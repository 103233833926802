import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import Button from '@geops/react-ui/components/Button';
import MenuTooltip from '../tooltip/MenuTooltip';

import IABPPropTypes from '../../model/iabp/prop-types';
import './FloorButton.scss';

const propTypes = {
  active: PropTypes.bool,
  floor: IABPPropTypes.floor.isRequired,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any,
};

const defaultProps = {
  children: null,
  active: false,
  isMobile: false,
};

const FloorButton = ({ floor, onClick, active, children, isMobile }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();
  const floorNames = (floor.names || []).map((n) => t(n)).join(', ');
  const mobileInfo = isMobile && (
    <div className="tm-mobile-floor-info">{t('Etage')}</div>
  );
  const tooltipId = useMemo(() => {
    return uuid();
  }, []);
  return (
    <>
      <div
        className="tm-floor-button-wrapper"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onFocus={() => setShowTooltip(true)}
        onBlur={() => setShowTooltip(false)}
        aria-describedby={tooltipId}
      >
        <Button
          className={`tm-button tm-square-white${active ? ' tm-active' : ''}`}
          onClick={() => onClick(floor)}
        >
          {mobileInfo}
          <div>
            {floor.iabp_name}
            {children}
          </div>
        </Button>
      </div>
      <MenuTooltip
        id={tooltipId}
        className="tm-menu-tooltip tm-floor-tooltip"
        show={showTooltip}
        content={floorNames}
      />
    </>
  );
};

FloorButton.propTypes = propTypes;
FloorButton.defaultProps = defaultProps;

export default React.memo(FloorButton);
