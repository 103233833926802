import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BlankLink from '@geops/react-ui/components/BlankLink';
import { ReactComponent as Print } from '../../img/icons/printer_21_large.svg';
import './PrintButton.scss';

const PrintButton = () => {
  const { t } = useTranslation();
  const station = useSelector((state) => state.iabp.station);
  const href = useMemo(() => {
    return `//www.sbb.ch/content/dam/infrastruktur/trafimage/bahnhofplaene/plan-${station.alias}-a4.pdf`;
  }, [station]);

  return (
    <div className="tm-print-button">
      <BlankLink href={href} title={t('PDF des Plans öffnen')}>
        <Print focusable={false} />
        {t('PDF des Plans öffnen')}
      </BlankLink>
    </div>
  );
};

export default React.memo(PrintButton);
