import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import PiwikReactRouter from 'piwik-react-router';
import Head from './app/Head';
import ErrorBoundary from './app/ErrorBoundary';
import StationPlan from './app/StationPlan';
import store from './model/store';

import './App.scss';

let piwik;
const loadPiwik = process.env.REACT_APP_CI !== 'true';

const propTypes = {
  // A documentation can be found in the sbb confluence:
  // https://confluence-ext.sbb.ch/display/TRAFIEXTERN/Einbindung+des+Interaktiven+Bahnhofplans
  // always add new parameters to this page!!!
  apiParameters: PropTypes.shape({
    alias: PropTypes.string,
    disablePermalink: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.arrayOf(PropTypes.string),
    identifier: PropTypes.string,
    lang: PropTypes.string,
    layer: PropTypes.string,
    r: PropTypes.number,
    sentryDSN: PropTypes.string,
    x: PropTypes.numbber,
    y: PropTypes.number,
    hideExternalLinks: PropTypes.bool,
    apiObj: PropTypes.object,
    disableCookies: PropTypes.bool,
    barrierfree: PropTypes.bool,
    domainConsentId: PropTypes.string,
  }),

  history: PropTypes.oneOfType([
    ReactRouterPropTypes.history,
    PropTypes.shape(), // testing
  ]),
  isEmbedded: PropTypes.bool,
};

const defaultProps = {
  apiParameters: {
    lang: 'de',
    alias: 'bern',
    sentryDSN: '',
    disableCookies: false,
    domainConsentId: null,
  },
  history: null,
  isEmbedded: false,
};

const App = ({ apiParameters, history, isEmbedded }) => {
  // Create piwik here to be able to deactivate cookies using api and the iframe.
  if (loadPiwik && !piwik) {
    piwik = PiwikReactRouter({
      url: 'https://analytics.geops.de',
      siteId: 7,
    });

    // If it's embedded we rely on the disableCookies parameters
    if (isEmbedded && apiParameters.disableCookies) {
      piwik.push(['disableCookies']);

      // If it's not embedded we wait for the consent
    } else if (!isEmbedded) {
      // Make it global for the consent process, see HEAD component
      window.piwik = piwik;
      if (apiParameters.domainConsentId) {
        // Do nothing until we give the consent with OneTrust
        piwik.push(['requireConsent']);
      }
    }
  }
  return (
    <ErrorBoundary sentryDSN={apiParameters.sentryDSN}>
      <Provider store={store}>
        <Suspense fallback={null}>
          {!isEmbedded && <Head apiParameters={apiParameters} />}
          <StationPlan
            history={history && piwik ? piwik.connectToHistory(history) : null}
            apiParameters={apiParameters}
            stationAlias={apiParameters.alias}
            isEmbedded={isEmbedded}
          />
        </Suspense>
      </Provider>
    </ErrorBoundary>
  );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
