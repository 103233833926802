import {
  ANIMATE,
  SET_LAYERS,
  FIT_EXTENT,
  SET_CENTER,
  SET_CLICKED_FEATURES,
  SET_HOVER_FEATURES,
  SET_RESOLUTION,
  SET_ZOOM,
} from './actions';

const initialState = {
  layers: [],
  clickedFeatures: [],
  hoverFeatures: [],
};

export default function tralisApp(state = initialState, action) {
  switch (action.type) {
    case ANIMATE:
      return {
        ...state,
        animationOptions: { ...action.data },
      };
    case SET_LAYERS:
      return {
        ...state,
        layers: [...action.data],
      };
    case FIT_EXTENT:
      return {
        ...state,
        extent: [...action.data],
      };
    case SET_CENTER:
      return {
        ...state,
        center: [...action.data],
      };
    case SET_CLICKED_FEATURES:
      return {
        ...state,
        clickedFeatures: [...action.data],
      };
    case SET_HOVER_FEATURES:
      return {
        ...state,
        hoverFeatures: [...action.data],
      };
    case SET_RESOLUTION:
      return {
        ...state,
        resolution: action.data,
      };
    case SET_ZOOM:
      return {
        ...state,
        zoom: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
