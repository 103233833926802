import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash.flowright';

import Menu from '@geops/react-ui/components/Menu';
import StationSwitcher from '../StationSwitcher';
import BarrierFreeToggler from './BarrierFreeToggler';
import PrintButton from './PrintButton';
import { setStationSwitcherVisible } from '../../model/iabp/actions';

import './Menu.scss';

const propTypes = {
  // mapStateToProps
  menuVisible: PropTypes.bool,
  disabled: PropTypes.arrayOf(PropTypes.string),

  // mapDispatchToProps
  dispatchSetStationSwitcherVisible: PropTypes.func.isRequired,
};

const defaultProps = {
  disabled: [],
  menuVisible: false,
};

class IABPMenu extends PureComponent {
  constructor(props) {
    super(props);
    const { menuVisible } = props;

    this.state = {
      // used for removing the menu with a delay
      // to enable animations
      menuRemoved: !menuVisible,
    };
  }

  componentDidUpdate(prevProps) {
    const { menuVisible } = this.props;

    if (menuVisible !== prevProps.menuVisible) {
      window.clearTimeout(this.menuTimeout);
      this.menuTimeout = window.setTimeout(
        () => {
          this.setState({ menuRemoved: !menuVisible });
        },
        menuVisible ? 10 : 500,
      );
    }
  }

  render() {
    const { menuRemoved } = this.state;
    const { disabled, menuVisible, dispatchSetStationSwitcherVisible } =
      this.props;
    const menuClass = menuVisible ? 'tm-visible' : 'tm-hidden';

    if (menuRemoved || disabled.includes('menu')) {
      return null;
    }

    return (
      <div className={`tm-menu-wrapper ${menuClass}`}>
        <Menu>
          <div className="tm-menu-buttons">
            <BarrierFreeToggler />
            <PrintButton />
          </div>
          <StationSwitcher
            onSelect={() => {
              dispatchSetStationSwitcherVisible(false);
            }}
          />
        </Menu>
      </div>
    );
  }
}

IABPMenu.propTypes = propTypes;
IABPMenu.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  menuVisible: state.iabp.menuVisible,
  disabled: state.iabp.disabled,
});

const mapDispatchToProps = {
  dispatchSetStationSwitcherVisible: setStationSwitcherVisible,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(IABPMenu);
