// Variable to configure if PWA Service Worker is registered in index.js.
let pwaActive = false;

let prtl = 'https:';
let wkpUrl = '//maps.trafimage.ch';

let departureUrl = '//api.geops.io/sbb-departures/v1/';
let destinationSearchUrl = '//maps.trafimage.ch/search/v2/destinations/';
let iabpBackendUrl = '//backend.iabp.dev.trafimage.geops.ch';
let plansBackendUrl = '//backend.plans.dev.trafimage.geops.ch';
let iabpFrontendUrl = '//plans.trafimage.ch';
let sentryDSN;
const domainConsentId = process.env.REACT_APP_DOMAIN_CONSENT_ID;

const wkpRedirectHash =
  'ch.sbb.netzkarte?layers=ch.sbb.bahnhofplaene.printprodukte';

switch (process.env.REACT_APP_ENV) {
  case 'local': {
    iabpBackendUrl = process.env.REACT_APP_BACKEND_URL || iabpBackendUrl;
    iabpFrontendUrl = process.env.REACT_APP_FRONTEND_URL || iabpFrontendUrl;
    break;
  }
  case 'prod':
  default: {
    iabpBackendUrl = '//backend.iabp.trafimage.geops.ch';
    plansBackendUrl = '//backend.plans.prod.trafimage.geops.ch';
    break;
  }
  case 'stag': {
    pwaActive = false;
    departureUrl = '//api.geops.io/sbb-departures/v1/';
    destinationSearchUrl =
      '//wkp.stag.trafimage.geops.ch/search/v2/destinations/';
    iabpBackendUrl = '//backend.iabp.stag.trafimage.geops.ch';
    plansBackendUrl = '//backend.plans.stag.trafimage.geops.ch';
    iabpFrontendUrl = '//plans.stag.trafimage.geops.ch';
    wkpUrl = '//wkp.stag.trafimage.geops.ch';
    sentryDSN = 'https://a25800f7bc37409382a279535541b4eb@sentry.geops.de/5';
    break;
  }
  case 'dev': {
    pwaActive = false;
    departureUrl = '//api.geops.io/sbb-departures/v1/';
    destinationSearchUrl =
      '//wkp.dev.trafimage.geops.ch/search/v2/destinations/';
    iabpBackendUrl = '//backend.iabp.dev.trafimage.geops.ch';
    iabpFrontendUrl = '//plans.dev.trafimage.geops.ch';
    wkpUrl = '//wkp.dev.trafimage.geops.ch';
    sentryDSN = 'https://a25800f7bc37409382a279535541b4eb@sentry.geops.de/5';
    break;
  }
  case 'container': {
    pwaActive = false;
    prtl = '';
    wkpUrl = process.env.REACT_APP_CONTAINER_URL;
    departureUrl = process.env.REACT_APP_CONTAINER_URL;
    iabpBackendUrl = process.env.REACT_APP_CONTAINER_URL;
    // plansBackendUrl = ??
    iabpFrontendUrl = process.env.REACT_APP_CONTAINER_URL;
    sentryDSN = 'https://a25800f7bc37409382a279535541b4eb@sentry.geops.de/5';
    break;
  }
}

export default {
  pwaActive,
  sentryDSN,
  departureUrl: prtl + departureUrl,
  destinationSearchUrl: prtl + destinationSearchUrl,
  iabpFrontendUrl: prtl + iabpFrontendUrl,
  iabpBackendUrl: prtl + iabpBackendUrl,
  plansBackendUrl: prtl + plansBackendUrl,
  wkpUrl: prtl + wkpUrl,
  wkpRedirectUrl: `${wkpUrl}/#/${wkpRedirectHash}`,
  domainConsentId,
};
