import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UIHeader from '@geops/react-ui/components/Header';
import Button from '@geops/react-ui/components/Button';

import LanguageSelect from '../LanguageSelect';
import Search from '../search/Search';
import HeaderLeft from './HeaderLeft';

import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';
import { ReactComponent as SBBLogo } from '../../img/sbb_logo.svg';

import './Header.scss';

const propTypes = {
  lng: PropTypes.string.isRequired,
  screenWidth: PropTypes.string.isRequired,
};

const Header = ({ lng, screenWidth }) => {
  const searchRef = useRef(null);
  const { t } = useTranslation();
  const disabled = useSelector((state) => state.iabp.disabled);
  const [showSearch, setShowSearch] = useState(false);
  const isMobile = useMemo(() => screenWidth === 'xs', [screenWidth]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Close search bar if click outside.
      if (
        searchRef &&
        searchRef.current &&
        !searchRef.current.contains(e.target)
      ) {
        setShowSearch(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UIHeader>
      <div className="tm-header-flex">
        <div className="tm-header-left">
          <HeaderLeft />
        </div>
        <div className="tm-header-right">
          {disabled.includes('search') ? null : (
            <Button
              title={t('Suche')}
              className="tm-toggle-search"
              onClick={() => {
                setShowSearch(!showSearch);
              }}
            >
              <SearchIcon />
            </Button>
          )}
          {lng && (
            <LanguageSelect
              lng={lng}
              isAppWidthSmallerThanL={['l', 'm', 's', 'xs'].includes(
                screenWidth,
              )}
            />
          )}
          <div className="tm-header-logo">
            <SBBLogo />
          </div>
        </div>
      </div>
      {!isMobile || showSearch ? (
        <Search searchRef={searchRef} onSelect={() => setShowSearch(false)} />
      ) : null}
    </UIHeader>
  );
};

Header.propTypes = propTypes;
export default Header;
