import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash.flowright';
import Feature from 'ol/Feature';
import { getCenter, extend } from 'ol/extent';
import Autocomplete from '@geops/react-ui/components/Autocomplete';
import CONF from '../../config';
import IABPPropTypes from '../../model/iabp/prop-types';
import {
  setClickedSearchResult,
  setFilterCategories,
  setFilterService,
  setSearchValue,
  setSearchResults,
} from '../../model/iabp/actions';
import { setClickedFeatures, animate } from '../../model/map/actions';
import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';

import './Search.scss';

const propTypes = {
  searchRef: PropTypes.object,
  onSelect: PropTypes.func.isRequired,

  // mapStateToProps
  lng: PropTypes.string,
  activePlan: IABPPropTypes.plan,
  station: IABPPropTypes.station,
  disabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  filterService: PropTypes.string,
  searchValue: PropTypes.string,
  searchValueSource: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  searchSuggestions: PropTypes.arrayOf(PropTypes.array),

  // mapDispatchToProps
  dispatchAnimate: PropTypes.func.isRequired,
  dispatchSetClickedSearchResult: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
  dispatchSetFilterCategories: PropTypes.func.isRequired,
  dispatchSetFilterService: PropTypes.func.isRequired,
  dispatchSetSearchValue: PropTypes.func.isRequired,
  dispatchSetSearchResults: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func,
  zoom: PropTypes.number,
};

const defaultProps = {
  searchRef: undefined,

  // mapStateToProps
  lng: 'de',
  activePlan: null,
  station: { name: '' },
  serviceFeatures: [],
  filterCategories: [],
  filterService: null,
  searchValue: '',
  searchValueSource: '',
  searchResults: [],
  searchSuggestions: [],
  zoom: 19,

  // react-i18next
  t: (p) => p,
};

/**
 * This component displays a search input.
 */
class Search extends PureComponent {
  static renderLogo(item) {
    const src = item.title
      ? item.logo_url
      : item.get('icon_url') || item.get('logo_url');
    return (
      <div className="tm-item-search-result-logo">
        <img src={src} alt="" />
      </div>
    );
  }

  static renderResultText(item, lng) {
    if (!item.title) {
      return item.get(`field_name_${lng}`);
    }

    return (
      <div className="tm-item-search-result-text">
        <div
          className="tm-item-title tm-ellipsis"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: item.title }}
        />
        <div
          className="tm-item-headline tm-ellipsis"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: item.headline }}
        />
      </div>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      focus: false,
    };

    this.defaultItems = [];

    // Abort fetch requests
    this.abortController = new AbortController();
  }

  componentDidUpdate(prevProps) {
    const { searchValue, searchValueSource } = this.props;
    if (searchValue !== prevProps.searchValue) {
      this.updateSearchResults(searchValue, searchValueSource);
    }
  }

  /**
   * Load destinations.
   * @param {string} destination Selected destination.
   */
  updateSearchResults(value, source) {
    const { dispatchSetClickedFeatures, dispatchSetSearchResults } = this.props;
    if (!value) {
      dispatchSetSearchResults([]);
      dispatchSetClickedFeatures([]);
      return;
    }

    const { station, lng } = this.props;
    const url =
      `${CONF.iabpBackendUrl}/api/v2/stations/` +
      `${station.alias}/search?q=${value}&lang=${lng}`;

    this.abortController.abort();
    this.abortController = new AbortController();
    const { signal } = this.abortController;
    fetch(url, { signal })
      .then((response) => response.json())
      .then((data) => dispatchSetSearchResults(data, source))
      .catch((err) => {
        if (err.name === 'AbortError') {
          // eslint-disable-next-line no-console
          console.warn(`Abort ${url}`);
          return;
        }
        // It's important to rethrow all other errors so you don't silence them!
        // For example, any error thrown by setState(), will pass through here.
        throw err;
      });
  }

  highlightFeatures(features) {
    if (features.length === 0) {
      return;
    }

    const {
      activePlan,
      filterCategories,
      dispatchSetClickedFeatures,
      dispatchAnimate,
      dispatchSetFilterCategories,
      zoom,
    } = this.props;

    // open popup
    dispatchSetClickedFeatures(features);

    // center on features
    const targetExtent = features.reduce(
      (ext, feat) => extend(ext, feat.getGeometry().getExtent()),
      features[0].getGeometry().getExtent(),
    );

    const minSearchZoom = activePlan.plan_type === 'outdoor_web' ? 19 : 21;

    dispatchAnimate({
      duration: 750,
      center: getCenter(targetExtent),
      zoom: Math.max(zoom, minSearchZoom),
    });

    // disable filter categories if the search result has another category
    const firstFeat = features[0];
    if (!filterCategories.includes(firstFeat.get('category'))) {
      dispatchSetFilterCategories([]);
    }
  }

  renderItem(item) {
    const it = Array.isArray(item) ? item[0] : item;
    const { lng } = this.props;

    return (
      <div className="tm-search-result">
        {Search.renderLogo(it)}
        {Search.renderResultText(it, lng)}
      </div>
    );
  }

  render() {
    const { focus } = this.state;

    const {
      t,
      lng,
      searchRef,
      disabled,
      filterService,
      dispatchSetClickedSearchResult,
      dispatchSetFilterService,
      dispatchSetSearchValue,
      searchValue,
      searchResults,
      searchSuggestions,
      serviceFeatures,
      onSelect,
    } = this.props;

    if (disabled.includes('search')) {
      return null;
    }

    const fieldName = `field_name_${lng}`;
    const className = `tm-search${focus ? ' tm-focus' : ''}`;

    return (
      <div className={className} ref={searchRef}>
        <Autocomplete
          button={<SearchIcon />}
          value={searchValue || filterService || ''}
          defaultItems={searchSuggestions}
          items={searchResults}
          placeholder={t('Suche')}
          renderTitle={() => <p>{t('Meist gesucht')}</p>}
          renderItem={(item) => this.renderItem(item)}
          getItemKey={(item) => item.id || item[0].get(fieldName)}
          onFocus={() => {
            this.setState({ focus: true });
          }}
          onClearClick={() => {
            if (searchValue) {
              onSelect();
            }
          }}
          onBlur={() => {
            this.setState({ focus: false });
          }}
          onChange={(val) => {
            if (filterService && val !== filterService) {
              dispatchSetFilterService('');
            }
            dispatchSetSearchValue(val);
          }}
          onSelect={(item, evt) => {
            onSelect();
            if (!item) {
              return;
            }
            // Select the first feature from the list
            const selectedItem = item.length > 1 ? [item[0]] : item;

            dispatchSetSearchValue(
              selectedItem.title
                ? selectedItem.title.replace(/<(?:.|\n)*?>/gm, '')
                : selectedItem[0].get(fieldName),
              'onSelect',
            );

            const source = evt && evt.which === 13 ? 'Enter' : null;
            dispatchSetClickedSearchResult(selectedItem, source);

            if (!selectedItem.title) {
              this.highlightFeatures(selectedItem);
            } else {
              // We highlight feature corresponding to an item
              this.highlightFeatures(
                serviceFeatures.filter(
                  (feat) => selectedItem.id === feat.getId(),
                ),
              );
            }
          }}
        />
      </div>
    );
  }
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  activePlan: state.iabp.activePlan,
  disabled: state.iabp.disabled,
  station: state.iabp.station,
  searchResults: state.iabp.searchResults,
  searchSuggestions: state.iabp.searchSuggestions,
  searchValue: state.iabp.searchValue,
  searchValueSource: state.iabp.searchValueSource,
  serviceFeatures: state.iabp.serviceFeatures,
  filterCategories: state.iabp.filterCategories,
  filterService: state.iabp.filterService,
  zoom: state.map.zoom,
});

const mapDispatchToProps = {
  dispatchAnimate: animate,
  dispatchSetClickedFeatures: setClickedFeatures,
  dispatchSetFilterCategories: setFilterCategories,
  dispatchSetFilterService: setFilterService,
  dispatchSetClickedSearchResult: setClickedSearchResult,
  dispatchSetSearchValue: setSearchValue,
  dispatchSetSearchResults: setSearchResults,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Search);
