import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Notification.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  onNotificationEnd: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {
  duration: 3000,
  onNotificationEnd: () => {},
  className: 'tm-notification',
};

function Notification({ children, duration, onNotificationEnd, ...props }) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onNotificationEnd();
    }, duration);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div aria-live="polite" {...props}>
      <div>{children}</div>
    </div>
  );
}

Notification.defaultProps = defaultProps;
Notification.propTypes = propTypes;

export default React.memo(Notification);
