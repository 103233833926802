import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import qs from 'query-string';
import Feature from 'ol/Feature';
import { getCenter, buffer } from 'ol/extent';

import IABPPropTypes from '../../model/iabp/prop-types';

import {
  setActivePlan,
  setFilterService,
  setFilterCategories,
  setDisabled,
  setLanguage,
  toggleExternalLinks,
  setInitialFeatureIdentifier,
  setHighContrastMode,
} from '../../model/iabp/actions';

import {
  fitExtent,
  setClickedFeatures,
  setCenter,
  setZoom,
  setResolution,
} from '../../model/map/actions';

const propTypes = {
  apiParameters: PropTypes.shape().isRequired,
  searchString: PropTypes.string, // for testing
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),

  // mapStateToProps
  lng: PropTypes.string,
  activePlan: IABPPropTypes.plan,
  center: PropTypes.arrayOf(PropTypes.number),
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  zoom: PropTypes.number,
  serviceFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  station: IABPPropTypes.station,
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  highContrastMode: PropTypes.bool,

  // mapDispatchToProps
  dispatchSetLanguage: PropTypes.func.isRequired,
  dispatchFitExtent: PropTypes.func.isRequired,
  dispatchSetZoom: PropTypes.func.isRequired,
  dispatchSetCenter: PropTypes.func.isRequired,
  dispatchSetActivePlan: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
  dispatchSetDisabled: PropTypes.func.isRequired,
  dispatchSetResolution: PropTypes.func.isRequired,
  dispatchSetFilterService: PropTypes.func.isRequired,
  dispatchSetFilterCategories: PropTypes.func.isRequired,
  dispatchToggleExternalLinks: PropTypes.func.isRequired,
  dispatchsetInitialFeatureIdentifier: PropTypes.func.isRequired,
  dispatchSetHighContrastMode: PropTypes.func.isRequired,
};

const defaultProps = {
  history: null,
  searchString: null,

  // mapStateToProps
  lng: 'de',
  activePlan: null,
  center: null,
  serviceFeatures: [],
  station: null,
  zoom: null,
  filterCategories: [],
  highContrastMode: false,
};

export class Permalink extends PureComponent {
  // Allow to compare object equality.
  static isEqual(obj1, obj2) {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    let isEqual = true;
    obj1Keys.forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        isEqual = false;
      }
    });
    return isEqual;
  }

  constructor(props) {
    super(props);
    const { apiParameters, searchString } = props;

    // Permalink has the priority over the initial state.
    const params = {
      ...apiParameters,
      ...qs.parse(searchString || window.location.search),
    };

    this.dispatchFromNewParam(params);
  }

  componentDidUpdate(prevProps) {
    const {
      apiParameters,
      activePlan,
      center,
      clickedFeatures,
      dispatchFitExtent,
      history,
      serviceFeatures,
      station,
      zoom,
      lng,
      filterCategories,
      highContrastMode,
    } = this.props;

    // Permalink has the priority over the initial state.
    const params = qs.parse(window.location.search);

    if (!Permalink.isEqual(apiParameters, prevProps.apiParameters)) {
      this.dispatchFromNewParam(apiParameters, prevProps);
      return;
    }

    if (lng !== prevProps.lng) {
      params.lang = lng;
    }

    if (center !== prevProps.center) {
      params.x = Math.round(center[0] * 100) / 100;
      params.y = Math.round(center[1] * 100) / 100;
    }

    if (zoom !== prevProps.zoom) {
      params.z = zoom;
    }

    if (activePlan !== prevProps.activePlan) {
      params.layer = activePlan.plan_layer;
    }

    if (
      clickedFeatures.length &&
      clickedFeatures !== prevProps.clickedFeatures
    ) {
      params.identifier = clickedFeatures[0].get('url_identifier');
    } else if (!clickedFeatures.length) {
      delete params.identifier;
    }

    if (
      !filterCategories.length ||
      filterCategories !== prevProps.filterCategories
    ) {
      delete params.filterCategories;
    }

    if (station && serviceFeatures.length) {
      this.restoreFromPermalink();
    }

    if (
      history &&
      station &&
      prevProps.station &&
      station.alias !== prevProps.station.alias
    ) {
      history.push(`/${station.alias}`);
    }

    // update when station changes but not on startup
    if (prevProps.station && station !== prevProps.station) {
      const { extent } = station;
      dispatchFitExtent(extent);
    }

    if (highContrastMode !== prevProps.highContrastMode) {
      if (!highContrastMode) {
        delete params.barrierfree;
      } else {
        params.barrierfree = true;
      }
    }

    const qsStr = qs.stringify(params);
    const search = `?${qsStr}`;
    if (
      qsStr &&
      history &&
      search !== window.location.search &&
      !apiParameters.disablePermalink
    ) {
      history.replace({
        search,
      });
    }
  }

  getServiceFeaturesFromIdentifier(identifier) {
    const { serviceFeatures } = this.props;
    let matchFeatures = [];
    // search for url idenfifier in features and related_information
    for (let i = 0; i < serviceFeatures.length; i += 1) {
      const feature = serviceFeatures[i];
      const related = (feature.get('related_information') || []).find(
        (f) => f.url_identifier === identifier,
      );

      if (related) {
        // if a feature is contained in related information, use this one
        // and ignore others [IABPREF-58]
        matchFeatures = [feature];
        break;
      } else if (feature.get('url_identifier') === identifier) {
        matchFeatures.push(feature);
      }
    }

    // see [IABPKLEIN-214]
    if (matchFeatures.length > 1 && this.activePlanName) {
      matchFeatures = matchFeatures.filter(
        (f) => f.get('plan_layer') === this.activePlanName,
      );
    }
    return matchFeatures;
  }

  focusFeature(feature) {
    const { dispatchSetCenter, dispatchFitExtent, zoom } = this.props;

    const featExtent = feature.getGeometry().getExtent();
    const center = getCenter(featExtent);
    dispatchSetCenter(center);

    if (!zoom) {
      // Minimum extent
      const bufferedExtent = buffer(featExtent, 30);
      dispatchFitExtent(bufferedExtent);
    }
  }

  dispatchFromNewParam(params, prevProps) {
    const {
      dispatchSetLanguage,
      dispatchSetCenter,
      dispatchSetZoom,
      dispatchSetDisabled,
      dispatchSetResolution,
      dispatchSetFilterCategories,
      dispatchSetFilterService,
      dispatchToggleExternalLinks,
      dispatchSetHighContrastMode,
      serviceFeatures,
      highContrastMode,
      station,
    } = this.props;

    const {
      z,
      identifier,
      layer,
      disabled,
      r,
      service,
      categories,
      lang,
      hideExternalLinks,
      barrierfree,
    } = params;

    if (lang) {
      dispatchSetLanguage(lang);
    }

    const zoom = parseInt(z, 10);
    const x = parseFloat(params.x);
    const y = parseFloat(params.y);

    if (x && y) {
      dispatchSetCenter([x, y]);
    }

    if (zoom) {
      dispatchSetZoom(zoom);
    }

    if (r) {
      dispatchSetResolution(r);
    }

    if (disabled) {
      let arrOfCmpt = disabled || [];
      if (arrOfCmpt.split) {
        // if it's a string
        arrOfCmpt = arrOfCmpt.split(',');
      }
      if (arrOfCmpt.length) {
        dispatchSetDisabled(arrOfCmpt);
      }
    }

    if (hideExternalLinks && hideExternalLinks === 'true') {
      dispatchToggleExternalLinks(false);
    } else {
      dispatchToggleExternalLinks(true);
    }

    if (identifier) {
      this.activeIdentifier = identifier;
    } else {
      this.activeIdentifier = null;
    }

    if (service) {
      dispatchSetFilterService(service);
    } else if (!service && prevProps && prevProps.apiParameters.service) {
      // Empty search if service unset.
      dispatchSetFilterService(false);
    }

    if (categories) {
      let arrOfCategories = categories || [];
      if (arrOfCategories.split) {
        // if it's a string
        arrOfCategories = arrOfCategories.split(',');
      }
      if (arrOfCategories.length) {
        dispatchSetFilterCategories(arrOfCategories);
      } else {
        dispatchSetFilterCategories([]);
      }
    }
    if (barrierfree === 'true' || barrierfree === true) {
      dispatchSetHighContrastMode(true);
    } else if (highContrastMode) {
      dispatchSetHighContrastMode(false);
    }

    this.activePlanName = layer;
    this.restored = false;

    if (station && serviceFeatures.length) {
      this.restoreFromPermalink(prevProps);
    }
  }

  restoreFromPermalink(prevProps) {
    if (this.restored) {
      return;
    }

    const {
      activePlan,
      dispatchSetActivePlan,
      dispatchSetClickedFeatures,
      dispatchsetInitialFeatureIdentifier,
      station,
    } = this.props;

    const matchFeatures = this.getServiceFeaturesFromIdentifier(
      this.activeIdentifier,
    );

    if (matchFeatures.length) {
      const [matchFeature] = matchFeatures;
      const urlIdentifier = matchFeatures[0].get('url_identifier');
      if (urlIdentifier) {
        dispatchsetInitialFeatureIdentifier(urlIdentifier);
      }
      dispatchSetClickedFeatures(matchFeatures);

      this.activePlanName = matchFeature.get('plan_layer');
      this.activeIdentifier = null;

      this.focusFeature(matchFeature);
    } else if (
      !matchFeatures.length &&
      prevProps &&
      prevProps.apiParameters.identifier
    ) {
      // Empty clicked features if identifier unset.
      dispatchSetClickedFeatures([]);
    }

    // restore active plan
    if (this.activePlanName && station) {
      const planShouldBeActive = station.plans.filter(
        (p) => p.plan_layer === this.activePlanName,
      )[0];

      if (
        planShouldBeActive &&
        planShouldBeActive.plan_layer !== activePlan.plan_layer
      ) {
        dispatchSetActivePlan(planShouldBeActive);
      }
    }

    // do this only once
    this.restored = true;
  }

  render() {
    return null;
  }
}

Permalink.propTypes = propTypes;
Permalink.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  activePlan: state.iabp.activePlan,
  clickedFeatures: state.map.clickedFeatures,
  serviceFeatures: state.iabp.serviceFeatures,
  station: state.iabp.station,
  center: state.map.center,
  zoom: state.map.zoom,
  filterCategories: state.iabp.filterCategories,
  highContrastMode: state.iabp.highContrastMode,
});

const mapDispatchToProps = {
  dispatchSetLanguage: setLanguage,
  dispatchFitExtent: fitExtent,
  dispatchSetClickedFeatures: setClickedFeatures,
  dispatchSetActivePlan: setActivePlan,
  dispatchSetCenter: setCenter,
  dispatchSetZoom: setZoom,
  dispatchSetDisabled: setDisabled,
  dispatchSetResolution: setResolution,
  dispatchSetFilterService: setFilterService,
  dispatchSetFilterCategories: setFilterCategories,
  dispatchToggleExternalLinks: toggleExternalLinks,
  dispatchsetInitialFeatureIdentifier: setInitialFeatureIdentifier,
  dispatchSetHighContrastMode: setHighContrastMode,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Permalink);
