import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash.flowright';
import { withTranslation } from 'react-i18next';
import Feature from 'ol/Feature';

import './PlansTooltip.scss';

const propTypes = {
  feature: PropTypes.instanceOf(Feature),

  // mapstateToProps
  lng: PropTypes.string,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  lng: 'de',
  feature: null,
};

const PlansTooltip = ({ feature, lng, t }) => {
  if (!feature) {
    return null;
  }
  let text = feature.get('name') || feature.get(`field_name_${lng}`);

  // Display access type for access feature
  const type = feature.get('type');
  if (type) {
    text = t(type);
  }

  return <div className="tm-tooltip-body">{text}</div>;
};

PlansTooltip.propTypes = propTypes;
PlansTooltip.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(PlansTooltip);
