import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {
  buffer,
  containsExtent,
  createEmpty,
  extend,
  getCenter,
} from 'ol/extent';
import VectorLayer from 'react-spatial/layers/VectorLayer';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

export const CLUSTER_MODES = {
  all: 'ALL',
  distance: 'DISTANCE',
  none: 'NONE',
};

export default class ClusterLayer extends VectorLayer {
  static getPointsAroundCoordinate(coord, radius, count) {
    const angle = 360 / count;
    const points = [];
    let currentAngle = angle;

    while (currentAngle <= 360) {
      currentAngle += angle;
      const newX = coord[0] + radius * Math.cos((currentAngle * Math.PI) / 180);
      const newY = coord[1] + radius * Math.sin((currentAngle * Math.PI) / 180);
      points.push(new Point([newX, newY]));
    }

    return points;
  }

  static getMedian(values) {
    values.sort((a, b) => a - b);

    if (values.length === 0) {
      return 0;
    }

    const half = Math.floor(values.length / 2);

    if (values.length % 2) {
      return values[half];
    }

    return (values[half - 1] + values[half]) / 2.0;
  }

  /**
   * Vector layer.
   * @param {string} name The layer's name
   * @param {Object} [options] Layer options
   * @param {Array.<ol.Feature>} [options.features] Layer features.
   * @param {ol.style.Style} [options.style] Layer styile
   * @param {number} [options.resolution] Map resolution.
   */
  constructor(options) {
    const { clusterCenter, clusterMode, clusterDistance, resolution } = options;
    super({
      ...options,
      olLayer: new OLVectorLayer({
        zIndex: 1,
        style: options.style,
        source: new VectorSource(),
      }),
    });

    /**
     * List of categories that should not be clustered
     */
    this.noClusterCategories = ['public_transport', 'pictograms'];

    /**
     *
     */
    this.cluster = {};

    /**
     * Cluster mode (all: all features are clustered,
     *   distance: clustering by alley and distance.
     * @type {string}
     */
    this.clusterMode = clusterMode || CLUSTER_MODES.distance;

    /**
     * List of (unclustered) features
     * @type {Array.<ol.Feature>}
     */
    this.features = [];

    /**
     * Cluster distance in map units.
     * @type {number}
     */
    this.distance = clusterDistance || 150;

    /**
     * Map resolution.
     * @type {number}
     */
    this.resolution = resolution;

    /**
     *  List of existing snap coordinate strings
     *  (to avoid overlapping).
     *  @type {Array.string}
     */
    this.usedSnapCoords = [];

    /**
     * Width of the snap grid in pixel.
     * @type {number}
     */
    this.snapGridWidth = 70;

    this.clusterCenter = clusterCenter;
  }

  /**
   * Create a feature from a cluster object
   * @param {Object} cluster Cluster object
   * @param {Array.<ol.Feature>} cluster.features List of features
   * @param {ol.Extent} cluster.extent Cluster extent
   */
  getFeaturesFromCluster(cluster) {
    const clusterFeatures = [];
    const clusterKeys = Object.keys(cluster);
    let noClusterFeatures = [];

    if (this.clusterMode === CLUSTER_MODES.none) {
      for (let i = 0; i < clusterKeys.length; i += 1) {
        noClusterFeatures = noClusterFeatures.concat(
          cluster[clusterKeys[i]].features,
        );
      }
    } else {
      for (let i = 0; i < clusterKeys.length; i += 1) {
        const clusterName = clusterKeys[i];
        const { features, category } = cluster[clusterName];

        if (features.length === 1) {
          // add as normal feature
          noClusterFeatures.push(features[0]);
        } else {
          // optimize position and add as cluster feature
          const centerCoord = getCenter(cluster[clusterName].extent);
          const xCoords = [];
          const yCoords = [];

          features.forEach((f) => {
            const coords = f.getGeometry().getCoordinates();
            xCoords.push(coords[0]);
            yCoords.push(coords[1]);
          });

          const medianX = ClusterLayer.getMedian(xCoords);
          const medianY = ClusterLayer.getMedian(yCoords);
          const coord = medianX && medianY ? [medianX, medianY] : centerCoord;
          const snapCoord = this.getSnapCoordinate(coord);

          clusterFeatures.push(
            new Feature({
              category: category || clusterName,
              features,
              isClusterFeature: true,
              clusterExtent: cluster[clusterName].extent,
              geometry: new Point(snapCoord),
              featureCount: features.length,
            }),
          );
        }
      }
    }

    // equally distribute clusters if cluster mode == 'all'
    if (this.clusterMode === CLUSTER_MODES.all) {
      const ext = createEmpty();
      clusterFeatures.forEach((f) => extend(ext, f.getGeometry().getExtent()));
      const clusterPoints = ClusterLayer.getPointsAroundCoordinate(
        this.clusterCenter || getCenter(ext),
        40,
        clusterFeatures.length,
      );

      for (let i = 0; i < clusterPoints.length; i += 1) {
        clusterFeatures[i].setGeometry(clusterPoints[i]);
      }
    }

    return [...clusterFeatures, ...noClusterFeatures];
  }

  /**
   * @inheritdoc
   */
  addFeatures(features) {
    this.features = this.features.concat(features);
    this.updateCluster();
  }

  /**
   * Set the cluster mode
   * @param {string} mode Cluster mode
   */
  setClusterMode(mode) {
    if (mode !== this.clusterMode) {
      this.clusterMode = mode;
    }
  }

  clear() {
    super.clear();
    this.features = [];
  }

  /**
   * Cluster a list of features by their distance and alley name.
   * @param {Array.<ol.Feature>} features Array of features
   * @returns {Object} Cluster object with features, category and extent
   */
  getClustersByDistance(features) {
    const clustered = [];
    const clusters = {};
    const mapDistance =
      this.clusterMode === CLUSTER_MODES.distance
        ? this.distance * this.resolution
        : Number.MAX_VALUE;

    for (let i = 0; i < features.length; i += 1) {
      const id = features[i].getId();

      if (clustered.indexOf(id) === -1) {
        const featureExtent = [...features[i].getGeometry().getExtent()];
        const searchExtent = [...featureExtent];
        buffer(searchExtent, mapDistance, searchExtent);
        clustered.push(id);

        clusters[id] = clusters[id] || {
          features: [this.features[i]],
          category: features[i].get('category'),
        };

        const neighbours = features.filter((f) => {
          const cat = f.get('category');
          const extent = f.getGeometry().getExtent();

          const isValidClusterCategory =
            cat === features[i].get('category') &&
            this.noClusterCategories.indexOf(cat) === -1 &&
            clustered.indexOf(f.getId()) === -1;

          const isClusterDistance = containsExtent(searchExtent, extent);

          if (isValidClusterCategory && isClusterDistance) {
            extend(featureExtent, extent);
            clustered.push(f.getId());
            return true;
          }

          return false;
        });

        clusters[id].features = clusters[id].features.concat(neighbours);
        clusters[id].extent = featureExtent;
      }
    }

    return clusters;
  }

  /**
   * Snap to a grid with a grid size specified by this.snapGridWidth.
   * Ensure that 2 features are not snapped to the same coordinate.
   */
  getSnapCoordinate(coord) {
    const mapGridWidth = Math.ceil(this.snapGridWidth * this.resolution);
    const newCoord = [
      Math.round(coord[0] / mapGridWidth) * mapGridWidth,
      Math.round(coord[1] / mapGridWidth) * mapGridWidth,
    ];

    while (this.usedSnapCoords.indexOf(newCoord.join()) > -1) {
      const offset = mapGridWidth * [-1, 1][Math.floor(Math.random() * 2)];
      const coordIx = Math.floor(Math.random() * 2);
      newCoord[coordIx] += offset;
    }

    this.usedSnapCoords.push(newCoord.join());
    return newCoord;
  }

  /**
   * Set the resolution used for calculation of cluster distance.
   * @param {number} resolution Map resolution
   */
  setResolution(resolution) {
    this.resolution = resolution;
    this.usedSnapCoords = [];
  }

  /**
   * Update the cluster.
   */
  updateCluster() {
    this.olLayer.getSource().clear();
    this.cluster = this.getClustersByDistance(this.features);

    const features = this.getFeaturesFromCluster(this.cluster);
    this.olLayer.getSource().addFeatures(features);
  }

  /**
   * Add features of the cluster.
   */
  expandClusterFeature(clusterFeature) {
    this.olLayer.getSource().removeFeature(clusterFeature);
    this.olLayer.getSource().addFeatures(clusterFeature.get('features'));
  }

  /**
   * Remove features of the cluster.
   */
  reClusterFeature(clusterFeature) {
    const features = clusterFeature.get('features');
    this.olLayer.getSource().addFeature(clusterFeature);

    for (let i = 0; i < features.length; i += 1) {
      if (this.olLayer.getSource().hasFeature(features[i])) {
        this.olLayer.getSource().removeFeature(features[i]);
      }
    }
  }
}
