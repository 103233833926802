import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Button from '@geops/react-ui/components/Button';

import './EmbeddedOverlay.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.element.isRequired,
  link: PropTypes.element,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  link: null,
};

const EmbeddedOverlay = ({ onClose, logo, title, link }) => {
  return (
    <div className="tm-overlay-integrated-body">
      <Button className="tm-overlay-integrated-closer" onClick={onClose}>
        <MdClose focusable={false} />
      </Button>
      {logo}
      <div className="tm-overlay-integrated-content">
        <div className="tm-overlay-integrated-title">
          {title}
          {/\.$/.test(title) ? '' : '.'}
        </div>
        {link}
      </div>
    </div>
  );
};

EmbeddedOverlay.propTypes = propTypes;
EmbeddedOverlay.defaultProps = defaultProps;

export default EmbeddedOverlay;
