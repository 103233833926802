import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import CircleStyle from 'ol/style/Circle';
import ShapeStyle from 'ol/style/RegularShape';
import StrokeStyle from 'ol/style/Stroke';
import TextStyle from 'ol/style/Text';
import FillStyle from 'ol/style/Fill';
import CATEGORIES from '../ServiceCategories';

import whiteClusterIcon from '../../img/white_cluster_icon.png';
import iconRolltreppe from '../../img/icons/rolltreppe.svg';
import iconTreppe from '../../img/icons/treppe.svg';
import iconLift from '../../img/icons/lift.svg';
import iconRampe from '../../img/icons/rampe.svg';
import redPin from '../../img/icons/redPin.svg';

export default class LayerStyle {
  static MAX_RES = 0.2;

  static MAX_RES_OUTDOOR = 0.4;

  static BLUE = [0, 61, 133];

  static areaStyle = [
    new Style({
      stroke: new StrokeStyle({
        color: 'white',
      }),
      fill: new FillStyle({
        color: 'rgba(0,0,0,0.2)',
      }),
    }),
  ];

  static outlineStyle = (radius) => {
    return new Style({
      image: new CircleStyle({
        radius: radius + 10,
        fill: new FillStyle({
          color: 'rgba(255,255,255,0.01)',
        }),
      }),
    });
  };

  static getBlueSquareStyle(radius = 5) {
    const color = LayerStyle.BLUE;
    return [
      LayerStyle.outlineStyle(radius),
      new Style({
        image: new ShapeStyle({
          points: 4,
          rotation: Math.PI / 4,
          radius,
          fill: new FillStyle({
            color: `rgb(${color[0]},${color[1]},${color[2]})`,
          }),
        }),
      }),
    ];
  }

  static getCircleStyle(color, radius = 5) {
    return [
      LayerStyle.outlineStyle(radius),
      new Style({
        image: new CircleStyle({
          radius,
          fill: new FillStyle({
            color: `rgb(${color[0]},${color[1]},${color[2]})`,
          }),
        }),
      }),
    ];
  }

  static selectedStyle(feature, lng) {
    const text = feature.get(`field_name_${lng}`);
    return new Style({
      image: new Icon({
        src: redPin,
        imgSize: [38, 38],
        anchor: [0.5, 0.9],
        scale: 1.5,
      }),
      text: new TextStyle({
        text,
        font: '16px "SBBWeb Roman"',
        offsetY: -25,
        offsetX: 25,
        textAlign: 'left',
        fill: new FillStyle({
          color: '#eb0000 ',
        }),
        stroke: new StrokeStyle({
          color: 'white',
          width: 2,
        }),
        // backgroundFill: new FillStyle({
        //   color: 'rgba(0,0,0,0.8)',
        // }),
        padding: [5, 5, 5, 5],
      }),
      zIndex: 1000,
    });
  }

  constructor(options = {}) {
    const { gridExtent, gridWidth, highContrastMode, translateFunction } =
      options;

    this.usedGridCoords = [];
    this.gridCache = {};
    this.gridExtent = gridExtent;
    this.gridWidth = gridWidth || 50;
    this.highContrastMode = highContrastMode;

    this.translateFunction = translateFunction;
    this.serviceStyleCache = {};
    this.accessStyleCache = {};
  }

  accessStyleFunction(
    feature,
    resolution,
    isOutdoorPlan = false,
    isSelected = false,
    lng,
  ) {
    // Display small squares and cricle on high resolution
    const maxRes = isOutdoorPlan
      ? LayerStyle.MAX_RES_OUTDOOR
      : LayerStyle.MAX_RES;

    const styles = [];
    if (isSelected) {
      styles.push(LayerStyle.selectedStyle(feature, lng));
    }

    if (resolution > maxRes) {
      styles.unshift(...LayerStyle.getBlueSquareStyle());
      return styles;
    }

    const type = feature.get('type');
    if (!this.accessStyleCache[type]) {
      let icon = null;

      switch (type) {
        case 'rolltreppe':
          icon = iconRolltreppe;
          break;
        case 'lift':
          icon = iconLift;
          break;
        case 'treppe':
          icon = iconTreppe;
          break;
        case 'rampe':
          icon = iconRampe;
          break;
        default:
          icon = iconTreppe;
      }

      this.accessStyleCache[type] = new Style({
        image: new Icon({
          src: icon,
          // IE 11 needs the imgSize for SVG
          imgSize: [24, 24],
        }),
      });
    }

    styles.unshift(this.accessStyleCache[type]);
    return styles;
  }

  setHighContrastMode(highContrastMode) {
    this.highContrastMode = highContrastMode;
    this.serviceStyleCache = {};
  }

  labelStyleFunction(feat) {
    const labelName = feat.get('label_name');
    const label = this.translateFunction
      ? this.translateFunction(labelName)
      : labelName;

    return [
      new Style({
        text: new TextStyle({
          fill: new FillStyle({ color: feat.get('color') || '#333' }),
          font: `${feat.get('font_weight') || ''} 10px Arial`,
          text: label,
          textAlign: 'center',
          rotation: (feat.get('rotation', 0) / 180) * Math.PI,
          scale: feat.get('scale') || 1,
        }),
      }),
    ];
  }

  // mode=declutter
  serviceStyleFunctionDeclutter(feature, isSelected, lng) {
    const styles = this.serviceStyleFunction(
      feature,
      undefined,
      false,
      isSelected,
      lng,
    );
    return isSelected ? styles : styles.slice(0, 1);
  }

  serviceStyleFunction(
    feature,
    resolution,
    isOutdoorPlan = false,
    isSelected = false,
    isEmbedded = false,
    isInitialSelectedWhenEmbedded = false,
    lng,
  ) {
    let feat = feature;

    // mode=cluster2
    const isCluster2 = !!feat.get('features');

    if (isCluster2) {
      [feat] = feat.get('features');
    }

    const styles = [];
    const category = feat.get('category');
    const featureIcon = feat.get('icon_url');
    const { priority, color } = CATEGORIES[category];
    const icon =
      featureIcon ||
      (this.highContrastMode
        ? CATEGORIES[category].icon_contrast
        : CATEGORIES[category].icon);
    const isSelectedWhenEmbedded = isEmbedded && isSelected;
    const scale = isSelectedWhenEmbedded ? 1.1 : 1;
    // if (thumb) {
    //   scale = isSelectedWhenEmbedded ? 0.55 : 0.5;
    // }

    // mode=cluster
    const isCluster = !!(category && feature.get('isClusterFeature'));

    // Display small squares and circles on high resolution
    const maxRes = isOutdoorPlan
      ? LayerStyle.MAX_RES_OUTDOOR
      : LayerStyle.MAX_RES;

    if (isInitialSelectedWhenEmbedded || (!isEmbedded && isSelected)) {
      styles.push(LayerStyle.selectedStyle(feature, lng));
    }

    if (!isCluster && resolution > maxRes) {
      const newColor = this.highContrastMode ? LayerStyle.BLUE : color;
      const shape = color
        ? LayerStyle.getCircleStyle(newColor, isSelectedWhenEmbedded ? 7 : 5)
        : LayerStyle.getBlueSquareStyle(isSelectedWhenEmbedded ? 7 : 5);
      styles.unshift(...shape);
      return styles;
    }

    const cacheKey = `${icon}${isSelectedWhenEmbedded}`;
    // Display icons on low resolution
    if (icon && !this.serviceStyleCache[cacheKey]) {
      this.serviceStyleCache[cacheKey] = [
        new Style({
          image: new Icon({
            src: icon,
            scale,
          }),
          zIndex: -priority,
        }),
      ];
    }

    // mode=cluster
    if (isCluster) {
      const count = feature.get('featureCount');
      const radius = Math.min(
        70,
        Math.max(30, Math.round(Math.log(count) * 15)),
      );

      styles.unshift(
        ...[
          new Style({
            zIndex: radius,
            text: new TextStyle({
              fill: new FillStyle({ color: 'black' }),
              stroke: new StrokeStyle({ color: 'rgba(255,255,255,0.9)' }),
              text: `${count}`,
              offsetX: 25,
              offsetY: -24,
            }),
          }),

          new Style({
            zIndex: radius,
            opacity: 0.5,
            image: new CircleStyle({
              radius,
              fill: new FillStyle({
                color: `rgba(${color[0]},${color[1]},${color[2]}, 0.9)`,
              }),
            }),
          }),

          new Style({
            zIndex: radius,
            opacity: 0.1,
            image: new CircleStyle({
              radius: radius + 2,
              stroke: new StrokeStyle({
                color: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.5)`,
                width: 2,
              }),
            }),
          }),

          new Style({
            zIndex: radius,
            image: new Icon({
              anchor: [-0.5, 1.5],
              scale: 0.8,
              src: whiteClusterIcon,
            }),
          }),

          new Style({
            zIndex: radius,
            image: new Icon({
              src: CATEGORIES[category].icon_transparent,
            }),
          }),
        ],
      );
      return styles;
    }
    styles.unshift(...this.serviceStyleCache[cacheKey]);
    return styles;
  }
}
