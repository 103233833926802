import React from 'react';

function ImprintFR() {
  return (
    <div className="tm-imprint">
      <h1>Impressum.</h1>
      <p>
        <b>Ensemble de la responsabilité Trafimage.</b>
        <br />
        <br />
        CFF SA
        <br />
        Infrastructure – Installations et technologie – Operation Center
        Technique – Technical Management
        <br />
        Fachbus Trafimage
        <br />
        Poststrasse 6
        <br />
        CH-3072 Ostermundingen
        <br />
        <a href="http://www.trafimage.ch">www.trafimage.ch</a>
        <br />
      </p>
      <p>
        <b>
          Exploitation, maintenance et développement des applications Trafimage,
          intégration des données.
        </b>
        <br />
        <br />
        geOps AG
        <br />
        Solothurnerstrasse 235
        <br />
        CH-4600 Olten
        <br />
        <a href="http://www.geops.ch">www.geops.ch</a>
        <br />
      </p>
      <p>
        <b>Établissement des cartes et des plans des gares</b>
        <br />
        <br />
        evoq communications AG
        <br />
        Ottikerstrasse 59
        <br />
        CH-8006 Zürich
        <br />
        <a href="http://www.evoq.ch">www.evoq.ch</a>
        <br />
      </p>
    </div>
  );
}

export default ImprintFR();
