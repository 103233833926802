import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash.flowright';
import { MdLoop } from 'react-icons/md';
import { withTranslation } from 'react-i18next';

import Feature from 'ol/Feature';

import { ReactComponent as SBBClock } from '../../img/clock_10_large.svg';
import EmbeddedOverlay from './EmbeddedOverlay';
import DestinationInput from './DestinationInput';
import CONF from '../../config';

import './TransportOverlay.scss';

const propTypes = {
  feature: PropTypes.instanceOf(Feature),
  isEmbedded: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  // mapStateToProps
  lng: PropTypes.string,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  lng: 'de',
  feature: null,
};

class TransportOverlay extends PureComponent {
  static formatTime(time) {
    const d = new Date(time);

    return [`0${d.getHours()}`.slice(-2), `0${d.getMinutes()}`.slice(-2)].join(
      ':',
    );
  }

  static getMinDiff(time) {
    const min = Math.floor((new Date(time) - new Date()) / 1000 / 60);
    if (min < 0) {
      return null;
    }
    return min > 0 && min < 60 ? [min, "'"].join('') : null;
  }

  static getDelayColor(estimatedTimeLocal, timetabledTimeLocal) {
    if (!estimatedTimeLocal || !timetabledTimeLocal) {
      return 'green';
    }

    const min = Math.floor(
      (new Date(estimatedTimeLocal) - new Date(timetabledTimeLocal)) /
        1000 /
        60,
    );
    if (min >= 3 && min < 5) {
      return 'orange';
    }
    if (min >= 5) {
      return 'red';
    }
    return 'green';
  }

  constructor(props) {
    super(props);

    this.state = {
      departures: [],
      departuresLoading: true,
      platformName: 'abfahrtszeiten_kante',
      isOffline: false,
    };

    this.selectedDestination = null;
    this.loadInterval = null;
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.loadDepartures();
    this.loadInterval = window.setInterval(() => this.loadDepartures(), 5000);
  }

  componentDidUpdate(prevProps) {
    const { lng, feature } = this.props;
    if (lng !== prevProps.lng || feature !== prevProps.feature) {
      this.loadDepartures();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.clearInterval(this.loadInterval);
  }

  /**
   * On selection of a destination in the input.
   */
  onDestinationSelect(selectedDestination) {
    this.selectedDestination = selectedDestination;
    this.loadDepartures(selectedDestination);
  }

  /**
   * Load departures.
   * @param {string} destination Selected destination.
   */
  loadDepartures() {
    const { feature } = this.props;
    const uic = feature.get('didok');

    let url = `${CONF.departureUrl}?uic=${uic}&key=${process.env.REACT_APP_API_KEY}&limit=20`;

    if (this.selectedDestination) {
      url += `&destination_uic=${this.selectedDestination}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // HACK to prevent "update of unmounted component" warning
        if (!this.mounted) {
          return;
        }

        // mode of transport does not change between departures
        const platformType =
          data && data.length ? data[0].modeOfTransport : null;
        let platformName = null;

        switch (platformType) {
          case 'rail':
            platformName = 'abfahrtszeiten_gleis';
            break;
          case 'water':
            platformName = 'abfahrtszeiten_steg';
            break;
          default:
            platformName = 'abfahrtszeiten_kante';
            break;
        }

        this.setState({
          departures: data,
          departuresLoading: false,
          platformName,
          isOffline: !navigator.onLine,
        });
      })
      .catch(() => {
        this.setState({
          departuresLoading: false,
          isOffline: true,
        });
      });
  }

  renderMinDiff(minDiff) {
    const { isOffline } = this.state;
    if (isOffline && minDiff) {
      return <>&nbsp;-&nbsp;&nbsp;</>;
    }
    return minDiff;
  }

  render() {
    const { feature, lng, t, isEmbedded, onClose } = this.props;
    if (!feature) {
      return null;
    }
    const { isOffline, departuresLoading, platformName } = this.state;
    let { departures } = this.state;
    departures = departures.sort(
      (a, b) =>
        new Date(a.estimatedTimeLocal || a.timetabledTimeLocal).getTime() -
        new Date(b.estimatedTimeLocal || b.timetabledTimeLocal).getTime(),
    );

    // Display all platforms [TRAFKLEIN-524]
    const title = t(`Abfahrtszeiten aller ${platformName}`);

    const loading = departuresLoading ? (
      <div className="tm-loader">
        <MdLoop className="tm-load-spinner" focusable={false} />
      </div>
    ) : null;

    let info = null;

    if (!departuresLoading && departures.length === 0) {
      info = (
        <p>
          <i>
            {`${t(
              'Für den aktuellen Zeitraum konnten keine ' +
                'Verbindungen gefunden werden',
            )}.`}
          </i>
        </p>
      );
    }
    const trackName = feature.get(`field_name_${lng}`);

    if (isEmbedded) {
      return <EmbeddedOverlay onClose={onClose} title={trackName} />;
    }
    return (
      <div className="tm-transport-overlay-body">
        <div className="tm-transport-content">
          <div className="tm-transport-header">
            <div className="tm-transport-title">{trackName}.</div>
          </div>
          <div className="tm-departure-title" title={title}>
            {title}
          </div>

          <DestinationInput
            platforms={feature.get('platforms')}
            onSelect={(d) => this.onDestinationSelect(d)}
            uic={feature.get('didok')}
          />

          {loading}
          {info}

          <div
            className={`tm-table-wrapper${departuresLoading ? ' loading' : ''}`}
          >
            <table className="tm-departures">
              <tbody>
                <tr>
                  <th
                    style={{
                      padding: '5px 7px 5px 3px',
                    }}
                  >
                    {t('Linie')}
                  </th>
                  <th>{t('Ziel')}</th>
                  <th colSpan="2">
                    <SBBClock focusable={false} height="23px" width="23px" />
                  </th>
                  <th>{t(platformName)}</th>
                </tr>
                {departures.map((d, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={idx}>
                    <td
                      style={{
                        padding: '5px 7px 5px 3px',
                      }}
                    >
                      <div className="tm-departure-name">{d.lineName}</div>
                    </td>
                    <td>
                      <div className="tm-departure-destination">
                        {d.destinationText}
                      </div>
                    </td>
                    <td>
                      {TransportOverlay.formatTime(d.timetabledTimeLocal)}
                    </td>
                    <td>
                      <div
                        className="tm-departure-min"
                        style={{
                          color: isOffline
                            ? '#333'
                            : TransportOverlay.getDelayColor(
                                d.estimatedTimeLocal,
                                d.timetabledTimeLocal,
                              ),
                        }}
                      >
                        {this.renderMinDiff(
                          TransportOverlay.getMinDiff(
                            d.estimatedTimeLocal || d.timetabledTimeLocal,
                          ),
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          'tm-departure-platform ' +
                          `${d.modeOfTransport === 'rail' ? 'train' : ''}`
                        }
                      >
                        <div className="tm-platform-inner">{d.plannedQuay}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

TransportOverlay.propTypes = propTypes;
TransportOverlay.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(TransportOverlay);
