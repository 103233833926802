import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';

import Feature from 'ol/Feature';
import Button from '@geops/react-ui/components/Button';

import CONF from '../../config';
import IABPPropTypes from '../../model/iabp/prop-types';

const propTypes = {
  // mapStateToProps
  lng: PropTypes.string,
  activePlan: IABPPropTypes.plan,
  center: PropTypes.arrayOf(PropTypes.number),
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  station: IABPPropTypes.station,
  zoom: PropTypes.number,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  clickedFeatures: [],
  activePlan: null,
  center: null,
  station: null,
  zoom: 19,
  lng: 'de',
};

const PermalinkButton = ({
  activePlan,
  station,
  clickedFeatures,
  lng,
  center,
  zoom,
  t,
}) => {
  if (!station) {
    return null;
  }

  let href = `${CONF.iabpFrontendUrl}/${station.alias}?lang=${lng}&`;

  // A feature is selected
  if (clickedFeatures.length) {
    const [popupFeature] = clickedFeatures;
    href += `identifier=${popupFeature.get('url_identifier')}&`;
  }

  if (activePlan && center && zoom) {
    href +=
      `layer=${activePlan.plan_layer}&x=${center[0]}` +
      `&y=${center[1]}&z=${zoom}`;
  }

  return (
    <Button
      className="tm-button tm-round-white"
      title={t('Vollbildansicht')}
      onClick={() => {
        window.open(href);
      }}
    >
      <FaExternalLinkSquareAlt focusable={false} />
    </Button>
  );
};

PermalinkButton.propTypes = propTypes;
PermalinkButton.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  center: state.map.center,
  clickedFeatures: state.map.clickedFeatures,
  station: state.iabp.station,
  zoom: state.map.zoom,
  activePlan: state.iabp.activePlan,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(PermalinkButton);
