/* eslint react/prop-types: 0 */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import { MdContentCopy } from 'react-icons/md';
import Input from '@material-ui/core/Input';
import MUISelect from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import MUITextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@geops/react-ui/components/Button';
import Autocomplete from './Autocomplete';
import App from '../../App';
import CONF from '../../config';
import CATEGORIES from '../ServiceCategories';

import './Doc.scss';

const returnNotNull = (value) => {
  return Number(value) > 0 ? Number(value).toFixed(3) : null;
};

const padding = '12px';
const inputStyle = {
  width: '100%',
  fontSize: '13px',
  marginLeft: padding,
};
const fontStyle = { fontSize: '13px' };

// SBB blue custom Checkbox, based on material-ui component.
const BlueCheckbox = withStyles({
  root: {
    color: '#767676',
    '&$checked': {
      color: '#003d85',
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox color="default" {...props} />);

const Select = withStyles({
  root: {
    paddingRight: 0,
    width: '100%',
    maxWidth: '310px',
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <MUISelect style={inputStyle} {...props} />);

const TextField = (props) => {
  return (
    <MUITextField
      style={inputStyle}
      InputProps={{ style: fontStyle }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const renderMenuItem = (item) => (
  <MenuItem key={item} value={item} style={fontStyle}>
    {item}
  </MenuItem>
);

const parameters = [
  {
    name: 'alias',
    type: 'String',
    dflt: 'bern',
    description:
      'The url_alias for the station, e.g. "zuerich-hb". Bern is displayed by default.',
    // eslint-disable-next-line react/prop-types
    render: ({ stations, alias, setAlias }) => (
      <Select
        value={alias}
        onChange={(evt) => {
          setAlias(evt.target.value);
        }}
      >
        {stations.map((s) => renderMenuItem(s.alias))}
      </Select>
    ),
  },
  {
    name: 'disablePermalink',
    type: 'Boolean',
    dflt: 'false',
    description: 'Disable the permalink parameters.',
    // eslint-disable-next-line react/prop-types
    render: ({ disablePermalink, setDisablePermalink }) => (
      <BlueCheckbox
        checked={disablePermalink === 'true'}
        onChange={(evt) => {
          setDisablePermalink(evt.target.value === 'true' ? false : 'true');
        }}
        value={disablePermalink === 'true'}
      />
    ),
  },
  {
    name: 'disabled',
    type: 'Array<String>',
    dflt: '',
    description: (
      <>
        Application components to be disabled:
        <li>spyLayer - hides the Floor/Plan switcher</li>
        <li>header - hides the header</li>
        <li>footer - hides the footer</li>
        <li>menu - hide the menu</li>
        <li>search - hides the search bar</li>
        <li>mapControls - hides map controls</li>
        <li>overlay - hides all overlay</li>
        <li>transportOverlay - hides Transport overlay</li>
        <li>wkpSwitcher - hides the wkp switcher button</li>
        <li>
          identifierOverlay - hides the service overlay of the feature selected
          with the identifier
        </li>
      </>
    ),
    // eslint-disable-next-line react/prop-types
    render: ({ disabled, setDisabled }) => (
      <Select
        multiple
        value={disabled}
        onChange={(evt) => {
          setDisabled(evt.target.value);
        }}
        input={<Input id="select-multiple-disabled" />}
      >
        {[
          'spyLayer',
          'header',
          'footer',
          'menu',
          'search',
          'mapControls',
          'overlay',
          'identifierOverlay',
          'transportOverlay',
          'wkpSwitcher',
        ].map((name) => renderMenuItem(name))}
      </Select>
    ),
  },
  {
    name: 'hideExternalLinks',
    type: 'Boolean',
    dflt: 'false',
    description: 'Hide popup links to non-SBB web pages.',
    // eslint-disable-next-line react/prop-types
    render: ({ hideExternalLinks, setHideExternalLinks }) => (
      <BlueCheckbox
        checked={hideExternalLinks === 'true'}
        onChange={(evt) => {
          setHideExternalLinks(evt.target.value === 'true' ? false : 'true');
        }}
        value={hideExternalLinks === 'true'}
      />
    ),
  },
  {
    name: 'layer',
    type: 'String',
    dflt: 'false',
    description: 'Select the plan layer.',
    render: ({
      /* eslint-disable react/prop-types */
      layer,
      layers,
      setLayer,
      inactiveFields,
      removeInactiveFields,
      /* eslint-enable */
    }) => (
      <Select
        value={layer}
        onChange={(evt) => {
          setLayer(evt.target.value);
          // eslint-disable-next-line react/prop-types
          if (inactiveFields.includes('layer')) {
            removeInactiveFields('layer');
          }
        }}
      >
        {layers.map((l) => renderMenuItem(l))}
      </Select>
    ),
  },
  {
    name: 'lang',
    type: 'String',
    dflt: 'de',
    description: 'Language of the application.',
    // eslint-disable-next-line react/prop-types
    render: ({ lang, setLang, inactiveFields, removeInactiveFields }) => (
      <Select
        value={lang}
        onChange={(evt) => {
          setLang(evt.target.value);
          if (inactiveFields.includes('lang')) {
            removeInactiveFields('lang');
          }
        }}
      >
        {['de', 'fr', 'it', 'en'].map((l) => renderMenuItem(l))}
      </Select>
    ),
  },
  {
    name: 'x',
    type: 'Float',
    dflt: 'null',
    description: "x coordinate of the map's center.",
    // eslint-disable-next-line react/prop-types
    render: ({ x, setX, inactiveFields, removeInactiveFields }) => (
      <TextField
        value={x}
        type="number"
        onChange={(evt) => {
          setX(returnNotNull(evt.target.value));
          if (inactiveFields.includes('x')) {
            removeInactiveFields('x');
          }
        }}
      />
    ),
  },
  {
    name: 'y',
    type: 'Float',
    dflt: 'null',
    description: "y coordinate of the map's center.",
    // eslint-disable-next-line react/prop-types
    render: ({ y, setY, inactiveFields, removeInactiveFields }) => (
      <TextField
        value={y}
        type="number"
        onChange={(evt) => {
          setY(returnNotNull(evt.target.value));
          if (inactiveFields.includes('y')) {
            removeInactiveFields('y');
          }
        }}
      />
    ),
  },
  {
    name: 'r',
    type: 'Float',
    dflt: 'null',
    description: 'Resolution of the map.',
    // eslint-disable-next-line react/prop-types
    render: ({ r, setR, inactiveFields, removeInactiveFields }) => (
      <TextField
        value={r}
        type="number"
        inputProps={{ min: '0', max: '1', step: '0.01' }}
        onChange={(evt) => {
          setR(evt.target.value);
          if (inactiveFields.includes('r')) {
            removeInactiveFields('r');
          }
        }}
      />
    ),
  },
  {
    name: 'identifier',
    type: 'String',
    dflt: 'null',
    description: `Select and open a service popup by its identifier.
      Do not use in combination with 'service or 'categories'.`,
    // eslint-disable-next-line react/prop-types
    render: ({ identifier, identifiers, setIdentifier }) => (
      <Autocomplete
        identifier={identifier}
        setIdentifier={setIdentifier}
        options={identifiers.map((id) => ({ value: id, label: id }))}
      />
    ),
  },
  {
    name: 'service',
    type: 'String',
    dflt: 'null',
    description: `Initial offer search term.
      Do not use in combination with 'identifier'.`,
    // eslint-disable-next-line react/prop-types
    render: ({ service, setService }) => (
      <TextField
        value={service}
        onChange={(evt) => {
          setService(evt.target.value);
        }}
      />
    ),
  },
  {
    name: 'categories',
    type: 'Array<String>',
    dflt: 'null',
    description: `Filter service by categories.
      Do not use in combination with 'identifier'.`,
    // eslint-disable-next-line react/prop-types
    render: ({ categories, setCategories }) => (
      <Select
        multiple
        value={categories}
        onChange={(evt) => {
          setCategories(evt.target.value);
        }}
        input={<Input id="select-multiple-category" />}
      >
        {Object.keys(CATEGORIES).map((cat) => renderMenuItem(cat))}
      </Select>
    ),
  },
  {
    name: 'barrierfree',
    type: 'Boolean',
    dflt: 'false',
    description: 'Enable the barrierfree mode.',
    // eslint-disable-next-line react/prop-types
    render: ({ barrierfree, setBarrierfree }) => (
      <BlueCheckbox
        checked={barrierfree === 'true'}
        onChange={(evt) => {
          setBarrierfree(evt.target.value === 'true' ? false : 'true');
        }}
        value={barrierfree === 'true'}
      />
    ),
  },
];

const url = `${CONF.iabpBackendUrl}/api/v2/stations`;
const generateCode = (tpl, apiParameters, state) => {
  let code = tpl;
  const properties = [];
  apiParameters.forEach((prop) => {
    // Only display those params if the user changed it in the form.
    if (state.inactiveFields.includes(prop.name)) {
      return;
    }
    let value = state[prop.name];
    if (Array.isArray(value)) {
      value = value.length ? `[${value.map((v) => `'${v}'`)}]` : '';
    } else if (value) {
      if (prop.type === 'Float' || prop.type === 'Integer') {
        value = Number(value);
      } else if (prop.type === 'Boolean') {
        value = value === 'true';
      } else {
        value = `'${value}'`;
      }
    }
    if (value) {
      properties.push(`${prop.name}: ${value}`);
    }
  });
  code = code.replace(`{properties}`, properties.join(',\n    '));
  return code;
};

const tpl = `
<!-- 1. Laden der neuesten Version des API Skriptes -->
<script type="text/javascript" src="https://unpkg.com/@geops/interactive-stationplans/static/js/api.js"></script>

<!-- 2. Definition des Anwendungscontainers (position:[relative/absolute]) -->
<div id="plansMapContainer" style="position:relative;width:950px;height:600px;"></div>

<!-- 3. Initialisierung -->
<script type="text/javascript">
  new SBBPlansApplication({
    div: 'plansMapContainer',  // id of the app container
    {properties}
  })
</script>
`;

const intro = (
  <>
    <p style={{ marginTop: 50 }}>
      Station plans provide information on boarding, alighting and changing
      trains. They show available services, shops and combined mobility in and
      around the station (
      <a
        href="https://www.sbb.ch/en/station-services/railway-stations/trafimage/station-plans.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        read more
      </a>
      ).
    </p>
    <p>
      It&apos;s very easy to integrate a station plan into your existing web
      page. There are various configuration options which allow you to configure
      the application according to your needs. This page documents all the
      configuration options and leads you through the configuration process.
    </p>
  </>
);

const Doc = () => {
  const [code, setCode] = useState('');
  const [alias, setAlias] = useState('bern');
  const [stations, setStations] = useState([]);
  const [disablePermalink, setDisablePermalink] = useState(false);
  const [disabled, setDisabled] = useState(['footer']);
  const [hideExternalLinks, setHideExternalLinks] = useState(false);
  const [layers, setLayers] = useState([]);
  const [layer, setLayer] = useState('');
  const [lang, setLang] = useState('de');
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
  const [r, setR] = useState(null);
  const [identifiers, setIdentifiers] = useState([]);
  const [identifier, setIdentifier] = useState(null);
  const [service, setService] = useState('');
  const [categories, setCategories] = useState([]);
  const [barrierfree, setBarrierfree] = useState(false);
  const [inactiveFields, setInactiveFields] = useState([
    'x',
    'y',
    'r',
    'lang',
    'layer',
  ]);

  const removeInactiveFields = useCallback(
    (item) => {
      const newInactiveFields = inactiveFields.filter((f) => f !== item);
      setInactiveFields([...newInactiveFields]);
    },
    [inactiveFields],
  );

  const state = useMemo(() => {
    return {
      alias,
      setAlias,
      stations,
      setStations,
      disablePermalink,
      setDisablePermalink,
      disabled,
      setDisabled,
      hideExternalLinks,
      setHideExternalLinks,
      layer,
      layers,
      setLayer,
      setLayers,
      x,
      setX,
      y,
      setY,
      r,
      setR,
      identifiers,
      setIdentifiers,
      identifier,
      setIdentifier,
      service,
      setService,
      categories,
      setCategories,
      lang,
      setLang,
      inactiveFields,
      setInactiveFields,
      removeInactiveFields,
      barrierfree,
      setBarrierfree,
    };
  }, [
    alias,
    barrierfree,
    categories,
    disablePermalink,
    disabled,
    hideExternalLinks,
    identifier,
    identifiers,
    inactiveFields,
    lang,
    layer,
    layers,
    r,
    removeInactiveFields,
    service,
    stations,
    x,
    y,
  ]);
  useEffect(() => {
    window.addEventListener('stationplans:station_change', (evt) => {
      const { map } = evt.detail;
      let once = true;

      map.on('moveend', (e) => {
        if (once) {
          once = false;
          const center = e.target.getView().getCenter();
          setR(returnNotNull(e.target.getView().getResolution()));
          setX(center[0].toFixed(3));
          setY(center[1].toFixed(3));
        }
      });
    });
  }, []);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const orderedStations = data.sort((a, b) => {
          return a.alias.localeCompare(b.alias);
        });
        setStations(orderedStations);
        setAlias('bern');
      });
  }, []);

  useEffect(() => {
    // Unset x, y & identifer to ensure to zoom on full station extent.
    setX(null);
    setY(null);
    setIdentifier(null);

    fetch(`${url}/${alias}`)
      .then((res) => res.json())
      .then((data) => {
        const { plans } = data;
        const stationPlans = plans.map((p) => p.plan_layer);
        setLayers(stationPlans);

        const outdoorPlan = plans.filter(
          (p) => p.plan_type === 'outdoor_web',
        )[0];
        setLayer(outdoorPlan.plan_layer);
      });
  }, [alias]);

  useEffect(() => {
    fetch(`${url}/${alias}/planservices`)
      .then((res) => res.json())
      .then((data) => {
        const { features } = data;
        const featuresIds = features
          .filter((f) => f.properties.plan_layer === layer)
          .map((f) => f.properties.url_identifier);
        featuresIds.unshift('');
        setIdentifiers(featuresIds);
        setIdentifier(featuresIds[0]);
      });
  }, [alias, layer]);

  useEffect(() => {
    setCode(generateCode(tpl, parameters, state));
  }, [state]);

  return (
    <>
      <header>
        <h1>Interactive Station Plans - API Documentation</h1>
      </header>
      <div className="tm-doc">
        <p>{intro}</p>
        <h1>Parameters</h1>
        <p style={{ marginBottom: 40 }}>
          The following parameters allow you to easily customize the
          application. Just try it out and see the results in the live preview
          and the code block below.
        </p>
        <div className="tm-parameters">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
                <th style={{ paddingLeft: padding }}>Selected</th>
              </tr>
            </thead>
            <tbody>
              {parameters.map(({ name, type, dflt, description, render }) => (
                <tr key={name}>
                  <td style={{ width: '10%' }}>{name}</td>
                  <td style={{ width: '10%' }}>{type}</td>
                  <td style={{ width: '10%' }}>{dflt}</td>
                  <td style={{ width: '45%' }}>{description}</td>
                  <td style={{ minWidth: '290px', width: '25%' }}>
                    {render(state)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <h1>Live-Preview</h1>
        <p style={{ marginBottom: 40 }}>
          Modify the paramters in the above section to change the functionality
          and appearance of the application. &nbsp;
          <u>Note</u>: It&apos;s not possible to update the parameters by
          navigating inside the live preview.
        </p>
        {/* <div id="preview" /> */}
        <div className="tm-preview">
          <App
            apiParameters={{
              alias,
              stations,
              disablePermalink,
              disabled,
              hideExternalLinks,
              layer,
              layers,
              x,
              y,
              r,
              identifiers,
              identifier,
              service,
              categories,
              lang,
              inactiveFields,
              barrierfree,
            }}
            isEmbedded
          />
        </div>
        <h1>Code</h1>
        <p style={{ marginBottom: 40 }}>
          Use the following code snippet to integrate the interactive station
          plan application into your webpage. Another possibility for embedding
          the application is by using our &nbsp;
          <a
            href="https://www.npmjs.com/package/@geops/interactive-stationplans"
            target="_blank"
            rel="noopener noreferrer"
          >
            NPM module
          </a>
          .
        </p>
        <div className="tm-code">
          <Editor
            readOnly
            value={code}
            // onValueChange={codee => setCode(codee)}
            highlight={(codeVal) => highlight(codeVal, languages.html)}
          />
          <Button
            title="Copy code"
            onClick={() => {
              document.querySelector('textarea').select();
              document.execCommand('copy');
            }}
          >
            <MdContentCopy focusable={false} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default React.memo(Doc);
