import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuTooltip from '../tooltip/MenuTooltip';
import IABPPropTypes from '../../model/iabp/prop-types';

import './LegendMenuItem.scss';

const propTypes = {
  category: IABPPropTypes.category.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
};

function LegendMenuItem({ category, onClick }) {
  const { t } = useTranslation();
  const highContrastMode = useSelector((state) => state.iabp.highContrastMode);
  const filterCategories = useSelector((state) => state.iabp.filterCategories);
  const isAppWidthSmallerThanS = useSelector(
    (state) => state.iabp.isAppWidthSmallerThanS,
  );
  const [isTooltipShown, setTooltipShown] = useState(false);
  const {
    name,
    icon,
    icon_contrast: iconContrast,
    svg_icon_contrast: svgIconContrast,
    svg_icon: svgIcon,
    removeFromLegend,
  } = category;

  const isInactive = useMemo(() => {
    return (
      Array.isArray(filterCategories) &&
      filterCategories.length &&
      !filterCategories.includes(category.name)
    );
  }, [filterCategories, category]);

  const showTooltip = useCallback(() => {
    if (isAppWidthSmallerThanS) {
      return;
    }
    setTooltipShown(true);
  }, [isAppWidthSmallerThanS, setTooltipShown]);

  const hideTooltip = useCallback(() => {
    setTooltipShown(false);
  }, [setTooltipShown]);

  const onItemClick = useCallback(() => {
    onClick(category);
  }, [category, onClick]);

  const onKeyPress = useCallback(
    (evt) => {
      if (evt.which === 13) {
        onClick(category);
      }
    },
    [category, onClick],
  );

  if (removeFromLegend) {
    return null;
  }

  return (
    <li>
      <div
        className={`tm-legend-menu-item ${name}${
          isInactive ? ' tm-inactive' : ''
        }`}
        tabIndex="0"
        onClick={onItemClick}
        onKeyPress={onKeyPress}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onFocus={showTooltip}
        onBlur={hideTooltip}
        role="button"
        aria-describedby={t(name)}
      >
        <div className="tm-legend-menu-icon">
          {highContrastMode && svgIconContrast}
          {highContrastMode && !svgIconContrast && (
            <img src={iconContrast} alt={t(name)} />
          )}
          {!highContrastMode && svgIcon}
          {!highContrastMode && !svgIcon && <img src={icon} alt={t(name)} />}
        </div>

        <div className="tm-legend-menu-title">{t(name)}</div>
      </div>
      <MenuTooltip id={name} show={isTooltipShown} content={t(name)} />
    </li>
  );
}

LegendMenuItem.propTypes = propTypes;
LegendMenuItem.defaultProps = defaultProps;

export default React.memo(LegendMenuItem);
