import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layer from 'react-spatial/layers/Layer';

import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileImageSource from 'ol/source/TileImage';
import { getCenter } from 'ol/extent';

import CONF from '../../config';
import { setLayers, setCenter, fitExtent } from '../../model/map/actions';
import IABPPropTypes from '../../model/iabp/prop-types';

const propTypes = {
  activePlan: IABPPropTypes.plan.isRequired,
  station: IABPPropTypes.station.isRequired,
  center: PropTypes.arrayOf(PropTypes.number),
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  dispatchFitExtent: PropTypes.func.isRequired,
  dispatchSetCenter: PropTypes.func.isRequired,
  dispatchSetLayers: PropTypes.func.isRequired,
};

const defaultProps = {
  center: null,
};

class PlanLayer extends PureComponent {
  constructor(props) {
    super(props);
    const { activePlan } = this.props;

    if (activePlan) {
      this.loadPlan(activePlan);
    }
  }

  componentDidUpdate(prevProps) {
    const { activePlan } = this.props;
    if (prevProps.activePlan.plan_layer !== activePlan.plan_layer) {
      this.loadPlan(activePlan);
    }
  }

  loadPlan(plan) {
    const {
      station,
      center,
      dispatchSetLayers,
      dispatchSetCenter,
      dispatchFitExtent,
      layers,
    } = this.props;

    const tileUrl = `${CONF.iabpFrontendUrl}/static/tiles`;
    const name = 'PlanLayer';

    const layer = new Layer({
      name,
      olLayer: new TileLayer({
        extent: plan.extent,
        zIndex: 0,
        source: new TileImageSource({
          tileUrlFunction: (c) =>
            `${tileUrl}/${plan.plan_layer}/${c[0]}/${c[1]}/${-c[2] - 1}.png`,
          tileGrid: new TileGrid({
            origin: [plan.extent[0], plan.extent[1]],
            resolutions: plan.resolutions,
          }),
        }),
      }),
    });

    const otherLayers = layers.filter((l) => l.getName() !== name);
    dispatchSetLayers([layer, ...otherLayers]);

    if (!center) {
      dispatchSetCenter(getCenter(station.extent));
      dispatchFitExtent(station.extent);
    }
  }

  render() {
    return null;
  }
}

PlanLayer.propTypes = propTypes;
PlanLayer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  station: state.iabp.station,
  center: state.map.center,
  layers: state.map.layers,
});

const mapDispatchToProps = {
  dispatchFitExtent: fitExtent,
  dispatchSetLayers: setLayers,
  dispatchSetCenter: setCenter,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanLayer);
