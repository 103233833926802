import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { containsExtent } from 'ol/extent';
import Feature from 'ol/Feature';

import IABPPropTypes from '../../model/iabp/prop-types';
import { setClickedFeatures, setHoverFeatures } from '../../model/map/actions';

const propTypes = {
  features: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  refTooltip: PropTypes.object,
  refOverlay: PropTypes.object,
  refFooter: PropTypes.object,
  refZoomOut: PropTypes.object,
  extent: PropTypes.arrayOf(PropTypes.number),
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  hoverFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  focusClass: PropTypes.string,

  // mapStateToProps
  activePlan: IABPPropTypes.plan,

  // mapDispatchToProps
  dispatchSetHoverFeatures: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
};

const defaultProps = {
  features: [],
  refTooltip: undefined,
  refOverlay: undefined,
  refFooter: undefined,
  refZoomOut: undefined,
  extent: undefined,
  activePlan: undefined,
  focusClass: 'tm-bf-focus',
};

class BarrierFree extends PureComponent {
  /**
   * Find the HTML node corresponding to a react component.
   */
  static findNode(refNode) {
    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(refNode);
  }

  /**
   * Check if the current activeElement is the first or last node displayed of the popup.
   */
  static isFocusOnFirstOrLastElt(node, forward) {
    const tabElems = [...node.querySelectorAll('[tabindex="0"]')].filter(
      (elem) => elem.offsetParent !== null,
    );
    const idx = tabElems.indexOf(document.activeElement);
    return !!(
      (!forward && idx === 0) ||
      (forward && idx === tabElems.length - 1)
    );
  }

  constructor(props) {
    super(props);
    this.refBarrierFree = undefined;
    this.featureIndex = null;
    this.tabFeature = false;
    this.featuresOnView = [];
    this.onPopupKeyDown = this.onPopupKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onDocumentKeyUp = this.onDocumentKeyUp.bind(this);
    this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumenClick);
    document.addEventListener('keyup', this.onDocumentKeyUp);
    document.addEventListener('keydown', this.onDocumentKeyDown);
  }

  componentDidUpdate() {
    const {
      activePlan,
      extent,
      features,
      refOverlay,
      hoverFeatures,
      clickedFeatures,
    } = this.props;

    // Get features visible in the current extent.
    if (activePlan && extent && features) {
      const sortedFeatures = features.filter((f) => {
        if (f.get('plan_layer') === activePlan.plan_layer) {
          return containsExtent(extent, f.getGeometry().getExtent());
        }
        return null;
      });

      sortedFeatures.sort((a, b) => {
        if (
          a.getGeometry().getCoordinates()[0] <
          b.getGeometry().getCoordinates()[0]
        ) {
          return -1;
        }
        return 1;
      });
      this.featuresOnView = sortedFeatures;
    }

    if (
      this.tabFeature &&
      refOverlay &&
      refOverlay.current === null &&
      !hoverFeatures.length &&
      this.featureIndex
    ) {
      // Focus feature back when close Overlay.
      this.onFocus();
    }

    // If a feature is focused via TAB. We give the focus to its popup.
    if (
      this.tabFeature &&
      ((hoverFeatures && hoverFeatures.length) ||
        (clickedFeatures && clickedFeatures.length))
    ) {
      this.focusOnPopup();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', BarrierFree.onDocumentKeyDown);
    document.removeEventListener('keyup', BarrierFree.onDocumentKeyUp);
    document.removeEventListener('click', this.onDocumentClick);
  }

  onKeyUp(e, forceTarget) {
    const { focusClass } = this.props;

    // Remov bf class from all the document
    document.querySelectorAll(`.${focusClass}`).forEach((elt) => {
      elt.classList.remove(focusClass);
    });

    if (forceTarget) {
      forceTarget.classList.add(focusClass);
    } else if (e.target.tagName === 'BODY') {
      // IE initially focus body,
      // needs to wait before setting the style. [IABPKLEIN-231]
      setTimeout(() => {
        document.activeElement.classList.add(focusClass);
      }, 10);
    } else {
      e.target.focus();
      e.target.classList.add(focusClass);
    }
  }

  // Display bf focus class on mormal HTML element.
  onDocumentKeyUp(e) {
    if (e.keyCode === 9) {
      this.onKeyUp(e);
    }
    if ([38, 40].includes(e.keyCode)) {
      this.onKeyUp(e, document.activeElement);
    }
  }

  // IE triggers keydownevent to focus body
  // when leaving the  address bar [IABPKLEIN-231]
  onDocumentKeyDown = (e) => {
    if (e.keyCode === 9) {
      this.onKeyUp(e);
      document.removeEventListener('keydown', this.onDocumentKeyDown);
    }
  };

  onDocumentClick() {
    const { focusClass } = this.props;

    // Remov bf class from all the document
    document.querySelectorAll(`.${focusClass}`).forEach((elt) => {
      elt.classList.remove(focusClass);
    });

    // Reset values to default
    this.tabFeature = false;
    this.featureIndex = null;
  }

  onPopupKeyDown(evt) {
    if (evt.keyCode !== 9 || !this.tabFeature) {
      return;
    }

    const forward = !evt.shiftKey;
    const popupElement = this.getPopupElt();

    // If the current element focused is the first or the last child of the popup, we go to the next element.
    if (BarrierFree.isFocusOnFirstOrLastElt(popupElement, forward)) {
      this.nextElt(forward);

      // We stop propagation because we don't want to go in the document onKeyDown listener.
      evt.stopPropagation();
      evt.preventDefault();
    }
  }

  /**
   * When the focus happens on the barrierfree element we hover the feature at the current index.
   */
  onFocus() {
    const { dispatchSetHoverFeatures } = this.props;
    this.featureIndex = this.featureIndex || 0;
    this.tabFeature = true;
    dispatchSetHoverFeatures([this.featuresOnView[this.featureIndex]]);
  }

  /**
   * Return the main node of a Popup component.
   */
  getPopupElt() {
    const { refTooltip, refOverlay } = this.props;
    return (
      BarrierFree.findNode(refTooltip) ||
      (refOverlay &&
        refOverlay.current &&
        BarrierFree.findNode(refOverlay.current))
    );
  }

  /**
   * This function give the focus to the first focusable element of a Popup component.
   */
  focusOnPopup() {
    const { focusClass } = this.props;
    const node = this.getPopupElt();
    if (!node) {
      return;
    }

    const nodeToFocus = node.querySelector('[tabindex="0"]');
    if (!nodeToFocus) {
      return;
    }
    node.removeEventListener('keydown', this.onPopupKeyDown);
    node.addEventListener('keydown', this.onPopupKeyDown);
    nodeToFocus.focus();

    // First time we focus a feature, the class name is not apply properly using classList
    window.setTimeout(() => {
      nodeToFocus.classList.add(focusClass);
    }, 50);
  }

  /**
   * Determine the next element to focus: a feature, the element before the map or the element after the map.
   */
  nextElt(forward) {
    let hoverFeatures = [];
    const {
      dispatchSetHoverFeatures,
      dispatchSetClickedFeatures,
      refFooter,
      refZoomOut,
    } = this.props;
    this.featureIndex += forward ? 1 : -1;

    if (forward && this.featureIndex >= this.featuresOnView.length) {
      // Focus on 1st footer item
      BarrierFree.findNode(refFooter).querySelector('a').focus();
      this.tabFeature = false;
      this.featureIndex = this.featuresOnView.length - 1;
    } else if (!forward && this.featureIndex <= -1) {
      // Focus on zoom out button
      BarrierFree.findNode(refZoomOut).focus();
      this.tabFeature = false;
      this.featureIndex = 0;
    } else {
      // Focus on next feature
      this.tabFeature = true;
      hoverFeatures = [this.featuresOnView[this.featureIndex]];
    }

    dispatchSetClickedFeatures([]);
    dispatchSetHoverFeatures(hoverFeatures);
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <div
        role="button"
        ref={(node) => {
          this.refBarrierFree = node;
        }}
        tabIndex="0"
        onFocus={() => {
          this.onFocus();
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  clickedFeatures: state.map.clickedFeatures,
  hoverFeatures: state.map.hoverFeatures,
});

const mapDispatchToProp = (dispatch) => ({
  dispatchSetClickedFeatures: (feats) => {
    dispatch(setClickedFeatures(feats));
  },
  dispatchSetHoverFeatures: (features) => {
    dispatch(setHoverFeatures(features));
  },
});

BarrierFree.propTypes = propTypes;
BarrierFree.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProp)(BarrierFree);
