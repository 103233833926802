/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import { FaPlus, FaMinus } from 'react-icons/fa';

import OLMap from 'ol/Map';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { defaults as defaultInteractions } from 'ol/interaction';
import { mouseOnly } from 'ol/events/condition';
import { register } from 'ol/proj/proj4';
import { containsCoordinate } from 'ol/extent';
import { unByKey } from 'ol/Observable';
import proj4 from 'proj4';
import Layer from 'react-spatial/layers/Layer';
import BasicMap from 'react-spatial/components/BasicMap';
import Footer from '@geops/react-ui/components/Footer';
import BlankLink from '@geops/react-ui/components/BlankLink';
import ActionLink from '@geops/react-ui/components/ActionLink';
import Button from '@geops/react-ui/components/Button';
import Popup from 'react-spatial/components/Popup';
import ResizeHandler from '@geops/react-ui/components/ResizeHandler';
import CONF from '../config';
import IABPPropTypes from '../model/iabp/prop-types';
import {
  fetchStation,
  setActivePlan,
  setMenuVisible,
  setStationSwitcherVisible,
  togglePlan,
  setActiveFloor,
  setInitialFeatureIdentifier,
  setAppSize,
} from '../model/iabp/actions';
import {
  animate,
  setClickedFeatures,
  setHoverFeatures,
  setResolution,
  setLayers,
  setCenter,
  setZoom,
  fitExtent,
} from '../model/map/actions';
import CPlansServiceLayer from './layers/PlansServiceLayer';
import PlansServiceLayerCluster from './layers/PlansServiceLayerCluster';
import CPlansVectorLayer from './layers/PlansVectorLayer';
import CPlansAccessLayer from './layers/PlansAccessLayer';
import FloorSwitcher from './floor-switcher/FloorSwitcher';

import LayerStyle from './layers/LayerStyle';
import CWKPSwitcher from './wkp-switcher/WKPSwitcher';
import Header from './Header';

import OutdoorPlanButton from './floor-switcher/OutdoorPlanButton';
import CPermalink from './permalink/Permalink';
import PermalinkButton from './permalink/PermalinkButton';
import PlanLayer from './layers/PlanLayer';
import PlansOverlay from './overlay/PlansOverlay';
import PlansTooltip from './popup/PlansTooltip';

import CLegendMenu from './menu/LegendMenu';
import BarrierFree from './barrier-free/BarrierFree';

import './StationPlan.scss';

proj4.defs(
  'EPSG:21781',
  '+proj=somerc +lat_0=46.95240555555556 ' +
    '+lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel ' +
    '+towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs',
);

register(proj4);

const propTypes = {
  stationAlias: PropTypes.string.isRequired,
  apiParameters: PropTypes.shape().isRequired,
  isEmbedded: PropTypes.bool.isRequired,

  // react-router
  history: PropTypes.oneOfType([
    ReactRouterPropTypes.history,
    PropTypes.shape(), // testing
  ]),

  // mapStateToProps
  lng: PropTypes.string,
  activePlan: IABPPropTypes.plan,
  activeFloor: IABPPropTypes.floor,
  animationOptions: PropTypes.shape(),
  center: PropTypes.arrayOf(PropTypes.number),
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  hoverFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  extent: PropTypes.arrayOf(PropTypes.number),
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  resolution: PropTypes.number,
  serviceFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  planAccessFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  station: IABPPropTypes.station,
  zoom: PropTypes.number,
  disabled: PropTypes.arrayOf(PropTypes.string),
  isAppWidthSmallerThanS: PropTypes.bool,

  // mapDispatchToProps
  dispatchAnimate: PropTypes.func.isRequired,
  dispatchFetchStation: PropTypes.func.isRequired,
  dispatchSetActivePlan: PropTypes.func.isRequired,
  dispatchSetCenter: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
  dispatchSetHoverFeatures: PropTypes.func.isRequired,
  dispatchSetZoom: PropTypes.func.isRequired,
  dispatchSetResolution: PropTypes.func.isRequired,
  dispatchTogglePlan: PropTypes.func.isRequired,
  dispatchSetActiveFloor: PropTypes.func.isRequired,
  dispatchSetAppSize: PropTypes.func.isRequired,
  dispatchFitExtent: PropTypes.func.isRequired,
  dispatchsetInitialFeatureIdentifier: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  history: null,
  activePlan: null,
  activeFloor: null,
  animationOptions: null,
  center: null,
  extent: undefined,
  resolution: undefined,
  serviceFeatures: [],
  disabled: [],
  planAccessFeatures: [],
  station: null,
  zoom: 19,
  lng: 'de',
  isAppWidthSmallerThanS: undefined,
};

class StationPlan extends PureComponent {
  constructor(props) {
    super(props);
    const { t, dispatchFetchStation, stationAlias, apiParameters } = this.props;

    const params = {
      ...apiParameters,
      ...qs.parse(window.location.search),
    };

    const showUnpublished = ['1', 'true'].includes(params.show_unpublished);
    dispatchFetchStation(stationAlias, showUnpublished);

    this.state = {
      screenWidth: undefined,
      resizeForceUpdate: false,
    };

    this.layerStyle = new LayerStyle({ translateFunction: t });
    this.extentValue = undefined;
    this.ref = React.createRef();
    this.refMap = React.createRef();
    this.refTooltip = undefined;
    this.refOverlay = React.createRef();
    this.refFooter = undefined;
    this.refZoomOut = undefined;
    this.olKeys = [];

    // TODO: used for prototype [IABPPRIO-4]. Delete this later
    this.enableClustering = qs.parse(window.location.search).mode === 'cluster';
    this.enableClustering2 =
      qs.parse(window.location.search).mode === 'cluster2';
    this.enableDecluttering =
      qs.parse(window.location.search).mode === 'declutter';

    const interactions = defaultInteractions({
      keyboard: true,
      pinchRotate: false,
    }).getArray();

    this.map = new OLMap({ controls: [], interactions });
    this.maxSizeSmallScreen = 600;
  }

  componentDidMount() {
    this.onUpdateApiParameters();

    this.olKeys.push(
      this.map.getView().on('change:center', (evt) => {
        const { activePlan } = this.props;
        const { oldValue } = evt;
        const newValue = evt.target.getCenter();
        if (
          oldValue &&
          newValue &&
          activePlan &&
          activePlan.extent &&
          !containsCoordinate(activePlan.extent, newValue) &&
          // This last condition ensure to avoid infinite loop, in case the map is initialized outside the plan's extent.
          containsCoordinate(activePlan.extent, oldValue)
        ) {
          // We block the center of the map in the current plan's extent.
          evt.target.setCenter(oldValue);
        }
      }),
    );

    ['pointermove'].forEach((evt) => {
      this.map.on(evt, () => {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this.refMap.current);
        if (document.activeElement !== node) {
          node.focus();
        }
      });
    });

    this.initAPIFunctions();
  }

  componentDidUpdate(prevProps) {
    const {
      disabled,
      stationAlias,
      clickedFeatures,
      dispatchSetActivePlan,
      dispatchSetActiveFloor,
      dispatchFetchStation,
      station,
      activePlan,
      activeFloor,
      dispatchSetClickedFeatures,
      isAppWidthSmallerThanS,
      isEmbedded,
    } = this.props;

    if (disabled !== prevProps.disabled) {
      // Force re-applying the width and height classesbundleRenderer.
      this.forceUpdateResizeHandler();
    }

    if (
      stationAlias &&
      stationAlias !== prevProps.stationAlias &&
      (!station || stationAlias !== station.alias)
    ) {
      dispatchFetchStation(stationAlias);
      return;
    }

    if (station && station !== prevProps.station) {
      // see https://confluence-ext.sbb.ch/display/TRAFIEXTERN/Einbindung+des+Interaktiven+Bahnhofplans
      window.dispatchEvent(
        new CustomEvent('stationplans:station_change', {
          detail: {
            station,
            map: this.map,
          },
        }),
      );

      dispatchSetActivePlan(
        station.plans.filter((p) => p.plan_type === 'outdoor_web')[0],
      );
      // Close the overlay if switch station.
      dispatchSetClickedFeatures([]);
    }

    // When we go from indoor plan to outdoor we unset the activeFloor.
    if (
      prevProps.activePlan !== activePlan &&
      activePlan.plan_type === 'outdoor_web'
    ) {
      dispatchSetActiveFloor();

      // When we go from from outdoor plan to indoor we set a default
      // activeFloor.
    } else if (
      activePlan &&
      activePlan.plan_type === 'indoor_web' &&
      !activeFloor
    ) {
      dispatchSetActiveFloor(activePlan.floors[0]);
      const feature = clickedFeatures && clickedFeatures[0];
      if (feature) {
        this.updateFloor(
          feature.get('floor_id'),
          feature.getGeometry().getCoordinates(),
        );
      }
    }

    if (
      clickedFeatures !== prevProps.clickedFeatures &&
      clickedFeatures.length
    ) {
      const feature = clickedFeatures[0];
      // If we are in the outdoor_plan then we zoom on the features, we triggers an update of the active plan
      // so we have to update the floor in that case too (see line 276).
      this.zoomOnFeatures([feature]);
      if (feature) {
        this.updateFloor(
          feature.get('floor_id'),
          feature.getGeometry().getCoordinates(),
        );

        if (isAppWidthSmallerThanS) {
          this.map.getView().fit(feature.getGeometry(), {
            duration: 1000,
            padding: [0, 0, isEmbedded ? 0 : 250, 0],
            maxZoom: this.map.getView().getZoom(),
          });
        }
      }
    }

    this.onUpdateApiParameters(prevProps.apiParameters);
  }

  componentWillUnmount() {
    unByKey(this.olKeys);
    this.olKeys = [];
  }

  onMapMoved(evt) {
    const {
      center,
      resolution,
      dispatchSetCenter,
      dispatchSetResolution,
      dispatchSetZoom,
      zoom,
      activePlan,
      activeFloor,
      clickedFeatures,
    } = this.props;
    const newResolution = evt.map.getView().getResolution();
    const newZoom = evt.map.getView().getZoom();
    const newCenter = evt.map.getView().getCenter();

    if (zoom !== newZoom) {
      dispatchSetZoom(newZoom, this.zoomButtonActive);
      window.dispatchEvent(
        new CustomEvent('stationplans:zoom_change', {
          ...zoom,
        }),
      );
      this.zoomButtonActive = false;
    }

    if (resolution !== newResolution) {
      dispatchSetResolution(newResolution);
    }

    if (center[0] !== newCenter[0] || center[1] !== newCenter[1]) {
      dispatchSetCenter(newCenter);

      // Update floor only when the user move map with his mouse
      if (
        zoom === newZoom &&
        activePlan &&
        activeFloor &&
        (!clickedFeatures || !clickedFeatures.length)
      ) {
        this.updateFloor(null, newCenter);
      }
    }
  }

  onFeaturesHover(features, evt) {
    const { dispatchSetHoverFeatures, hoverFeatures } = this.props;
    const hoverFeature = features.find((f) => this.isClickableFeature(f));

    if (
      !evt.map.getView().getAnimating() &&
      !evt.map.getView().getInteracting() &&
      hoverFeature &&
      !hoverFeature.get('isClusterFeature') &&
      !hoverFeatures.includes(hoverFeature)
    ) {
      dispatchSetHoverFeatures([hoverFeature]);
      // IE 11 bug: 2nd parameter is ignored
      // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11865865/
      document.body.classList.add('tm-pointer');
    } else if (!hoverFeature && hoverFeatures.length > 0) {
      dispatchSetHoverFeatures([]);
      // IE 11 bug: 2nd parameter is ignored
      // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11865865/
      document.body.classList.remove('tm-pointer');
    }
  }

  onFeaturesClick(features) {
    const { dispatchSetClickedFeatures } = this.props;
    const popupFeatures = features.filter(this.isClickableFeature, this);
    dispatchSetClickedFeatures(popupFeatures);
  }

  onUpdateApiParameters(prevApiParameters) {
    const { apiParameters, dispatchsetInitialFeatureIdentifier } = this.props;
    const { identifier } = apiParameters;

    if (
      (!prevApiParameters && identifier) ||
      (prevApiParameters &&
        identifier &&
        identifier !== prevApiParameters.identifier)
    ) {
      dispatchsetInitialFeatureIdentifier(identifier);
    }
  }

  forceUpdateResizeHandler() {
    const { resizeForceUpdate } = this.state;
    this.setState({
      resizeForceUpdate: !resizeForceUpdate,
    });
  }

  // API control functions
  initAPIFunctions() {
    const { apiParameters } = this.props;

    if (apiParameters.apiObj) {
      apiParameters.apiObj.getZoom = () => {
        const { zoom } = this.props;
        return zoom;
      };
      apiParameters.apiObj.setZoom = (z) => {
        const { dispatchAnimate } = this.props;
        this.map.getView().cancelAnimations();
        dispatchAnimate({
          zoom: z,
        });
      };
      apiParameters.apiObj.getFloors = () => {
        const { station } = this.props;
        let totalFloors = station.plans.map((plan) => {
          return plan.floors.map((f) => {
            const floor = f;
            floor.plan = plan;
            return floor;
          });
        });
        totalFloors = totalFloors[0].concat(totalFloors[1]);
        return totalFloors;
      };
      apiParameters.apiObj.setActiveFloor = (f) => {
        const {
          station,
          dispatchFitExtent,
          dispatchSetActivePlan,
          dispatchSetActiveFloor,
        } = this.props;
        const activePlan = station.plans.find(
          (p) => p.plan_type === f.plan.plan_type,
        );
        const newFloor = activePlan.floors.find(
          (floor) => floor.iabp_name === f.iabp_name,
        );
        if (newFloor) {
          dispatchFitExtent(newFloor.extent);
          dispatchSetActivePlan(activePlan);
          dispatchSetActiveFloor(newFloor);
        }
      };
    }
  }

  isClickableFeature(feature) {
    const { planAccessFeatures, serviceFeatures } = this.props;
    return !!(
      serviceFeatures.find((f) => f === feature) ||
      planAccessFeatures.find((f) => f === feature) ||
      feature.get('isClusterFeature')
    );
  }

  zoomOnFeatures(features) {
    const { activePlan, dispatchTogglePlan, station } = this.props;
    const firstFeat = features[0];
    const mustTogglePlan = firstFeat.get('isClusterFeature')
      ? activePlan.plan_type === 'outdoor_web'
      : firstFeat.get('plan_layer') !== activePlan.plan_layer;

    if (mustTogglePlan) {
      dispatchTogglePlan(activePlan, station);
    }
  }

  updateFloor(floorId, coordinate) {
    const { activePlan, activeFloor, dispatchSetActiveFloor } = this.props;
    let newFloorId = floorId;
    if (
      !activePlan ||
      activePlan.plan_type === 'outdoor_web' ||
      !activePlan.floors
    ) {
      return;
    }

    if (!newFloorId) {
      this.map.getLayers().forEach((l) => {
        if (l instanceof VectorLayer && l.getSource() instanceof VectorSource) {
          const closest = l
            .getSource()
            .getClosestFeatureToCoordinate(coordinate);
          if (!newFloorId && closest && closest.get('floor_id')) {
            newFloorId = closest.get('floor_id');
          }
        }
      });
    }

    const floor = activePlan.floors.find((f) => f.ids.includes(newFloorId));
    if (floor && (activeFloor && activeFloor.id) !== floor.id) {
      dispatchSetActiveFloor(floor);
    }
  }

  updateZoom(zoomAction) {
    this.zoomButtonActive = true;
    const { zoom, dispatchAnimate } = this.props;
    this.map.getView().cancelAnimations();
    dispatchAnimate({
      zoom: zoom + zoomAction,
    });
  }

  areaStyleFunction(feature) {
    const { clickedFeatures } = this.props;
    const hlAreas = clickedFeatures.map((f) => f.get('area_id'));
    return hlAreas.indexOf(feature.get('nid')) > -1
      ? LayerStyle.areaStyle
      : null;
  }

  renderFooter() {
    const { station, lng, t, disabled, apiParameters } = this.props;

    if (disabled.includes('footer') || !station) {
      return null;
    }

    return (
      <Footer
        ref={(node) => {
          this.refFooter = node;
        }}
      >
        {/* Open the OneTrust consent management dialog */}
        {apiParameters.domainConsentId && (
          <ActionLink
            onClick={() => {
              window.Optanon.ToggleInfoDisplay();
            }}
            title={t('Cookie-Einstellungen')}
          >
            {t('Cookie-Einstellungen')}
          </ActionLink>
        )}
        <BlankLink
          title={t('Rechtliches')}
          href={`${CONF.iabpFrontendUrl}/legallines/rechtliches/${lng}`}
        >
          {t('Rechtliches')}
        </BlankLink>
        <BlankLink
          title={t('Impressum')}
          href={`${CONF.iabpFrontendUrl}/legallines/impressum/${lng}`}
        >
          {t('Impressum')}
        </BlankLink>
        <a
          href="https://doc.trafimage.ch"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('Developer Portal')}
        </a>
      </Footer>
    );
  }

  renderLegendMenu() {
    const { disabled } = this.props;

    if (disabled.includes('menu')) {
      return null;
    }
    return <CLegendMenu />;
  }

  renderFloorSwitcher() {
    const { disabled, activePlan } = this.props;

    if (disabled.includes('mapControls') || !activePlan) {
      return null;
    }

    return <FloorSwitcher />;
  }

  renderOutdoorPlanButtons() {
    const { disabled, station } = this.props;

    if (disabled.includes('spyLayer') || !station) {
      return null;
    }

    return <OutdoorPlanButton />;
  }

  renderPlanButtons() {
    const { disabled, station } = this.props;

    if (disabled.includes('mapControls') || !station) {
      return null;
    }

    return (
      <div className="tm-button-bar">
        {this.renderOutdoorPlanButtons()}
        {this.renderFloorSwitcher()}
      </div>
    );
  }

  renderPermalinkButton() {
    const { isEmbedded } = this.props;

    if (isEmbedded) {
      return <PermalinkButton />;
    }

    return null;
  }

  renderZoomButtons() {
    const { disabled, station, t } = this.props;

    if (disabled.includes('mapControls') || !station) {
      return null;
    }

    return (
      <div className="tm-zooms-wrapper">
        <Button
          className="tm-button tm-round-white"
          title={t('Zoom in')}
          onClick={() => this.updateZoom(1)}
        >
          <FaPlus focusable={false} />
        </Button>
        <Button
          className="tm-button tm-round-white"
          title={t('Zoom out')}
          ref={(node) => {
            this.refZoomOut = node;
          }}
          onClick={() => this.updateZoom(-1)}
        >
          <FaMinus focusable={false} />
        </Button>
        {this.renderPermalinkButton()}
      </div>
    );
  }

  renderBarrierFree() {
    const { serviceFeatures, activePlan } = this.props;
    return (
      <BarrierFree
        features={serviceFeatures}
        refTooltip={this.refTooltip}
        refOverlay={this.refOverlay}
        refFooter={this.refFooter}
        refZoomOut={this.refZoomOut}
        extent={this.extentValue}
        activePlan={activePlan}
      />
    );
  }

  renderOverlay(overlayFeature) {
    const { isEmbedded } = this.props;
    return (
      <PlansOverlay
        isEmbedded={isEmbedded}
        contentRef={this.refOverlay}
        observe={this.ref.current}
        feature={overlayFeature}
        map={this.map}
      />
    );
  }

  render() {
    const {
      apiParameters,
      activePlan,
      animationOptions,
      center,
      clickedFeatures,
      hoverFeatures,
      extent,
      history,
      layers,
      resolution,
      station,
      stationAlias,
      isAppWidthSmallerThanS,
      dispatchSetAppSize,
      dispatchSetClickedFeatures,
      dispatchSetHoverFeatures,
      zoom,
      t,
      lng,
      disabled,
      isEmbedded,
    } = this.props;

    const { screenWidth, resizeForceUpdate } = this.state;

    let layerContainer = null;
    let copyright = null;

    if (center && resolution && zoom) {
      this.extentValue = this.map.getView().calculateExtent();
    }

    if (station) {
      if (activePlan) {
        const alias = (station && station.alias) || stationAlias;
        let serviceLayer = (
          <CPlansServiceLayer
            stationAlias={alias}
            isEmbedded={isEmbedded}
            enableClustering={this.enableClustering}
            enableDecluttering={
              !this.enableClustering && this.enableDecluttering
            }
          />
        );

        if (this.enableClustering2) {
          serviceLayer = <PlansServiceLayerCluster stationAlias={alias} />;
        }

        let accessLayer = <CPlansAccessLayer name="PlanAccess" />;
        if (
          this.enableClustering ||
          this.enableClustering2 ||
          this.enableDecluttering
        ) {
          accessLayer = null;
        }

        layerContainer = (
          <>
            {accessLayer}

            <CPlansVectorLayer
              name="Areas"
              url={`${CONF.iabpBackendUrl}/api/v1/areas/${alias}`}
              style={(feat) => this.areaStyleFunction(feat)}
            />

            <CPlansVectorLayer
              name="Labels"
              declutter
              url={`${CONF.iabpBackendUrl}/api/v1/labels/${alias}`}
              style={(f) => this.layerStyle.labelStyleFunction(f)}
            />

            {serviceLayer}

            <PlanLayer />
          </>
        );
      }

      const copyrightText = `© OpenStreetMap contributors, © ${t('SBB')}`;

      copyright = (
        <div className="tm-copyright" aria-label={copyrightText}>
          {copyrightText}
        </div>
      );
    }

    const [popupFeature] = clickedFeatures;
    const tooltipFeature = hoverFeatures.find((f) => f !== popupFeature);
    const switcher =
      zoom <= 16 && !disabled.includes('wkpSwitcher') ? (
        <CWKPSwitcher observe={this.ref.current} />
      ) : null;
    // Add css classes when a feature is disabled.
    const className = `tm-station-plan ${disabled
      .map((d) => `tm-${d}-disabled`)
      .join(' ')}`;

    return (
      <div className={className} ref={this.ref}>
        <ResizeHandler
          observe={this.ref.current}
          stylePropHeight="--tm-vh"
          forceUpdate={resizeForceUpdate}
          onResize={(entries, widthBreakpoint, heightBreakpoint) => {
            this.setState({
              screenWidth: widthBreakpoint,
            });
            dispatchSetAppSize({
              width: widthBreakpoint,
              height: heightBreakpoint,
            });
          }}
        />
        {!screenWidth || !disabled || disabled.includes('header') ? null : (
          <Header screenWidth={screenWidth} lng={lng} />
        )}

        <BasicMap
          ref={this.refMap}
          animationOptions={animationOptions}
          center={center}
          extent={extent}
          layers={layers}
          map={this.map}
          tabIndex={0}
          onFeaturesClick={(features) => this.onFeaturesClick(features)}
          onFeaturesHover={(features, evt) => {
            // Disable tooltip hover on mobile
            if (mouseOnly(evt)) {
              this.onFeaturesHover(features, evt);
            }
          }}
          onMapMoved={(evt) => this.onMapMoved(evt)}
          viewOptions={{
            minZoom: 16,
            maxZoom: 22,
            projection: 'EPSG:21781',
          }}
          resolution={resolution}
          zoom={zoom}
        />
        {copyright}

        {layerContainer}
        {switcher}
        {this.renderLegendMenu()}

        <CPermalink history={history} apiParameters={apiParameters} />

        {this.renderOverlay(popupFeature)}
        {!isAppWidthSmallerThanS ? (
          <Popup
            map={this.map}
            renderHeader={() => {}}
            feature={tooltipFeature}
            className="tm-tooltip"
            ref={(node) => {
              this.refTooltip = node;
            }}
            padding="0px"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.which === 13) {
                dispatchSetHoverFeatures([]);
                dispatchSetClickedFeatures([tooltipFeature]);
                // We stop propagation because we don'want to go in the BarrerFree listener.
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          >
            <PlansTooltip feature={tooltipFeature} />
          </Popup>
        ) : null}

        {this.renderPlanButtons()}
        {this.renderZoomButtons()}

        {this.renderBarrierFree()}

        {this.renderFooter()}
      </div>
    );
  }
}

StationPlan.propTypes = propTypes;
StationPlan.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  disabled: state.iabp.disabled,
  activePlan: state.iabp.activePlan,
  activeFloor: state.iabp.activeFloor,
  isAppWidthSmallerThanS: state.iabp.isAppWidthSmallerThanS,
  animationOptions: state.map.animationOptions,
  center: state.map.center,
  clickedFeatures: state.map.clickedFeatures,
  hoverFeatures: state.map.hoverFeatures,
  extent: state.map.extent,
  layers: state.map.layers,
  resolution: state.map.resolution,
  serviceFeatures: state.iabp.serviceFeatures,
  planAccessFeatures: state.iabp.planAccessFeatures,
  station: state.iabp.station,
  zoom: state.map.zoom,
});

const mapDispatchToProps = {
  dispatchFetchStation: fetchStation,
  dispatchSetActivePlan: setActivePlan,
  dispatchSetClickedFeatures: setClickedFeatures,
  dispatchSetHoverFeatures: setHoverFeatures,
  dispatchSetResolution: setResolution,
  dispatchSetLayers: setLayers,
  dispatchSetCenter: setCenter,
  dispatchSetMenuVisible: setMenuVisible,
  dispatchSetStationSwitcherVisible: setStationSwitcherVisible,
  dispatchSetZoom: setZoom,
  dispatchSetActiveFloor: setActiveFloor,
  dispatchAnimate: animate,
  dispatchTogglePlan: togglePlan,
  dispatchSetAppSize: setAppSize,
  dispatchFitExtent: fitExtent,
  dispatchsetInitialFeatureIdentifier: setInitialFeatureIdentifier,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(StationPlan);
