import { extend, createEmpty } from 'ol/extent';

import {
  FETCH_SERVICE_FEATURES,
  FETCH_STATION,
  FETCH_STATIONS,
  FETCH_PLAN_ACCESSES,
  SET_ACTIVE_PLAN,
  SET_ACTIVE_PLAN_BY_NAME,
  SET_CLICKED_SEARCH_RESULT,
  SET_FILTER_CATEGORIES,
  SET_FILTER_SERVICE,
  SET_MENU_VISIBLE,
  SET_STATION_SWITCHER_VISIBLE,
  SET_DISABLED,
  SET_HIGH_CONTRAST_MODE,
  SET_ACTIVE_FLOOR,
  SET_LANGUAGE,
  SET_SEARCH_VALUE,
  SET_SEARCH_RESULTS,
  TRACK_LINK,
  TOGGLE_EXTERNAL_LINKS,
  SET_INITIAL_FEATURE_IDENTIFIER,
  SET_APP_SIZE,
} from './actions';

const initialState = {
  lng: 'de',
  highContrastMode: false,
  menuVisible: false,
  planAccessFeatures: [],
  services: [],
  stations: [],
  disabled: [],
  stationSwitcherVisible: false,
  initialFeatureIdentifier: undefined,
};

export function getSearchSuggestions(serviceFeatures = [], lng = 'de') {
  // We filter then sort the favorites items
  const favoritesItems = serviceFeatures.filter(
    (item) => item.get && item.get('favorite') === 1,
  );
  favoritesItems.sort((a, b) => {
    const textA = a.get(`field_name_${lng}`);
    const textB = b.get(`field_name_${lng}`);
    if (textA < textB) {
      return -1;
    }
    return textA > textB ? 1 : 0;
  });

  // We group the favorites items by field_name value
  const categories = {};
  favoritesItems.forEach((item) => {
    const categoryId = item.get(`field_name_${lng}`);
    if (!categories[categoryId]) {
      categories[categoryId] = [];
    }
    if (!item.get('exclude_from_search')) {
      categories[categoryId].push(item);
    }
  });

  const searchSuggestions = [];
  Object.keys(categories).forEach((key) => {
    searchSuggestions.push(categories[key]);
  });
  return searchSuggestions.length > 0 ? { searchSuggestions } : null;
}

export default function tralisApp(state = initialState, action) {
  let data;
  if (Array.isArray(action.data)) {
    data = [...action.data];
  } else if (typeof action.data === 'string') {
    data = `${action.data}`;
  } else {
    data = { ...action.data };
  }

  switch (action.type) {
    case FETCH_STATION: {
      const station = data;

      // aggregate floors with the same name
      station.plans = station.plans.map((plan) => {
        const aggFloors = {};
        for (let i = 0; i < plan.floors.length; i += 1) {
          const floor = plan.floors[i];
          aggFloors[floor.iabp_name] = aggFloors[floor.iabp_name] || {
            ...floor,
            extent: createEmpty(),
            names: [],
            ids: [],
          };

          extend(aggFloors[floor.iabp_name].extent, floor.extent);
          aggFloors[floor.iabp_name].ids.push(floor.id);
          aggFloors[floor.iabp_name].names.push(floor.name);
        }
        return {
          ...plan,
          floors: Object.values(aggFloors),
        };
      });
      return {
        ...state,
        station,
      };
    }
    case FETCH_SERVICE_FEATURES:
      return {
        ...state,
        ...getSearchSuggestions(data, state.lng),
        serviceFeatures: data,
      };
    case FETCH_STATIONS:
      return {
        ...state,
        stations: data,
      };
    case SET_ACTIVE_PLAN:
      return {
        ...state,
        activePlan: data,
      };
    case SET_ACTIVE_PLAN_BY_NAME: {
      const newState = { ...state };

      if (state.station) {
        const [newActivePlan] = state.station.plans.filter(
          (p) => p.plan_layer === action.data,
        );

        newState.activePlan = newActivePlan || newState.activePlan;
      }

      return newState;
    }
    case SET_ACTIVE_FLOOR:
      return {
        ...state,
        activeFloor: action.data && data, // Return undefined instead of an empty object
      };
    case SET_FILTER_CATEGORIES: {
      return {
        ...state,
        filterCategories: data,
      };
    }
    case SET_FILTER_SERVICE: {
      return {
        ...state,
        filterService: action.data,
      };
    }
    case SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: data,
        searchValueSource: action.source,
      };
    }
    case TRACK_LINK: {
      return {
        ...state,
        trackLink: data,
      };
    }
    case SET_CLICKED_SEARCH_RESULT: {
      return {
        ...state,
        clickedSearchResult: data,
      };
    }
    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: data,
      };
    }
    case SET_DISABLED: {
      return {
        ...state,
        disabled: data,
      };
    }
    case SET_MENU_VISIBLE: {
      return {
        ...state,
        menuVisible: action.data,
      };
    }
    case SET_HIGH_CONTRAST_MODE: {
      return {
        ...state,
        highContrastMode: action.data,
      };
    }
    case SET_STATION_SWITCHER_VISIBLE: {
      return {
        ...state,
        stationSwitcherVisible: action.data,
      };
    }
    case FETCH_PLAN_ACCESSES: {
      return {
        ...state,
        planAccessFeatures: action.data,
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        ...getSearchSuggestions(state.serviceFeatures, action.data),
        lng: `${action.data}`,
      };
    }
    case SET_APP_SIZE: {
      const width = action.data.width || state.width;
      const height = action.data.height || state.height;
      return {
        ...state,
        width,
        height,
        isAppWidthSmallerThanL: ['s', 'xs', 'm', 'l'].includes(width),
        isAppWidthSmallerThanS: ['s', 'xs'].includes(width),
        isAppHeightSmallerThanS: ['s', 'xs'].includes(height),
      };
    }
    case TOGGLE_EXTERNAL_LINKS: {
      return {
        ...state,
        extLinksVisible: action.data,
      };
    }
    case SET_INITIAL_FEATURE_IDENTIFIER: {
      return {
        ...state,
        initialFeatureIdentifier: action.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
