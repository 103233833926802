import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import qs from 'query-string';
import LegalLines from './app/legals/LegalLines';
import CONF from './config';
import App from './App';
import Doc from './app/doc';

// Only for testing purpose
const params = qs.parse(window.location.search);
const { domainConsentId } = params;

// redirect station names
const stationRedirects = {
  freiburg: 'fribourg',
  zuerich: 'zuerich-hb',
  biel: 'biel-bienne',
  bienne: 'biel-bienne',
};

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/doc" component={() => <Doc />} />
        {Object.keys(stationRedirects).map((key) => (
          <Route
            key={key}
            exact
            path={`/${key}`}
            render={() => <Redirect to={stationRedirects[key]} />}
          />
        ))}
        <Route
          exact
          path="/"
          render={() => {
            const searchParams = qs.parse(window.location.search);
            const url = searchParams.lang
              ? `${CONF.wkpRedirectUrl}&lang=${searchParams.lang}`
              : CONF.wkpRedirectUrl;
            window.location.replace(url);
          }}
        />
        <Route
          exact
          path="/legallines/:doc/:lang"
          component={({ match }) => (
            <LegalLines doc={match.params.doc} language={match.params.lang} />
          )}
        />
        <Route
          exact
          path="/:alias"
          component={({ history, match }) => {
            return (
              <>
                <App
                  history={history}
                  apiParameters={{
                    ...CONF,
                    domainConsentId: domainConsentId || CONF.domainConsentId,
                    ...match.params,
                  }}
                />
              </>
            );
          }}
        />
      </Switch>
    </Router>
  );
};

export default AppRouter;
