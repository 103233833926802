import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../menu/Menu';
import MenuButton from '../menu/MenuButton';

function HeaderLeft() {
  const station = useSelector((state) => state.iabp.station);
  const disabled = useSelector((state) => state.iabp.disabled);

  if (!station) {
    return null;
  }
  return (
    <>
      <MenuButton />

      <Menu />

      <h1
        className={`tm-header-title${
          disabled.includes('menu') ? ' tm-header-title-without-menu' : ''
        }`}
      >
        {station.name}
      </h1>
    </>
  );
}
export default React.memo(HeaderLeft);
