import React from 'react';
import ctIcon, {
  ReactComponent as CombinedTransportSvg,
} from '../img/icons/icon_combined-transport.svg';
import ptIcon, {
  ReactComponent as PublicTransport,
} from '../img/icons/icon_public-transport.svg';
import lfIcon, {
  ReactComponent as LiftFilter,
} from '../img/icons/lift_filter.svg';
import officeIcon, {
  ReactComponent as Office,
} from '../img/icons/icon_offices.svg';
import serviceIcon, {
  ReactComponent as Service,
} from '../img/icons/icon_services.svg';
import gastroIcon, {
  ReactComponent as Gastronomy,
} from '../img/icons/icon_gastronomy.svg';
import shoppingIcon, {
  ReactComponent as Shopping,
} from '../img/icons/icon_shopping.svg';

import ctIconBlue, {
  ReactComponent as CombinedTransportBlueSvg,
} from '../img/icons/contrast/icon_combined-transport_b.svg';
import officeIconBlue, {
  ReactComponent as OfficeBlue,
} from '../img/icons/contrast/icon_offices_b.svg';
import serviceIconBlue, {
  ReactComponent as ServiceBlue,
} from '../img/icons/contrast/icon_services_b.svg';
import gastroIconBlue, {
  ReactComponent as GastronomyBlue,
} from '../img/icons/contrast/icon_gastronomy_b.svg';
import shoppingIconBlue, {
  ReactComponent as ShoppingBlue,
} from '../img/icons/contrast/icon_shopping_b.svg';

import ctIconTrans from '../img/icons/transparent/icon_combined-transport_t.svg';
import ptIconTrans from '../img/icons/transparent/icon_public-transport_t.svg';
import lfIconTrans from '../img/icons/transparent/lift_filter_t.svg';
import officeIconTrans from '../img/icons/transparent/icon_offices_t.svg';
import serviceIconTrans from '../img/icons/transparent/icon_services_t.svg';
import gastroIconTrans from '../img/icons/transparent/icon_gastronomy_t.svg';
import shoppingIconTrans from '../img/icons/transparent/icon_shopping_t.svg';

export const ACCESS_POINTS = 'lift_and_stairs';

export default {
  public_transport: {
    icon: ptIcon,
    icon_contrast: ptIcon,
    icon_transparent: ptIconTrans,
    svg_icon: <PublicTransport focusable={false} />,
    svg_icon_contrast: <PublicTransport focusable={false} />,
    name: 'public_transport',
    planType: 'outdoor_web',
    priority: 30,
    removeFromLegend: true,
    color: [0, 51, 153],
  },
  services: {
    icon: serviceIcon,
    icon_contrast: serviceIconBlue,
    icon_transparent: serviceIconTrans,
    svg_icon: <Service focusable={false} />,
    svg_icon_contrast: <ServiceBlue focusable={false} />,
    color: [158, 95, 155],
    name: 'services',
    planType: 'indoor_web',
    priority: 60,
  },
  combined_traffic: {
    icon: ctIcon,
    icon_contrast: ctIconBlue,
    icon_transparent: ctIconTrans,
    svg_icon: <CombinedTransportSvg focusable={false} />,
    svg_icon_contrast: <CombinedTransportBlueSvg focusable={false} />,
    color: [0, 160, 119],
    name: 'combined_traffic',
    planType: 'outdoor_web',
    priority: 30,
  },
  gastronomy: {
    icon: gastroIcon,
    icon_contrast: gastroIconBlue,
    icon_transparent: gastroIconTrans,
    svg_icon: <Gastronomy focusable={false} />,
    svg_icon_contrast: <GastronomyBlue focusable={false} />,
    color: [194, 92, 27],
    name: 'gastronomy',
    planType: 'indoor_web',
    priority: 40,
  },
  shopping: {
    icon: shoppingIcon,
    icon_contrast: shoppingIconBlue,
    icon_transparent: shoppingIconTrans,
    svg_icon: <Shopping focusable={false} />,
    svg_icon_contrast: <ShoppingBlue focusable={false} />,
    color: [217, 61, 127],
    name: 'shopping',
    planType: 'indoor_web',
    priority: 50,
  },
  offices: {
    icon: officeIcon,
    icon_contrast: officeIconBlue,
    icon_transparent: officeIconTrans,
    svg_icon: <Office focusable={false} />,
    svg_icon_contrast: <OfficeBlue focusable={false} />,
    color: [117, 117, 117],
    name: 'offices',
    planType: 'indoor_web',
    priority: 10,
  },
  pictograms: {
    key: 'pictograms',
    name: 'pictograms',
    removeFromLegend: true,
    priority: 20,
  },
  [ACCESS_POINTS]: {
    icon: lfIcon,
    icon_contrast: lfIcon,
    icon_transparent: lfIconTrans,
    svg_icon: <LiftFilter focusable={false} />,
    svg_icon_contrast: <LiftFilter focusable={false} />,
    name: ACCESS_POINTS,
    planType: 'indoor_web',
    priority: 30,
    color: [0, 51, 153],
  },
};
