import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@geops/react-ui/components/Button';
import { setHighContrastMode } from '../../model/iabp/actions';
import { ReactComponent as Visibility } from '../../img/icons/visibility.svg';

import './BarrierFreeToggler.scss';

function BarrierFreeToggler() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const highContrastMode = useSelector((state) => state.iabp.highContrastMode);

  return (
    <div className="tm-barrierfree-toggler">
      <Button
        onClick={() => dispatch(setHighContrastMode(!highContrastMode))}
        className={`tm-barrierfree-button ${
          highContrastMode ? 'tm-active' : ''
        }`}
        title={t('Barrierefreie Darstellung')}
      >
        <Visibility focusable={false} />
        {t('Barrierefreie Darstellung')}
      </Button>
    </div>
  );
}
export default React.memo(BarrierFreeToggler);
