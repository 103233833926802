export const ANIMATE = 'ANIMATE';
export const SET_LAYERS = 'SET_LAYERS';
export const FIT_EXTENT = 'FIT_EXTENT';
export const SET_CENTER = 'SET_CENTER';
export const SET_CLICKED_FEATURES = 'SET_CLICKED_FEATURES';
export const SET_HOVER_FEATURES = 'SET_HOVER_FEATURES';
export const SET_RESOLUTION = 'SET_RESOLUTION';
export const SET_ZOOM = 'SET_ZOOM';

export const animate = (data) => ({ type: ANIMATE, data });

export const setLayers = (data) => ({ type: SET_LAYERS, data });

export const fitExtent = (data) => ({ type: FIT_EXTENT, data });

export const setCenter = (data) => ({ type: SET_CENTER, data });

export const setClickedFeatures = (data) => ({
  type: SET_CLICKED_FEATURES,
  data,
});

export const setHoverFeatures = (data) => ({ type: SET_HOVER_FEATURES, data });

export const setZoom = (data, button) => ({ type: SET_ZOOM, data, button });

export const setResolution = (data) => ({ type: SET_RESOLUTION, data });
