import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import SelectLinks from '@geops/react-ui/components/SelectLinks';
import IABPPropTypes from '../../model/iabp/prop-types';
import { fetchStation, fetchStations } from '../../model/iabp/actions';

import './StationSwitcher.scss';

const propTypes = {
  onSelect: PropTypes.func,

  // mapStateToProps
  station: IABPPropTypes.station,
  stations: PropTypes.arrayOf(Object),
  disabled: PropTypes.arrayOf(PropTypes.string),

  // mapDispatchToProps
  dispatchFetchStation: PropTypes.func.isRequired,
  dispatchFetchStations: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func,
};

const defaultProps = {
  station: { name: '' },
  stations: [],
  onSelect: () => {},
  disabled: [],
  t: () => {},
};

class StationSwitcher extends PureComponent {
  static sortAlphabetic(a, b) {
    const textA = a.name;
    const textB = b.name;
    if (textA < textB) {
      return -1;
    }
    return textA > textB ? 1 : 0;
  }

  constructor(props) {
    super(props);
    const { dispatchFetchStations } = props;

    dispatchFetchStations();
  }

  getSelectLinks(options) {
    const { station } = this.props;
    return (
      <SelectLinks
        options={options}
        getHref={(option) => `/${option.alias}`}
        getLabel={(option) => option.name}
        getTitle={(option) => option.name}
        isSelected={(option) => station.alias === option.alias}
        onClick={(evt, option) => this.selectStation(option.alias)}
      />
    );
  }

  /**
   * Extra function for testing.
   */
  selectStation(alias) {
    const { dispatchFetchStation, onSelect } = this.props;
    dispatchFetchStation(alias);
    onSelect(alias);
  }

  render() {
    const { t, disabled, station, stations } = this.props;
    if (!station.alias || disabled.includes('search')) {
      return null;
    }

    const top = [];
    const others = [];

    stations.forEach((st) => {
      if (st.has_top_station) {
        top.push(st);
      } else {
        others.push(st);
      }
    });

    top.sort(StationSwitcher.sortAlphabetic);
    others.sort(StationSwitcher.sortAlphabetic);

    return (
      <div className="tm-station-switcher tm-visible">
        <div className="tm-station-title">{t('Weitere Bahnhofpläne')}</div>
        <div className="tm-station-top">{this.getSelectLinks(top)}</div>
        <div className="tm-station-others">{this.getSelectLinks(others)}</div>
      </div>
    );
  }
}

StationSwitcher.propTypes = propTypes;
StationSwitcher.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  station: state.iabp.station,
  stations: state.iabp.stations,
  disabled: state.iabp.disabled,
});

const mapDispatchToProps = {
  dispatchFetchStation: fetchStation,
  dispatchFetchStations: fetchStations,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(StationSwitcher);
