import React from 'react';
import PropTypes from 'prop-types';
import './MenuTooltip.scss';

const propTypes = {
  show: PropTypes.bool,
  content: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  show: false,
  content: null,
  className: 'tm-menu-tooltip',
};

const MenuTooltip = ({ show, content, className, ...props }) => {
  if (!show) {
    return null;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={className} {...props}>
      <div className="tm-menu-tooltip-body">{content}</div>
    </div>
  );
};

MenuTooltip.propTypes = propTypes;
MenuTooltip.defaultProps = defaultProps;

export default React.memo(MenuTooltip);
