import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@geops/react-ui/components/Button';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';

import IABPPropTypes from '../../model/iabp/prop-types';
import { setActivePlan, setActiveFloor } from '../../model/iabp/actions';
import { fitExtent, setClickedFeatures } from '../../model/map/actions';
import FloorButton from './FloorButton';

import './FloorSwitcher.scss';

const propTypes = {
  // mapStateToProps
  activeFloor: IABPPropTypes.floor,
  activePlan: IABPPropTypes.plan.isRequired,
  station: IABPPropTypes.station.isRequired,
  isAppWidthSmallerThanS: PropTypes.bool,
  isAppHeightSmallerThanS: PropTypes.bool,
  isAppWidthSmallerThanL: PropTypes.bool,

  // mapDispatchToProps
  dispatchFitExtent: PropTypes.func.isRequired,
  dispatchSetActivePlan: PropTypes.func.isRequired,
  dispatchSetActiveFloor: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func,
};

const defaultProps = {
  activeFloor: null,
  isAppWidthSmallerThanS: false,
  isAppHeightSmallerThanS: false,
  isAppWidthSmallerThanL: false,

  // react-i18next
  t: (p) => p,
};

class FloorSwitcher extends PureComponent {
  /**
   * Sort floors based on their latitude.
   */
  static sortFloors(floors) {
    const sorted = [...floors];
    sorted.sort(
      (a, b) => parseInt(b.iabp_name, 10) - parseInt(a.iabp_name, 10),
    );

    return sorted;
  }

  getFloorNames(floor) {
    if (!floor) {
      return null;
    }
    const { t } = this.props;
    const names = (floor.names || []).map((n) => t(n)).join(' ,');
    return `${floor.iabp_name} ${names}`;
  }

  selectFloor(floor) {
    const {
      activePlan,
      dispatchFitExtent,
      dispatchSetActivePlan,
      dispatchSetActiveFloor,
      dispatchSetClickedFeatures,
      station,
    } = this.props;

    const [plan] = station.plans.filter((p) => p.floors.indexOf(floor) > -1);
    dispatchFitExtent(floor.extent);
    dispatchSetActiveFloor(floor, 'Switcher');

    if (plan && activePlan.plan_layer !== plan.plan_layer) {
      dispatchSetActivePlan(plan, 'Switcher');
      dispatchSetClickedFeatures([]); // Close popups
    }
  }

  renderMobilePicker(floors) {
    const { activeFloor } = this.props;
    let selectedFloor = activeFloor;

    if (!selectedFloor) {
      // choose floor "0" or first floow
      selectedFloor = floors.find((f) => f.iabp_name === '0') || floors[0];
    }

    // find previous and next floor
    let prevFloor = null;
    let nextFloor = null;

    for (let i = 0; i < floors.length; i += 1) {
      if (floors[i].name === selectedFloor.name) {
        prevFloor = i > 0 ? floors[i - 1] : null;
        nextFloor = i < floors.length - 1 ? floors[i + 1] : null;
        break;
      }
    }

    const prevButtonClass = `tm-prev-floor ${prevFloor ? '' : 'disabled'}`;
    const nextButtonClass = `tm-next-floor ${nextFloor ? '' : 'disabled'}`;

    return (
      <div className="tm-floor-switcher-mobile">
        <Button
          className={`tm-floor-button-mobile ${prevButtonClass}`}
          onClick={() => prevFloor && this.selectFloor(prevFloor)}
        >
          <FiArrowUp />
        </Button>
        <FloorButton
          active={!!activeFloor}
          floor={selectedFloor}
          isMobile
          onClick={(f) => this.selectFloor(f)}
        />
        <Button
          className={`tm-floor-button-mobile ${nextButtonClass}`}
          onClick={() => nextFloor && this.selectFloor(nextFloor)}
        >
          <FiArrowDown />
        </Button>
      </div>
    );
  }

  render() {
    const {
      activePlan,
      activeFloor,
      station,
      isAppWidthSmallerThanS,
      isAppWidthSmallerThanL,
      isAppHeightSmallerThanS,
    } = this.props;

    const isOutdoorActive =
      activePlan && activePlan.plan_type === 'outdoor_web';

    if (isOutdoorActive) {
      return null;
    }

    const [plan] = station.plans.filter((p) => p.plan_type === 'indoor_web');
    const sortedFloors = FloorSwitcher.sortFloors(plan.floors);

    if (
      isAppWidthSmallerThanS ||
      (sortedFloors?.length > 5 && isAppHeightSmallerThanS) ||
      (sortedFloors?.length > 3 && isAppWidthSmallerThanL) // zoom buttons are moved up when height is L
    ) {
      return this.renderMobilePicker(sortedFloors);
    }

    return (
      <ol className="tm-floor-switcher">
        {sortedFloors.map((floor) => (
          <li key={floor.name}>
            <FloorButton
              active={
                !!(
                  activeFloor &&
                  activePlan &&
                  floor.name === activeFloor.name &&
                  activePlan.floors.find((f) => activeFloor.name === f.name)
                )
              }
              floor={floor}
              onClick={(f) => this.selectFloor(f)}
            />
          </li>
        ))}
      </ol>
    );
  }
}

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  activeFloor: state.iabp.activeFloor,
  station: state.iabp.station,
  isAppWidthSmallerThanS: state.iabp.isAppWidthSmallerThanS,
  isAppHeightSmallerThanS: state.iabp.isAppHeightSmallerThanS,
  isAppWidthSmallerThanL: state.iabp.isAppWidthSmallerThanL,
});

const mapDispatchToProps = {
  dispatchFitExtent: fitExtent,
  dispatchSetActivePlan: setActivePlan,
  dispatchSetActiveFloor: setActiveFloor,
  dispatchSetClickedFeatures: setClickedFeatures,
};

FloorSwitcher.propTypes = propTypes;
FloorSwitcher.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(FloorSwitcher);
