import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import Layer from 'react-spatial/layers/Layer';
import VectorLayer from 'react-spatial/layers/VectorLayer';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import GeoJSONFormat from 'ol/format/GeoJSON';
import { setLayers } from '../../model/map/actions';
import IABPPropTypes from '../../model/iabp/prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  onFeaturesLoaded: PropTypes.func,
  declutter: PropTypes.bool,

  activePlan: IABPPropTypes.plan.isRequired,
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  dispatchSetLayers: PropTypes.func.isRequired,

  // react-i18next
  lng: PropTypes.string,
};

const defaultProps = {
  onFeaturesLoaded: () => {},
  declutter: false,
  lng: 'de',
};

export class PlansVectorLayer extends PureComponent {
  constructor(props) {
    super(props);
    const { dispatchSetLayers, layers, name, style, declutter } = this.props;

    this.style = style;
    this.layer = new VectorLayer({
      name,
      olLayer: new OLVectorLayer({
        zIndex: 1,
        declutter,
        source: new VectorSource(),
        style: (f, r) => this.styleFunction(f, r),
      }),
    });
    dispatchSetLayers([...layers, this.layer]);
    this.loadLayer();
  }

  componentDidUpdate(prevProps) {
    const { activePlan, clickedFeatures, url, lng } = this.props;

    if (prevProps.url !== url) {
      this.loadLayer();
    }

    if (prevProps.clickedFeatures !== clickedFeatures) {
      this.layer.olLayer.changed();
    }

    if (prevProps.activePlan !== activePlan) {
      this.layer.olLayer.changed();
    }

    if (prevProps.lng !== lng) {
      this.layer.olLayer.changed();
    }
  }

  styleFunction(feature) {
    const { activePlan } = this.props;
    if (feature.get('plan_layer') === activePlan.plan_layer) {
      return this.style(feature);
    }

    return null;
  }

  loadLayer() {
    const { onFeaturesLoaded, url } = this.props;
    const format = new GeoJSONFormat();

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const features = format.readFeatures(data);
        this.layer.olLayer.getSource().clear();
        this.layer.olLayer.getSource().addFeatures(features);
        onFeaturesLoaded(features);
        return features;
      });
  }

  render() {
    return null;
  }
}

PlansVectorLayer.propTypes = propTypes;
PlansVectorLayer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  activePlan: state.iabp.activePlan,
  clickedFeatures: state.map.clickedFeatures,
  layers: state.map.layers,
});

const mapDispatchToProps = {
  dispatchSetLayers: setLayers,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PlansVectorLayer);
