import { getI18n } from 'react-i18next';
import GeoJSONFormat from 'ol/format/GeoJSON';

import CONF from '../../config';

export const FETCH_SERVICE_FEATURES = 'FETCH_SERVICE_FEATURES';
export const FETCH_STATION = 'FETCH_STATION';
export const FETCH_STATIONS = 'FETCH_STATIONS';
export const SET_ACTIVE_FLOOR = 'SET_ACTIVE_FLOOR';
export const SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN';
export const SET_ACTIVE_PLAN_BY_NAME = 'SET_ACTIVE_PLAN_BY_NAME';
export const SET_FILTER_CATEGORIES = 'SET_FILTER_CATEGORIES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_MENU_VISIBLE = 'SET_MENU_VISIBLE';
export const SET_STATION_SWITCHER_VISIBLE = 'SET_STATION_SWITCHER_VISIBLE';
export const SET_FILTER_SERVICE = 'SET_FILTER_SERVICE';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_CLICKED_SEARCH_RESULT = 'SET_CLICKED_SEARCH_RESULT';
export const TRACK_LINK = 'TRACK_LINK';
export const SET_DISABLED = 'SET_DISABLED';
export const SET_PLAN_FEATURES = 'SET_PLAN_FEATURES';
export const SET_HIGH_CONTRAST_MODE = 'SET_HIGH_CONTRAST_MODE';
export const FETCH_PLAN_ACCESSES = 'FETCH_PLAN_ACCESSES';
export const SET_APP_SIZE = 'SET_APP_SIZE';
export const TOGGLE_EXTERNAL_LINKS = 'TOGGLE_EXTERNAL_LINKS';
export const SET_INITIAL_FEATURE_IDENTIFIER = 'SET_INITIAL_FEATURE_IDENTIFIER';

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(`Impossible to fetch data, response
      status: ${response.status},
      statusText: ${response.statusText}
    `);
  }
  return response;
};

const fetchJsonData =
  (url, type, transform = (a) => a) =>
  (dispatch) =>
    fetch(url)
      .then(handleErrors)
      .then((res) => res.json())
      .then(transform) // Transform the data
      .then((data) => {
        dispatch({ type, data });
        return data;
      });

export const fetchServiceFeatures = (alias, searchParams) => {
  const url =
    `${CONF.iabpBackendUrl}/api/v2/stations/${alias}/` +
    `planservices${searchParams}`;
  return fetchJsonData(url, FETCH_SERVICE_FEATURES, (data) => {
    const format = new GeoJSONFormat();
    return format.readFeatures(data);
  });
};

export const fetchStation = (alias, showUnpublished = false) => {
  const url = `${CONF.iabpBackendUrl}/api/v2/stations/${alias}${
    showUnpublished ? '?show_unpublished=1' : ''
  }`;
  return fetchJsonData(url, FETCH_STATION);
};

export const fetchPlanAccesses = (alias) => {
  const url = `${CONF.iabpBackendUrl}/api/v2/stations/${alias}/planaccess`;
  return fetchJsonData(url, FETCH_PLAN_ACCESSES, (data) => {
    const format = new GeoJSONFormat();
    return format.readFeatures(data);
  });
};

export const fetchStations = () => {
  const url = `${CONF.iabpBackendUrl}/api/v2/stations`;
  return fetchJsonData(url, FETCH_STATIONS);
};

export const setFilterCategories = (data) => (dispatch) => {
  dispatch({ type: SET_FILTER_CATEGORIES, data });
};

export const setFilterService = (data) => (dispatch) => {
  dispatch({ type: SET_FILTER_SERVICE, data });
};

export const setSearchValue = (data, source) => (dispatch) => {
  dispatch({ type: SET_SEARCH_VALUE, data, source });
};

export const trackLink = (data) => (dispatch) => {
  dispatch({ type: TRACK_LINK, data });
};

export const setClickedSearchResult = (data, source) => (dispatch) => {
  dispatch({ type: SET_CLICKED_SEARCH_RESULT, data, source });
};

export const setSearchResults = (data, source) => (dispatch) => {
  dispatch({ type: SET_SEARCH_RESULTS, data, source });
};

export const setDisabled = (data) => (dispatch) => {
  dispatch({ type: SET_DISABLED, data });
};

export const setActivePlan = (data, source) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_PLAN, data, source });
};

export const setActivePlanByName = (data) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_PLAN_BY_NAME, data });
};

export const setActiveFloor = (data, source) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_FLOOR, data, source });
};

export const setHighContrastMode = (data) => (dispatch) => {
  dispatch({ type: SET_HIGH_CONTRAST_MODE, data });
};

export const togglePlan = (activePlan, station) => (dispatch) => {
  const [newActivePlan] = station.plans.filter(
    (p) => activePlan.plan_layer !== p.plan_layer,
  );
  dispatch({ type: SET_ACTIVE_PLAN, data: newActivePlan });
};

export const setMenuVisible = (data) => (dispatch) => {
  dispatch({ type: SET_MENU_VISIBLE, data });
};

export const setStationSwitcherVisible = (data) => (dispatch) => {
  dispatch({ type: SET_STATION_SWITCHER_VISIBLE, data });
};

export const setLanguage = (data) => (dispatch) => {
  getI18n().changeLanguage(data);
  dispatch({ type: SET_LANGUAGE, data });
};

export const setAppSize = (data) => (dispatch) => {
  dispatch({ type: SET_APP_SIZE, data });
};

export const toggleExternalLinks = (data) => (dispatch) => {
  dispatch({ type: TOGGLE_EXTERNAL_LINKS, data });
};

export const setInitialFeatureIdentifier = (data) => (dispatch) => {
  dispatch({ type: SET_INITIAL_FEATURE_IDENTIFIER, data });
};
