import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { withTranslation } from 'react-i18next';
import qs from 'query-string';
import Layer from 'react-spatial/layers/Layer';
import VectorLayer from 'react-spatial/layers/VectorLayer';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { setClickedFeatures, setLayers } from '../../model/map/actions';
import { fetchServiceFeatures, togglePlan } from '../../model/iabp/actions';
import IABPPropTypes from '../../model/iabp/prop-types';
import ClusterLayer, { CLUSTER_MODES } from './ClusterLayer';
import LayerStyle from './LayerStyle';
import CATEGORIES from '../ServiceCategories';

const propTypes = {
  stationAlias: PropTypes.string.isRequired,
  enableClustering: PropTypes.bool.isRequired,
  enableDecluttering: PropTypes.bool,
  isEmbedded: PropTypes.bool.isRequired,

  // mapStateToProps
  lng: PropTypes.string,
  activePlan: IABPPropTypes.plan.isRequired,
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  filterService: PropTypes.string,
  highContrastMode: PropTypes.bool.isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  station: IABPPropTypes.station.isRequired,
  resolution: PropTypes.number,
  serviceFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  initialFeatureIdentifier: PropTypes.string,

  // mapDispatchToProps
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
  dispatchFetchServiceFeatures: PropTypes.func.isRequired,
  dispatchTogglePlan: PropTypes.func.isRequired,
  dispatchSetLayers: PropTypes.func.isRequired,
};

const defaultProps = {
  enableDecluttering: false,
  filterCategories: [],
  filterService: null,
  resolution: null,
  serviceFeatures: [],
  initialFeatureIdentifier: undefined,
  lng: 'de',
};

class PlansServiceLayer extends PureComponent {
  static filterFeaturesByPlan(features, plan) {
    return features.filter((f) => f.get('plan_layer') === plan.plan_layer);
  }

  constructor(props) {
    super(props);
    const {
      dispatchSetLayers,
      enableClustering,
      enableDecluttering,
      highContrastMode,
      layers,
      resolution,
      station,
      stationAlias,
    } = this.props;

    let clusterCenter;

    switch (stationAlias) {
      case 'bern':
        clusterCenter = [600147.8, 199678.75];
        break;
      case 'solothurn':
        clusterCenter = [607870, 228178.4];
        break;
      default:
        clusterCenter = undefined;
    }

    if (enableClustering) {
      this.layer = new ClusterLayer({
        resolution,
        style: (f, r) => this.styleFunction(f, r),
        name: 'Services',
        clusterMode: CLUSTER_MODES.all,
        clusterCenter,
      });
    } else {
      this.layer = new VectorLayer({
        name: 'Services',
        olLayer: new OLVectorLayer({
          zIndex: 1,
          declutter: enableDecluttering,
          source: new VectorSource(),
          style: (f, r) => this.styleFunction(f, r),
        }),
      });
    }

    dispatchSetLayers([...layers, this.layer]);
    this.fetchServiceFeatures();

    const { extent } = station;
    this.layerStyle = new LayerStyle({
      gridExtent: extent,
      highContrastMode,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      activePlan,
      clickedFeatures,
      filterCategories,
      filterService,
      highContrastMode,
      resolution,
      serviceFeatures,
      stationAlias,
      enableClustering,
    } = this.props;

    if (prevProps.stationAlias !== stationAlias) {
      this.fetchServiceFeatures();
    }

    if (filterCategories !== prevProps.filterCategories) {
      this.layer.olLayer.changed();
    }

    if (filterService !== prevProps.filterService) {
      this.layer.olLayer.changed();
    }

    if (prevProps.clickedFeatures !== clickedFeatures) {
      const [feat] = clickedFeatures;

      if (feat && (feat.get('isClusterFeature') || feat.get('features'))) {
        this.onClusterClick(feat);
      }
      this.layer.olLayer.changed();
    }

    if (
      activePlan &&
      (activePlan !== prevProps.activePlan ||
        serviceFeatures !== prevProps.serviceFeatures)
    ) {
      const planFeatures =
        activePlan.plan_type === 'outdoor_web'
          ? serviceFeatures
          : PlansServiceLayer.filterFeaturesByPlan(serviceFeatures, activePlan);

      this.layer.olLayer.getSource().clear();
      if (enableClustering) {
        this.layer.addFeatures(planFeatures);
      } else {
        this.layer.olLayer.getSource().addFeatures(planFeatures);
      }
    }

    if (
      activePlan !== prevProps.activePlan ||
      (resolution && prevProps.resolution !== resolution)
    ) {
      let clusterMode = CLUSTER_MODES.all;

      if (activePlan.plan_type === 'indoor_web') {
        // no clustering for last 2 zoom levels
        const maxClusterResolution =
          activePlan.resolutions[activePlan.resolutions.length - 2];

        clusterMode =
          resolution <= maxClusterResolution
            ? CLUSTER_MODES.none
            : CLUSTER_MODES.distance;
      }

      if (this.layer instanceof ClusterLayer) {
        this.layer.setResolution(resolution);
        this.layer.setClusterMode(clusterMode);
        // cluster clicked feature
        if (this.unclusteredFeature) {
          this.layer.reClusterFeature(this.unclusteredFeature);
          this.unclusteredFeature = null;
        }
        this.layer.updateCluster();
      }
    }

    if (highContrastMode !== prevProps.highContrastMode) {
      this.layerStyle.setHighContrastMode(highContrastMode);
      this.layer.olLayer.changed();
    }
  }

  /**
   * Called if a cluster feature is clicked.
   * @param {Feature} feature Clicked cluster feature.
   */
  onClusterClick(feature) {
    const {
      activePlan,
      dispatchSetClickedFeatures,
      dispatchTogglePlan,
      station,
    } = this.props;
    dispatchSetClickedFeatures([]);

    this.layer.expandClusterFeature(feature);
    this.unclusteredFeature = feature;

    // toggle plan or expand
    const planClickedFeatures = PlansServiceLayer.filterFeaturesByPlan(
      feature.get('features'),
      activePlan,
    );

    if (planClickedFeatures.length === 0) {
      dispatchTogglePlan(activePlan, station);
    }
  }

  getClusterMode() {
    const { activePlan } = this.props;
    return activePlan.plan_type === 'outdoor_web'
      ? CLUSTER_MODES.all
      : CLUSTER_MODES.distance;
  }

  fetchServiceFeatures() {
    const { dispatchFetchServiceFeatures, stationAlias } = this.props;

    const params = {
      ...qs.parse(window.location.search),
    };

    const { published, time } = params;
    const paramsArray = [];

    if (published) {
      paramsArray.push(`published=${published}`);
    }

    if (time) {
      paramsArray.push(`time=${time}`);
    }

    const filterParams = published || time ? `?${paramsArray.join('&')}` : '';

    dispatchFetchServiceFeatures(stationAlias, filterParams);
  }

  styleFunction(feature, resolution) {
    const {
      activePlan,
      filterCategories,
      filterService,
      lng,
      enableDecluttering,
      clickedFeatures,
      initialFeatureIdentifier,
      isEmbedded,
    } = this.props;
    const isValidPlan =
      feature.get('isClusterFeature') ||
      feature.get('plan_layer') === activePlan.plan_layer;

    const isValidCategory =
      !filterCategories.length ||
      CATEGORIES[feature.get('category')].removeFromLegend ||
      filterCategories.includes(feature.get('category'));

    const isValidFeature =
      !filterService ||
      new RegExp(filterService).test(
        feature.get(`field_name_${lng}`) + feature.get(`tags_${lng}`),
      );

    const [overlayFeature] = clickedFeatures;

    if (isValidPlan && isValidCategory && isValidFeature) {
      if (enableDecluttering) {
        return this.layerStyle.serviceStyleFunctionDeclutter(
          feature,
          feature === overlayFeature,
          lng,
        );
      }

      const isInitialSelectedWhenEmbedded =
        isEmbedded &&
        feature.get('url_identifier') &&
        initialFeatureIdentifier &&
        initialFeatureIdentifier === feature.get('url_identifier');

      return this.layerStyle.serviceStyleFunction(
        feature,
        resolution,
        activePlan.plan_type === 'outdoor_web',
        feature === overlayFeature,
        isEmbedded,
        isInitialSelectedWhenEmbedded,
        lng,
      );
    }
    return null;
  }

  render() {
    return null;
  }
}

PlansServiceLayer.propTypes = propTypes;
PlansServiceLayer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  activePlan: state.iabp.activePlan,
  clickedFeatures: state.map.clickedFeatures,
  filterCategories: state.iabp.filterCategories,
  filterService: state.iabp.filterService,
  highContrastMode: state.iabp.highContrastMode,
  layers: state.map.layers,
  station: state.iabp.station,
  resolution: state.map.resolution,
  serviceFeatures: state.iabp.serviceFeatures,
  initialFeatureIdentifier: state.iabp.initialFeatureIdentifier,
});

const mapDispatchToProps = {
  dispatchFetchServiceFeatures: fetchServiceFeatures,
  dispatchTogglePlan: togglePlan,
  dispatchSetClickedFeatures: setClickedFeatures,
  dispatchSetLayers: setLayers,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PlansServiceLayer);
