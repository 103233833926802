import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import LegendMenuItem from './LegendMenuItem';
import Notification from '../notification/Notification';
import IABPPropTypes from '../../model/iabp/prop-types';
import {
  setFilterCategories,
  setMenuVisible,
  setActivePlan,
} from '../../model/iabp/actions';
import { fitExtent, setClickedFeatures } from '../../model/map/actions';
import CATEGORIES from '../ServiceCategories';

import './LegendMenu.scss';

const propTypes = {
  // mapStateToProps
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  station: IABPPropTypes.station,
  isAppWidthSmallerThanS: PropTypes.bool,
  activePlan: IABPPropTypes.plan,

  // mapDispatchToProps
  dispatchSetFilterCategories: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
  dispatchSetMenuVisible: PropTypes.func.isRequired,
  dispatchSetActivePlan: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  // mapStateToProps
  activePlan: null,
  station: null,
  filterCategories: [],
  isAppWidthSmallerThanS: false,
};

class LegendMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.timeoutMs = 3000;
    this.state = {
      categoryClicked: null,
      categoryMsg: null,
    };
    this.categories = Object.values(CATEGORIES);
    this.toggleCategory = this.toggleCategory.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutShowMsg);
  }

  switchActivePlan(planType) {
    const { dispatchSetActivePlan, station } = this.props;

    if (!station) {
      return;
    }

    const [newActivePlan] = station.plans.filter(
      (p) => p.plan_type === planType,
    );
    dispatchSetActivePlan(newActivePlan);
  }

  toggleCategory(category) {
    const {
      t,
      isAppWidthSmallerThanS,
      dispatchSetFilterCategories,
      dispatchSetClickedFeatures,
      dispatchSetMenuVisible,
      filterCategories,
      activePlan,
    } = this.props;

    const { planType, name } = category;

    if (isAppWidthSmallerThanS) {
      // Hide menu on mobile.
      dispatchSetMenuVisible(false);
    }

    const nbCategories = Object.values(CATEGORIES).filter(
      ({ removeFromLegend }) => !removeFromLegend,
    ).length;

    const areAllCategoriesVisible = filterCategories.length === nbCategories;
    if (filterCategories.includes(name)) {
      if (areAllCategoriesVisible) {
        this.displayCategoryMsg(category, `${t(name)} ${t('ausgeblendet')}.`);

        // We display only the selected category
        dispatchSetFilterCategories([name]);
      } else {
        if (filterCategories.length === 1) {
          this.displayCategoryMsg({}, `${t('Alle Kategorie eingeblendet')}.`);
        } else {
          this.displayCategoryMsg(category, `${t(name)} ${t('ausgeblendet')}.`);
        }
        // We add the selected category
        filterCategories.splice(filterCategories.indexOf(name), 1);

        // Always display public transports.
        dispatchSetFilterCategories(
          areAllCategoriesVisible ? [name] : [...filterCategories],
        );
      }
    } else {
      // Toggle active plan based on filter's planType.
      if (activePlan.plan_type !== planType) {
        this.switchActivePlan(planType);
      }

      // We display a specific message when all categories will be visible
      if (filterCategories.length + 1 === nbCategories) {
        this.displayCategoryMsg({}, `${t('Alle Kategorie eingeblendet')}.`);
      } else {
        this.displayCategoryMsg(category, `${t(name)} ${t('eingeblendet')}.`);
      }

      dispatchSetFilterCategories([...filterCategories, name]);
      dispatchSetClickedFeatures([]);
    }
  }

  displayCategoryMsg(category, msg) {
    this.setState({
      categoryClicked: null,
      categoryMsg: null,
    });
    clearTimeout(this.timeoutShowMsg);
    this.timeoutShowMsg = setTimeout(() => {
      this.setState({
        categoryClicked: category,
        categoryMsg: msg,
      });
    }, 50);
  }

  render() {
    const { categoryClicked, categoryMsg } = this.state;

    return (
      <div className="tm-legend-menu">
        {categoryClicked && (
          <Notification
            onNotificationEnd={() => {
              this.setState({
                categoryClicked: null,
                categoryMsg: null,
              });
            }}
          >
            {!!categoryClicked.color && (
              <span
                className="tm-legend-color"
                style={{
                  backgroundColor: `rgb(${categoryClicked.color.toString()})`,
                }}
              />
            )}
            <span>{categoryMsg}</span>
          </Notification>
        )}
        <ul className="tm-legend-menu-content">
          {this.categories.map((category) => {
            return (
              <LegendMenuItem
                key={category.name}
                category={category}
                onClick={this.toggleCategory}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

LegendMenu.propTypes = propTypes;
LegendMenu.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  filterCategories: state.iabp.filterCategories,
  isAppWidthSmallerThanS: state.iabp.isAppWidthSmallerThanS,
  planAccessFeatures: state.iabp.planAccessFeatures,
  serviceFeatures: state.iabp.serviceFeatures,
  activePlan: state.iabp.activePlan,
  station: state.iabp.station,
});

const mapDispatchToProps = {
  dispatchSetFilterCategories: setFilterCategories,
  dispatchSetClickedFeatures: setClickedFeatures,
  dispatchSetMenuVisible: setMenuVisible,
  dispatchSetActivePlan: setActivePlan,
  dispatchFitExtent: fitExtent,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(LegendMenu);
