import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import Feature from 'ol/Feature';
import { containsCoordinate } from 'ol/extent';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import Button from '@geops/react-ui/components/Button';

import EmbeddedOverlay from './EmbeddedOverlay';
import { animate, setClickedFeatures } from '../../model/map/actions';
import { setActiveFloor } from '../../model/iabp/actions';
import IABPPropTypes from '../../model/iabp/prop-types';

import { ReactComponent as Rolltreppe } from '../../img/icons/rolltreppe.svg';
import { ReactComponent as Treppe } from '../../img/icons/treppe.svg';
import { ReactComponent as Lift } from '../../img/icons/lift.svg';
import { ReactComponent as Rampe } from '../../img/icons/rampe.svg';

import './PlanAccessContent.scss';

const propTypes = {
  feature: PropTypes.instanceOf(Feature).isRequired,
  isEmbedded: PropTypes.bool,
  onClose: PropTypes.func.isRequired,

  // mapStateToProps
  activePlan: IABPPropTypes.plan.isRequired,
  planAccessFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),

  // mapDispatchToProps
  dispatchAnimate: PropTypes.func.isRequired,
  dispatchSetActiveFloor: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,

  // react-i18n
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  planAccessFeatures: [],
  isEmbedded: false,
};

export class PlanAccessContent extends PureComponent {
  zoomToFeature(feature) {
    const {
      activePlan,
      dispatchAnimate,
      dispatchSetActiveFloor,
      dispatchSetClickedFeatures,
    } = this.props;

    const coord = feature.getGeometry().getCoordinates();

    dispatchAnimate({
      center: coord,
    });
    dispatchSetClickedFeatures([feature]);

    // Services features have a floor_id property
    const floorId = feature.get('floor_id');

    const floor = activePlan.floors.find((f) =>
      floorId ? f.id === floor.id : containsCoordinate(f.extent, coord),
    );

    dispatchSetActiveFloor(floor, 'Popup');
  }

  render() {
    const { onClose, isEmbedded, planAccessFeatures, feature, t } = this.props;
    let icon = null;

    switch (feature.get('type')) {
      case 'rolltreppe':
        icon = <Rolltreppe />;
        break;
      case 'lift':
        icon = <Lift />;
        break;
      case 'treppe':
        icon = <Treppe />;
        break;
      case 'rampe':
        icon = <Rampe />;
        break;
      default:
        icon = <Treppe />;
    }

    const yCoordinate = feature.getGeometry().getCoordinates()[1];
    const upAccessFeatures = [];
    const downAccessFeatures = [];

    // find the closest related feature in upward and downward direction
    feature.get('related').forEach((rel) => {
      const relFeature = planAccessFeatures.find((f) => f.getId() === rel);
      if (relFeature) {
        const yCoordRelated = relFeature.getGeometry().getCoordinates()[1];
        if (yCoordRelated > yCoordinate) {
          upAccessFeatures.push(relFeature);
        } else if (yCoordRelated < yCoordinate) {
          downAccessFeatures.push(relFeature);
        }
      }
    });

    // sort by dist
    upAccessFeatures.sort(
      (a, b) =>
        a.getGeometry().getCoordinates()[1] -
        b.getGeometry().getCoordinates()[1],
    );

    downAccessFeatures.sort(
      (a, b) =>
        b.getGeometry().getCoordinates()[1] -
        a.getGeometry().getCoordinates()[1],
    );

    let arrowUp = null;
    let arrowDown = null;

    if (upAccessFeatures.length) {
      const text = t('Nach oben');
      arrowUp = (
        <Button
          title={text}
          className="tm-access-direction"
          onClick={() => this.zoomToFeature(upAccessFeatures[0])}
        >
          <div className="tm-access-button">
            <MdArrowUpward focusable={false} />
          </div>
          <span>{text}</span>
        </Button>
      );
    }

    if (downAccessFeatures.length) {
      const text = t('Nach unten');
      arrowDown = (
        <Button
          className="tm-access-direction"
          title={text}
          onClick={() => this.zoomToFeature(downAccessFeatures[0])}
        >
          <div className="tm-access-button">
            <MdArrowDownward focusable={false} />
          </div>
          <span>{text}</span>
        </Button>
      );
    }

    const title = t(feature.get('type'));
    if (isEmbedded) {
      return (
        <EmbeddedOverlay
          onClose={onClose}
          logo={<div className="tm-overlay-logo">{icon}</div>}
          title={title}
        />
      );
    }
    return (
      <div className="tm-access-body">
        <div className="tm-access-content">
          <div className="tm-access-header">
            <div className="tm-access-type">{title}.</div>
            <div className="tm-access-icon">{icon}</div>
          </div>
          <div className="tm-access-info">
            {arrowUp}
            {arrowDown}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  planAccessFeatures: state.iabp.planAccessFeatures,
});

const mapDispatchToProps = {
  dispatchAnimate: animate,
  dispatchSetActiveFloor: setActiveFloor,
  dispatchSetClickedFeatures: setClickedFeatures,
};

PlanAccessContent.propTypes = propTypes;
PlanAccessContent.defaultProps = defaultProps;

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PlanAccessContent);
