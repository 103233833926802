import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import map from './map/reducers';
import iabp from './iabp/reducers';
import tracker from './tracker';

// Allow to use Redux dev tools in FF and Chrome
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  map,
  iabp,
});

export default createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk, tracker)),
);
