import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import qs from 'query-string';
import PropTypes from 'prop-types';

const languages = ['de', 'fr', 'it', 'en'];
const title = {
  de: '{station} Bahnhofplan | SBB',
  fr: '{station} plan de gare | CFF',
  it: '{station} piano della stazione | FFS',
  en: '{station} station plan | SBB',
};
const descr = {
  de: 'Der SBB Bahnhofplan von {station} zeigt Ihnen die Lage aller Gleise, Schalter, Shops, Restaurants sowie die Umsteigemöglichkeiten auf Busse, Taxi, Carsharing usw.',
  fr: "Le plan de gare CFF de {station} vous montre l'emplacement de toutes les voies, des guichets, des magasins, des restaurants ainsi que les possibilités de transfert vers les bus, les taxis, le carsharing, etc.",
  it: 'Il piano della stazione FFS di {station} mostra la posizione di tutti i binari, le biglietterie, i negozi, i ristoranti e le opzioni di trasferimento verso autobus, taxi, car sharing, ecc.',
  en: 'The SBB station plan of {station} shows you the location of all tracks, ticket counters, shops, restaurants as well as the transfer options to buses, taxis, car sharing, etc.',
};

// Don't display consent for cypress, it breaks all test
const isCI = process.env.REACT_APP_CI === 'true';
const tracker = process.env.REACT_APP_TRACKER;

/**
 * This component defined some additionnal Head tags for seo.
 * It is only used in non embedded mode.
 */
function Head({ apiParameters }) {
  const { i18n } = useTranslation();
  const stationName = useSelector((state) => (state.iabp.station || {}).name);
  const lang = i18n.language;
  const parsed = qs.parseUrl(window.location.href);

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title[lang].replace('{station}', stationName)}</title>
      <meta
        name="description"
        content={descr[lang].replace('{station}', stationName)}
      />
      {!!tracker && <script src={tracker} async />}
      {!isCI && [
        // <!-- OneTrust Cookies Consent Notice start -->

        // <script src="https://cookiesstaging.blob.core.windows.net/scripttemplates/otSDKStub.js"" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="af49c1fc-c518-468b-abe5-236656749d6b"></script>
        // <script type="text/javascript">
        // function OptanonWrapper() { }
        // </script>
        // <!-- OneTrust Cookies Consent Notice end -->
        // <script
        //   src="https://cdn.cookielaw.org/opt-out/otCCPAiab.js"
        //   type="text/javascript"
        //   charset="UTF-8"
        //   ccpa-opt-out-lspa="true"
        // />,

        <script
          key="onetrust"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={apiParameters.domainConsentId}
          data-language={`${lang}-ch`}
        />,
        <script key="onetrustcb" type="text/javascript">
          {`
            function OptanonWrapper(a,b) {
              if (!Optanon.IsAlertBoxClosed()) {
                return;
              }
              piwik.push(['setConsentGiven']);
              if (!/,C0002,/.test(OptanonActiveGroups)) {
                piwik.push(['disableCookies']);
              }
            }
            `}
        </script>,
      ]}
      {languages.map((language, idx) => {
        const href = { ...parsed };
        href.query.lang = language;
        return (
          <link
            key={language}
            hrefLang={language}
            rel={idx === 0 ? 'canonical' : 'alternate'}
            href={qs.stringifyUrl(href)}
          />
        );
      })}
    </Helmet>
  );
}

Head.propTypes = {
  apiParameters: PropTypes.shape().isRequired,
};

export default Head;
