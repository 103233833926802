import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layer from 'react-spatial/layers/Layer';
import VectorLayer from 'react-spatial/layers/VectorLayer';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';

import { setLayers } from '../../model/map/actions';
import { fetchPlanAccesses } from '../../model/iabp/actions';
import IABPPropTypes from '../../model/iabp/prop-types';
import LayerStyle from './LayerStyle';
import { ACCESS_POINTS } from '../ServiceCategories';

const propTypes = {
  activePlan: IABPPropTypes.plan.isRequired,
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  planAccessFeatures: PropTypes.arrayOf(PropTypes.shape()),
  station: IABPPropTypes.station,
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  lng: PropTypes.string,

  // mapDispatchToProps
  dispatchSetLayers: PropTypes.func.isRequired,
  dispatchFetchPlanAccesses: PropTypes.func.isRequired,
};

const defaultProps = {
  station: undefined,
  filterCategories: [],
  planAccessFeatures: undefined,
  lng: 'de',
};

export class PlansAccessLayer extends PureComponent {
  constructor(props) {
    super(props);
    const { dispatchSetLayers, dispatchFetchPlanAccesses, layers, station } =
      this.props;

    this.layerStyle = new LayerStyle();
    this.layer = new VectorLayer({
      name: 'PlanAccess',
      olLayer: new OLVectorLayer({
        zIndex: 1,
        source: new VectorSource(),
        style: (f, r) => this.styleFunction(f, r),
      }),
    });

    dispatchSetLayers([...layers, this.layer]);

    if (station) {
      dispatchFetchPlanAccesses(station.alias);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activePlan,
      clickedFeatures,
      dispatchFetchPlanAccesses,
      planAccessFeatures,
      filterCategories,
      station,
    } = this.props;

    if (prevProps.clickedFeatures !== clickedFeatures) {
      this.layer.olLayer.changed();
    }

    if (filterCategories !== prevProps.filterCategories) {
      this.layer.olLayer.changed();
    }

    if (prevProps.activePlan !== activePlan) {
      this.layer.olLayer.changed();
    }

    if (prevProps.planAccessFeatures !== planAccessFeatures) {
      this.layer.olLayer.getSource().clear();
      this.layer.olLayer.getSource().addFeatures(planAccessFeatures);
    }

    if (station && station !== prevProps.station) {
      dispatchFetchPlanAccesses(station.alias);
    }
  }

  styleFunction(feature, resolution) {
    const { activePlan, filterCategories, clickedFeatures, lng } = this.props;
    const [overlayFeature] = clickedFeatures;

    const isValidCategory =
      !filterCategories.length || filterCategories.includes(ACCESS_POINTS);

    if (
      isValidCategory &&
      feature.get('plan_layer') === activePlan.plan_layer
    ) {
      return this.layerStyle.accessStyleFunction(
        feature,
        resolution,
        activePlan.plan_type === 'outdoor_web',
        feature === overlayFeature,
        lng,
      );
    }

    return null;
  }

  render() {
    return null;
  }
}

PlansAccessLayer.propTypes = propTypes;
PlansAccessLayer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  clickedFeatures: state.map.clickedFeatures,
  layers: state.map.layers,
  filterCategories: state.iabp.filterCategories,
  planAccessFeatures: state.iabp.planAccessFeatures,
  station: state.iabp.station,
  lng: state.iabp.lng,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchPlanAccesses: (alias) => {
    dispatch(fetchPlanAccesses(alias));
  },
  dispatchSetLayers: (layers) => {
    dispatch(setLayers(layers));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansAccessLayer);
