import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import OLMap from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import WMTSSource from 'ol/source/WMTS';
import WMTSTilegrid from 'ol/tilegrid/WMTS';
import { getCenter } from 'ol/extent';
import { FaLink } from 'react-icons/fa';
import Layer from 'react-spatial/layers/Layer';
import Overlay from '@geops/react-ui/components/Overlay';
import Map from 'react-spatial/components/BasicMap';
import { trackLink } from '../../model/iabp/actions';
import IABPPropTypes from '../../model/iabp/prop-types';
import CONF from '../../config';

import './WKPSwitcher.scss';

const propTypes = {
  dispatchTrackLink: PropTypes.func,
  station: IABPPropTypes.station,
  isAppWidthSmallerThanS: PropTypes.bool,
  observe: PropTypes.object,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  dispatchTrackLink: () => null,
  station: undefined,
  isAppWidthSmallerThanS: undefined,
  observe: null,
};

const defaultHeight = 300;
const mobileSize = {
  minimalHeight: '10%',
  maximalHeight: '40%',
  defaultSize: {
    height: defaultHeight,
    width: '100%',
  },
};

export class WKPSwitcher extends PureComponent {
  static openWKP(center, dispatchTrackLink) {
    const url =
      `${CONF.wkpUrl}/#/ch.sbb.netzkarte` +
      `?zoom=8&x=${center[0]}&y=${center[1]}`;
    dispatchTrackLink({
      category: { primaryCategory: 'Map', subCategory1: 'WKPSwitcher' },
      eventInfo: { destination: url, eventName: 'Navigation' },
      geoData: center,
    });
    window.open(url, '_blank');
  }

  constructor(props) {
    super(props);

    const resolutions = [750, 500, 250, 100, 50, 20, 10, 5, 2.5, 1, 0.5];
    const matrixIds = resolutions.map((r, i) => `${i}`);

    this.layers = [
      new Layer({
        name: 'Netzkarte',
        olLayer: new TileLayer({
          zIndex: 0,
          source: new WMTSSource({
            url:
              `${CONF.wkpUrl}/raster/wmts/netzkarte_relief` +
              '/swissgrid/{TileMatrix}/{TileCol}/{TileRow}.png',
            requestEncoding: 'REST',
            tileGrid: new WMTSTilegrid({
              origin: [420000, 350000],
              resolutions,
              matrixIds,
            }),
          }),
        }),
      }),
    ];

    this.map = new OLMap({ controls: [], interactions: [] });

    this.state = {
      visible: true,
    };
  }

  componentDidMount() {
    this.setState({
      visible: true,
    });
  }

  renderSwitcher() {
    const { dispatchTrackLink, station, t } = this.props;

    const { extent } = station;
    const center = getCenter(extent);
    const title = t('Zur Karte wechseln');

    return (
      <div
        className="tm-wkp-switcher"
        aria-label={title}
        onClick={() => WKPSwitcher.openWKP(center, dispatchTrackLink)}
        onKeyPress={(e) =>
          e.which === 13 && WKPSwitcher.openWKP(center, dispatchTrackLink)
        }
        tabIndex="0"
        role="button"
      >
        <Map
          className="tm-wkp-map"
          layers={this.layers}
          map={this.map}
          center={center}
          zoom={15}
          tabIndex={-1}
        />
        <div className="tm-wkp-link">
          <FaLink focusable={false} />
          {` ${title}.`}
        </div>
      </div>
    );
  }

  render() {
    const { station, isAppWidthSmallerThanS, observe } = this.props;
    const { visible } = this.state;

    if (!visible || !station) {
      return null;
    }

    if (isAppWidthSmallerThanS) {
      return (
        <Overlay
          observe={observe}
          isMobileResizable
          mobileSize={mobileSize}
          onResizeStop={(evt, handler, div, resized) => {
            // When resizing smaller than the minimum height, it closes the overlay.
            if (
              (resized.height === 0 &&
                [defaultHeight, defaultHeight - 1].includes(
                  div.clientHeight,
                )) ||
              div.clientHeight + resized.height < defaultHeight - 100
            ) {
              this.setState({
                visible: false,
              });
            }
          }}
        >
          {this.renderSwitcher()}
        </Overlay>
      );
    }

    return this.renderSwitcher();
  }
}

WKPSwitcher.propTypes = propTypes;
WKPSwitcher.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  station: state.iabp.station,
  zoom: state.map.zoom,
  isAppWidthSmallerThanS: state.iabp.isAppWidthSmallerThanS,
});

const mapDispatchToProps = {
  dispatchTrackLink: trackLink,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(WKPSwitcher);
