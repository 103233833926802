import 'custom-event-polyfill';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import AppRouter from './AppRouter';
import App from './App';
import CONF from './config';
import registerServiceWorker from './registerServiceWorker';

const target = document.getElementById('root');

/**
 * Backward compatibility with old IABP:
 * Convert window.location.hash to window.location.search
 */
const hashToSearch = (url) => {
  if (url && url.indexOf('?')) {
    return `?${url.split('?')[1]}`;
  }

  return '';
};

if (target) {
  if (CONF.sentryDSN) {
    Sentry.init({ dsn: CONF.sentryDSN });
  }
  if (window.location.hash) {
    window.location.search = hashToSearch(window.location.hash);
    window.location.hash = '';
  }

  ReactDOM.render(<AppRouter />, target);
  if (CONF.pwaActive) {
    registerServiceWorker();
  }
} else {
  const defaultProps = {
    div: 'root',
    isEmbedded: true,
  };

  const prop = 'renderApp';

  window[prop] = (options) => {
    // Ensure backward compatibility
    // --> merge initialState with options [TRAFKLEIN-202]
    const apiParameters = {
      ...defaultProps,
      ...options,
      ...options.initialState,
    };

    if (apiParameters.sentryDSN) {
      Sentry.init({ dsn: apiParameters.sentryDSN });
    }

    apiParameters.alias = apiParameters.alias || apiParameters.stationAlias;

    // --> set disablePermalink=true for sbb pages
    apiParameters.disablePermalink = /sbb.ch/.test(window.location.hostname)
      ? true
      : apiParameters.disablePermalink;

    // Setting disabledCookies to true or false will deactivate the consent process.
    apiParameters.disabledCookies = apiParameters.disabledCookies || false;

    ReactDOM.render(
      <App
        div={apiParameters.div}
        isEmbedded={apiParameters.isEmbedded}
        apiParameters={apiParameters}
      />,
      document.getElementById(apiParameters.div),
    );
  };
}
