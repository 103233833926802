import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'lodash.flowright';
import { withTranslation } from 'react-i18next';
import Feature from 'ol/Feature';
import BlankLink from '@geops/react-ui/components/BlankLink';
import EmbeddedOverlay from './EmbeddedOverlay';
import IABPPropTypes from '../../model/iabp/prop-types';
import { ReactComponent as LinkArrowSVG } from '../../img/icons/link_arrow.svg';

import './RelatedOverlay.scss';

const propTypes = {
  feature: PropTypes.instanceOf(Feature),
  isEmbedded: PropTypes.bool,
  onClose: PropTypes.func.isRequired,

  // mapStateToProps
  lng: PropTypes.string,
  station: IABPPropTypes.station.isRequired,
};

const defaultProps = {
  lng: 'de',
  feature: null,
  isEmbedded: false,
};

class RelatedOverlay extends PureComponent {
  render() {
    const { feature, station, lng, onClose, isEmbedded } = this.props;

    if (!feature) {
      return null;
    }
    const orderedInfo = [...feature.get('related_information')].sort((a, b) => {
      if (a[`field_name_${lng}`] < b[`field_name_${lng}`]) {
        return -1;
      }
      if (a[`field_name_${lng}`] > b[`field_name_${lng}`]) {
        return 1;
      }
      return 0;
    });
    const logo = <img src={feature.get('logo_url')} alt="Logo" />;
    const title = feature.get(`field_name_${lng}`);

    if (isEmbedded) {
      return (
        <EmbeddedOverlay
          onClose={onClose}
          logo={<div className="tm-overlay-logo">{logo}</div>}
          title={title}
        />
      );
    }
    return (
      <>
        <div className="tm-overlay-related-body">
          <div className="tm-overlay-related-content">
            <div className="tm-related-header">
              <div>{title}.</div>
              <div>{logo}</div>
            </div>
            {orderedInfo.map((info) => (
              <div key={info.nid} className="tm-overlay-related-item">
                <div className="tm-overlay-related-image">
                  <img src={info.logo_url} alt="Logo" />
                </div>
                <div className="tm-overlay-related-title">
                  <BlankLink
                    href={`${station[`sbb_base_path_${lng}`]}${info.sbb_path}`}
                    className="tm-overlay-related-link"
                    title={info[`field_name_${lng}`]}
                  >
                    {info[`field_name_${lng}`]}
                  </BlankLink>
                  <LinkArrowSVG className="tm-overlay-related-arrow" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

RelatedOverlay.propTypes = propTypes;
RelatedOverlay.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
  station: state.iabp.station,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(RelatedOverlay);
