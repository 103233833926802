import { BrowserClient } from '@sentry/browser';
import PropTypes from 'prop-types';
import React from 'react';

import CONF from '../config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const sentryDSN = props.sentryDSN || CONF.sentryDSN;
    if (sentryDSN) {
      this.sentry = new BrowserClient({ dsn: sentryDSN });
    }
  }

  componentDidCatch(error, info) {
    if (this.sentry) {
      this.sentry.captureException(error, { extra: info });
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  sentryDSN: PropTypes.string,
  children: PropTypes.any.isRequired,
};
ErrorBoundary.defaultProps = {
  sentryDSN: '',
};

export default ErrorBoundary;
