import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlankLink from '@geops/react-ui/components/BlankLink';
import { trackLink } from '../../model/iabp/actions';

const propTypes = {
  category: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dispatchTrackLink: PropTypes.func.isRequired,
  eventInfo: PropTypes.shape(),
  geoData: PropTypes.shape(),
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const defaultProps = {
  category: {},
  className: '',
  eventInfo: {},
  geoData: {},
};

const TrackLink = ({
  category,
  children,
  className,
  dispatchTrackLink,
  eventInfo,
  geoData,
  href,
  title,
}) => {
  const handleClickLinkOffer = () =>
    dispatchTrackLink({
      category,
      eventInfo: {
        ...eventInfo,
        destination: href,
      },
      geoData,
    });

  return (
    <span
      role="link"
      tabIndex="-1"
      onClick={handleClickLinkOffer}
      onKeyPress={handleClickLinkOffer}
    >
      <BlankLink href={href} className={className} title={title}>
        {children}
      </BlankLink>
    </span>
  );
};

TrackLink.propTypes = propTypes;
TrackLink.defaultProps = defaultProps;

const mapDispatchToProps = {
  dispatchTrackLink: trackLink,
};

export default connect(null, mapDispatchToProps)(TrackLink);
