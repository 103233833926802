import React from 'react';

function ImprintIT() {
  return (
    <div className="tm-imprint">
      <h1>Impressum.</h1>
      <p>
        <b>Responsabilità generale Trafimage.</b>
        <br />
        <br />
        FFS SA
        <br />
        Infrastruttura – Impianti e tecnologia – Operation Center Tecnica –
        Technical Management
        <br />
        Fachbus Trafimage
        <br />
        Poststrasse 6
        <br />
        CH-3072 Ostermundingen
        <br />
        <a href="http://www.trafimage.ch">www.trafimage.ch</a>
        <br />
      </p>
      <p>
        <b>
          Gestione, manutenzione e ulteriore sviluppo delle applicazioni
          Trafimage, integrazione dei dati.
        </b>
        <br />
        <br />
        geOps AG
        <br />
        Solothurnerstrasse 235
        <br />
        CH-4600 Olten
        <br />
        <a href="http://www.geops.ch">www.geops.ch</a>
        <br />
      </p>
      <p>
        <b>Realizzazione di cartine e piante delle stazioni.</b>
        <br />
        <br />
        evoq communications AG
        <br />
        Ottikerstrasse 59
        <br />
        CH-8006 Zürich
        <br />
        <a href="http://www.evoq.ch">www.evoq.ch</a>
        <br />
      </p>
    </div>
  );
}

export default ImprintIT();
