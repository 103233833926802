import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import Feature from 'ol/Feature';
import Button from '@geops/react-ui/components/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as LinkArrowSVG } from '../../img/icons/link_arrow.svg';

import './PlanAccessContent.scss';

const propTypes = {
  feature: PropTypes.instanceOf(Feature).isRequired,
  logo: PropTypes.element.isRequired,
  link: PropTypes.element,
  linkUrl: PropTypes.string,
  extLinksVisible: PropTypes.bool.isRequired,

  // mapStateToProps
  lng: PropTypes.string,

  // react-i18n
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  lng: 'de',
  link: null,
  linkUrl: null,
};

const daysAbbrev = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const formatTime = (time) =>
  time.substring(0, time.length - 3).replace(':', '.');

const getTime = (from, to) => `${formatTime(from)} - ${formatTime(to)} h`;

const ServiceOverlay = ({
  feature,
  logo,
  link,
  linkUrl,
  extLinksVisible,
  lng,
  t,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [showAllHolidays, setShowAllHolidays] = useState(false);
  const formatDate = (d) => {
    const date = new Date(d);
    return `${date.getDate()}. ${t(
      months[date.getMonth()],
    )} ${date.getFullYear()}`;
  };
  const today = new Date();

  useEffect(() => {
    setIsImageLoaded(false);
  }, [feature]);

  const mail = feature.get('mail');
  const telephone = feature.get('telephone');
  const contact =
    linkUrl || telephone || mail ? (
      <>
        {linkUrl ? (
          <div className="tm-overlay-contact-url">
            <a href={linkUrl} rel="noopener noreferrer" target="_blank">
              {linkUrl}
              <LinkArrowSVG
                className="tm-overlay-link-arrow"
                focusable={false}
              />
            </a>
          </div>
        ) : null}
        {mail ? <a href={`mailto:${mail}`}>{mail}</a> : null}
        {telephone ? <a href={`tel:${telephone}`}>{telephone}</a> : null}
      </>
    ) : null;

  const openingHoursVal = feature.get('openhours');
  const openHours =
    openingHoursVal &&
    openingHoursVal.length &&
    openingHoursVal.map((h, idx) => {
      return (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`openhours-${h.time_from}${h.time_to}-${idx}`}
          className="tm-overlay-item"
        >{`${t(daysAbbrev[h.day_from - 1])}.${
          h.day_to ? `-${t(daysAbbrev[h.day_to - 1])}.` : ''
        } ${getTime(h.time_from, h.time_to)}`}</div>
      );
    });

  const featDescription = feature.get('description');
  const description =
    featDescription && featDescription[lng] !== '' && featDescription[lng];

  const holidayDates =
    feature.get('holidays') &&
    feature.get('holidays').filter((f) => f.date && new Date(f.date) >= today);

  const holidays = holidayDates && holidayDates.length && (
    <div>
      <table className="tm-overlay-holidays">
        <tbody>
          {holidayDates.map((h, idx) => (
            <tr
              key={h.name.de}
              className={`tm-overlay-holiday${
                !showAllHolidays && idx > 2 ? ' tm-hidden' : ''
              }`}
            >
              <td>{h.name[lng]}</td>
              <td>{formatDate(h.date)}</td>
              <td className="tm-overlay-holiday-hours">
                {h.hours.map((hour, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={`holidays-${hour.from}${hour.to}-${index}`}>
                    {getTime(hour.from, hour.to)}
                  </span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
        <Button
          className="tm-overlay-holiday-toggle"
          title={!showAllHolidays ? t('mehr anzeigen') : t('weniger anzeigen')}
          onClick={() => setShowAllHolidays(!showAllHolidays)}
        >
          {!showAllHolidays ? t('mehr anzeigen') : t('weniger anzeigen')}
        </Button>
      </table>
    </div>
  );

  const paymentsThumbs =
    feature.get('thumbs') && feature.get('thumbs').payments_and_prohibitions;
  const paymentsVal = feature.get('payments');

  const payments = paymentsVal && paymentsVal.length && (
    <div className="tm-overlay-payments">
      {paymentsVal.map((p, idx) =>
        paymentsThumbs[idx] && paymentsThumbs[idx].small ? (
          <img
            // eslint-disable-next-line react/no-array-index-key
            key={`${p.name}-${idx}`}
            src={`${paymentsThumbs[idx].small}`}
            alt={p.name}
          />
        ) : null,
      )}
    </div>
  );

  const location = feature.get('location_details');

  const serviceName = feature.get(`field_name_${lng}`);

  const images = useMemo(() => {
    return feature.get('thumbs') &&
      feature.get('thumbs').images &&
      feature.get('thumbs').images.length ? (
      <div className="tm-overlay-image">
        <img
          src={`${feature.get('thumbs').images[0].medium}`}
          onLoad={() => {
            setIsImageLoaded(true);
          }}
          onError={() => setIsImageLoaded(true)}
          alt={`${t('Bild')} ${serviceName}`}
        />
      </div>
    ) : null;
  }, [feature, serviceName, t]);

  return (
    <div className="tm-overlay-service-wrapper">
      <div className="tm-overlay-images-wrapper">
        {images}
        {images && !isImageLoaded ? (
          <div className="tm-overlay-image-loader">
            <CircularProgress />
          </div>
        ) : null}
      </div>
      <div
        className={`tm-overlay-body${images ? ' tm-overlay-body-small' : ''}`}
      >
        <div className="tm-overlay-header">
          <div>
            {serviceName}
            {/\.$/.test(serviceName) ? '' : '.'}
          </div>
          {logo}
        </div>
        {openHours ? (
          <>
            <div className="tm-overlay-title">{t('Öffnungszeiten')}.</div>
            {openHours}
          </>
        ) : null}
        {description ? (
          <div
            className="tm-overlay-description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        ) : null}
        {contact ? (
          <>
            <div className="tm-overlay-title">{t('Kontakt')}.</div>
            <div className="tm-overlay-contact">{contact}</div>
          </>
        ) : null}
        {location ? (
          <>
            <div className="tm-overlay-title">{t('Lokalisierung')}.</div>
            <div className="tm-overlay-location">{t(location)}</div>
          </>
        ) : null}
        {holidays ? (
          <>
            <div className="tm-overlay-title">{t('Feiertage')}.</div>
            {holidays}
          </>
        ) : null}
        {payments ? (
          <>
            <div className="tm-overlay-title">{t('Zahlungsmittel')}.</div>
            {payments}
          </>
        ) : null}
        <div
          className={`tm-overlay-link${!extLinksVisible ? ' tm-hidden' : ''}`}
        >
          {link}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lng: state.iabp.lng,
});

const mapDispatchToProps = {};

ServiceOverlay.propTypes = propTypes;
ServiceOverlay.defaultProps = defaultProps;

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ServiceOverlay);
