import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Autocomplete from '@geops/react-ui/components/Autocomplete';

import { ReactComponent as SearchIcon } from '../../img/icons/search.svg';

import CONF from '../../config';

import './DestinationInput.scss';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  uic: PropTypes.number.isRequired,

  // react-i18next
  t: PropTypes.func.isRequired,
};

class DestinationInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      destinations: [],
      destinationInputValue: '',
    };

    // Abort fetch requests
    this.abortController = new AbortController();
  }

  /**
   * Fired if user selects a destination from the input
   * @param {string} value Selected value.
   */
  onInputChange(value) {
    const { onSelect } = this.props;
    this.setState({ destinationInputValue: value });
    if (value) {
      this.loadDestinations(value);
    } else {
      this.setState({ destinations: [] });
      // Reset departures list.
      onSelect();
    }
  }

  /**
   * Selection is selected by click or key event.
   */
  selectDestination(item) {
    const { onSelect } = this.props;
    onSelect(item.id);
    this.setState({
      destinations: [],
      destinationInputValue: item.label,
    });
  }

  /**
   * Load destinations.
   * @param {string} destination Selected destination.
   */
  loadDestinations(value) {
    const { uic } = this.props;

    const url = `${CONF.destinationSearchUrl}${uic}?destination=${value}`;

    this.abortController.abort();
    this.abortController = new AbortController();
    const { signal } = this.abortController;
    fetch(url, { signal })
      .then((response) => response.json())
      .then((data) => {
        const destinations = data.map(({ dest, stop }) => ({
          id: stop,
          label: dest,
        }));

        this.setState({ destinations });
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          // eslint-disable-next-line no-console
          console.warn(`Abort ${url}`);
          return;
        }
        // It's important to rethrow all other errors so you don't silence them!
        // For example, any error thrown by setState(), will pass through here.
        throw err;
      });
  }

  render() {
    const { destinations, destinationInputValue } = this.state;
    const { t } = this.props;

    return (
      <div className="tm-departure-input">
        <Autocomplete
          button={<SearchIcon />}
          value={destinationInputValue}
          items={destinations}
          placeholder={t('Direkt erreichbares Ziel auswählen')}
          renderItem={(item) => item.label}
          getItemKey={(item) => item.id}
          onChange={(value) => {
            this.onInputChange(value);
          }}
          onSelect={(item) => {
            if (!item) {
              return;
            }
            this.selectDestination(item);
          }}
        />
      </div>
    );
  }
}

DestinationInput.propTypes = propTypes;

export default withTranslation()(DestinationInput);
