/* eslint-disable  react/prop-types */
import React from 'react';
import Select from 'react-select';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { MdArrowDropDown, MdClose } from 'react-icons/md';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingLeft: 12,
    width: '100%',
  },
  input: {
    display: 'flex',
    padding: '7px 0px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  singleValue: {
    fontSize: 13,
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    left: 0,
    right: 0,
    fontSize: 13,
  },
}));

// eslint-disable-next-line react/prop-types
const NoOptionsMessage = ({ children }) => (
  <Typography color="textSecondary">{children}</Typography>
);

// eslint-disable-next-line react/prop-types
const inputComponent = ({ inputRef, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div ref={inputRef} {...props} />
);

// eslint-disable-next-line react/prop-types
const Control = ({ selectProps, innerRef, children }) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.classes.input,
        inputRef: innerRef,
        children,
      },
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...selectProps.textFieldProps}
  />
);

// eslint-disable-next-line react/prop-types
const Option = ({ innerRef, isFocused, innerProps, children }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontSize: 13,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...innerProps}
  >
    {children}
  </MenuItem>
);

// eslint-disable-next-line react/prop-types
const SingleValue = ({ children }) => (
  <Typography style={{ fontSize: 13 }}>{children}</Typography>
);

// eslint-disable-next-line react/prop-types
const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

// eslint-disable-next-line react/prop-types
const Menu = ({ selectProps, innerProps, children }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

const Placeholder = () => null;

const IndicatorSeparator = () => null;

// eslint-disable-next-line react/prop-types
const ClearIndicator = ({ innerProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <IconButton {...innerProps}>
    <MdClose />
  </IconButton>
);

// eslint-disable-next-line react/prop-types
const DropdownIndicator = ({ innerProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <IconButton {...innerProps} style={{ padding: 0 }}>
    <MdArrowDropDown />
  </IconButton>
);

const renderIdentifier = (id) => (id ? { value: id, label: id } : null);

// eslint-disable-next-line react/prop-types
export default function Autocomplete({ identifier, setIdentifier, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Select
        value={renderIdentifier(identifier)}
        onChange={(id) => {
          setIdentifier(id ? id.value : null);
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ ...props, classes }}
      />
    </div>
  );
}

Autocomplete.defaultProps = {
  isClearable: true,
  components: {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorSeparator,
    ClearIndicator,
    DropdownIndicator,
  },
  options: [],
};
