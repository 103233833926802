import PropTypes from 'prop-types';

const floor = PropTypes.shape({
  name: PropTypes.string,
  iabp_name: PropTypes.string,
  extent: PropTypes.arrayOf(PropTypes.number),
});

export default {
  floor,
  station: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  plan: PropTypes.shape({
    name: PropTypes.string,
    plan_layer: PropTypes.string,
    extent: PropTypes.arrayOf(PropTypes.number),
    floor: PropTypes.arrayOf(floor),
  }),
  category: PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
    icon_contrast: PropTypes.string,
    icon_transparent: PropTypes.string,
    svg_icon: PropTypes.node,
    svg_icon_contrast: PropTypes.node,
    color: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
    planType: PropTypes.string,
    priority: PropTypes.number,
    removeFromLegend: PropTypes.bool,
  }),
};
