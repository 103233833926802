import debounce from 'lodash.debounce';

import i18n from '../i18n';
import {
  FETCH_STATION,
  SET_ACTIVE_FLOOR,
  SET_ACTIVE_PLAN,
  SET_CLICKED_SEARCH_RESULT,
  SET_FILTER_CATEGORIES,
  SET_LANGUAGE,
  SET_MENU_VISIBLE,
  SET_SEARCH_VALUE,
  SET_SEARCH_RESULTS,
  SET_STATION_SWITCHER_VISIBLE,
  TRACK_LINK,
} from './iabp/actions';
import { SET_CLICKED_FEATURES, SET_ZOOM } from './map/actions';

window.digitalDataPlans = {
  pageInstanceID: 584988, // vorgegeben, statisch
  page: { pageInfo: {} },
  event: [],
  search: [],
};

// We need to track the current search value before it is
// overwritten by the label of the selected search result item.
let currentSearchValue = '';
let trackingSearchValue;
const trackSearchValue = (iabp) => {
  if (iabp.searchValue) {
    window.digitalDataPlans.search.push({
      name: 'main search',
      term: iabp.searchValue,
      results: iabp.searchResults.length + iabp.searchSuggestions.length,
      resultPageNumber: iabp.searchResults.length === 0 ? 0 : 1,
    });
  }
};

window.onerror = function trackError(errorMessage) {
  window.digitalDataPlans.page.attributes = {
    errorCode: '', // Fehlercode
    errorURL: window.location.href, // URL der Page
    errorMessage, // Fehlermeldung
  };
};

let lastZoomLevel = null;

const tracker = (store) => (next) => (action) => {
  const { iabp: prevIabp } = store.getState();
  const result = next(action);
  const { iabp, map } = store.getState();
  const activeFloor =
    iabp.activeFloor && iabp.activeFloor.iabp_name
      ? parseInt(iabp.activeFloor.iabp_name, 10)
      : '';
  const language = iabp.lng || 'de';
  const origin = document.location.host + document.location.pathname;
  if (action.type === FETCH_STATION) {
    window.digitalDataPlans.page = {
      pageInfo: {
        pageID: 'station', // i.d.R. vom WCMS vergeben;alternativ der File Name
        destinationURL: window.location.href, // URL wie im Browser nur am Beginn des Besuchs.
        destinationURI: window.location.pathname, // i.d.R. die deutschsprachige Version für alle Sprachen; ohne Query Strings, ohne Domain
        pageName: iabp.station.name,
        language,
        referringURL: window.location.host,
        referrerAPI: window.SBBPlansApplication ? window.location.href : '', // Page URL der Website, die den Bahnhofsplan via API eingebunden hat
        sysEnv: navigator.userAgent, // Smartphone/Desktop/Tablet etc.
        breadCrumbs: '', // String, nicht in Verwendung
      },
      category: {
        primaryCategory: 'interaktiverBahnhof',
        subCategory1: '', // i.d.R. die deutschsprachige Version der Aufhängung in der Navigation, bspw. "Home" für die Startpage, nicht in Verwendung
        subCategory2: '', // i.d.R. die deutschsprachige Version der ersten Sub-Nav, unter der die Page aufgehangen ist, nicht in Verwendung
        subCategory3: '', // wie oben nur ein Sub-Navilevel tiefer, nicht in Verwendung
        subCategory4: '', // wie oben nur ein Sub-Navilevel tiefer, nicht in Verwendung
      },
    };
    window.digitalDataPlans.event = [];
  } else if (
    action.type === SET_ACTIVE_FLOOR &&
    iabp.activeFloor &&
    action.source
  ) {
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventName: 'Navigation',
        eventClick: 1,
        origin,
        language,
      },
      category: {
        primaryCategory: 'Etagennavigation',
        subCategory1: activeFloor,
        subCategory2: action.source,
      },
    });
  } else if (
    action.type === SET_ACTIVE_PLAN &&
    prevIabp.activePlan &&
    action.data.plan_type !== prevIabp.activePlan.plan_type &&
    action.source
  ) {
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventName: 'Navigation',
        eventClick: 1,
        origin,
        language,
      },
      category: {
        primaryCategory: 'Plannavigation',
        subCategory1: iabp.activePlan.plan_type,
        subCategory2: action.source,
      },
    });
  } else if (action.type === SET_LANGUAGE) {
    window.digitalDataPlans.page.pageInfo.language = language;
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventName: 'Navigation',
        eventClick: 1,
        origin,
        language,
      },
      category: {
        primaryCategory: 'Menu',
        subCategory1: 'Language',
        subCategory3: language,
      },
    });
  } else if (
    action.type === SET_MENU_VISIBLE &&
    prevIabp.menuVisible !== iabp.menuVisible
  ) {
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventName: 'Navigation',
        eventClick: 1,
        origin,
        language,
      },
      category: {
        primaryCategory: 'Menu',
        subCategory1: 'Hauptmenü',
        subCategory3: iabp.menuVisible ? 'geöffnet' : 'geschlossen',
      },
    });
  } else if (
    action.type === SET_STATION_SWITCHER_VISIBLE &&
    prevIabp.stationSwitcherVisible !== iabp.stationSwitcherVisible
  ) {
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventName: 'Navigation',
        eventClick: 1,
        origin,
        language,
      },
      category: {
        primaryCategory: 'Menu',
        subCategory1: 'Bahnhofumschalter',
        subCategory2: iabp.stationSwitcherVisible ? 'geöffnet' : 'geschlossen',
      },
    });
  } else if (action.type === SET_SEARCH_VALUE && action.source !== 'onSelect') {
    currentSearchValue = iabp.searchValue;
    // Tracked by SET_SEARCH_RESULTS ...
  } else if (
    action.type === SET_SEARCH_RESULTS &&
    action.source !== 'onSelect'
  ) {
    if (trackingSearchValue) {
      trackingSearchValue.cancel();
    }
    trackingSearchValue = debounce(trackSearchValue, 500);
    trackingSearchValue(iabp);
  } else if (action.type === SET_CLICKED_SEARCH_RESULT) {
    setTimeout(() => {
      const isSuggestion = Array.isArray(iabp.clickedSearchResult);
      if (isSuggestion && currentSearchValue === '') {
        window.digitalDataPlans.search.push({
          name: 'main search',
          term: `Meist gesucht: ${iabp.searchValue}`,
          results: iabp.searchSuggestions.length,
          resultPageNumber: 1,
        });
      }
      window.digitalDataPlans.event.push({
        eventInfo: {
          eventName: 'click',
          eventType: 'navigation',
          destination: window.location.href,
          label: isSuggestion
            ? `Meist gesucht: ${iabp.searchValue}`
            : iabp.searchValue,
          action: 'Click on search result',
          value: window.digitalDataPlans.search.slice(-1)[0].term,
          resultPageNumber: 1,
          resultPosition:
            (isSuggestion
              ? (iabp.searchResults ? iabp.searchResults.length : 0) +
                iabp.searchSuggestions.findIndex((r) =>
                  r.find(
                    (s) => s.ol_uid === iabp.clickedSearchResult[0].ol_uid,
                  ),
                )
              : iabp.searchResults.findIndex(
                  (r) => r.id === iabp.clickedSearchResult.id,
                )) + 1,
        },
        category: {
          primaryCategory: 'Main search',
        },
      });
    });
  } else if (action.type === SET_CLICKED_FEATURES) {
    map.clickedFeatures.forEach((feature) => {
      window.digitalDataPlans.event.push({
        eventInfo: {
          eventName: 'Icon',
          eventClick: 1,
          origin,
          language,
        },
        category: {
          primaryCategory: i18n.t(feature.get('category') || 'transport', {
            lng: 'de',
          }),
          subCategory1: feature.get('type') || '',
          subCategory2: activeFloor,
          subCategory3:
            feature.get(`field_name_${language}`) || feature.get(`name`),
        },
        geoData: {
          coordinates: feature.getGeometry().getCoordinates(),
        },
      });
    });
  } else if (action.type === SET_FILTER_CATEGORIES) {
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventName: 'Filter Kategorien',
        eventClick: 0,
        language,
      },
      search: {
        onsiteSearchTerm: iabp.filterCategories
          ? iabp.filterCategories.join(', ')
          : '',
      },
    });
  } else if (action.type === SET_ZOOM) {
    const newZoomLevel = Math.round(map.zoom);
    if (lastZoomLevel !== newZoomLevel) {
      window.digitalDataPlans.event.push({
        eventInfo: {
          eventName: 'Navigation',
          eventClick: 1,
          origin,
          language,
        },
        category: {
          primaryCategory: 'Map zoom',
          subCategory1: newZoomLevel,
          subCategory2: action.button ? 'Button' : null,
          subCategory3: lastZoomLevel < newZoomLevel ? 'in' : 'out',
        },
        geoData: {
          coordinates: map.center,
        },
      });
      lastZoomLevel = newZoomLevel;
    }
  } else if (action.type === TRACK_LINK) {
    iabp.trackLink.category = iabp.trackLink.category || {};
    if (iabp.activeFloor && !iabp.trackLink.category.subCategory2) {
      iabp.trackLink.category.subCategory2 = activeFloor;
    }
    window.digitalDataPlans.event.push({
      eventInfo: {
        eventClick: 1,
        origin,
        language,
        ...iabp.trackLink.eventInfo,
      },
      category: iabp.trackLink.category,
      geoData: iabp.trackLink.geoData,
    });
  }
  return result;
};

export default tracker;
