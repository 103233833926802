import React from 'react';

function LegalEN() {
  return (
    <div className="tm-imprint">
      <h1>Legal aspects.</h1>
      <p>
        <b>Legal information and data protection.</b>
      </p>
      <p>
        SBB’s{' '}
        <a href="http://www.sbb.ch/en/meta/legal-information.html">
          legal information
        </a>{' '}
        and{' '}
        <a href="http://www.sbb.ch/en/meta/data-protection.html">
          Privacy Policy
        </a>{' '}
        apply.
        <p>
          Contrary to SBB’s general data protection provisions,
          maps.trafimage.ch stores non-personal usage data such as your IP
          address, the page you last visited, the browser used, the date, the
          time, etc. and analyses it in an anonymised form using{' '}
          <a
            href="http://matomo.org/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Matomo
          </a>{' '}
          and{' '}
          <a
            href="https://www.piano.io/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Piano Analytics
          </a>
          .
        </p>
      </p>

      <p>
        <b>Corporate identity of Trafimage.</b>
      </p>
      <p>
        The corporate identity of Trafimage (structure and design) forms an
        integral part of SBB’s image and is protected by copyright. The maps and
        plans contained on the websites of Trafimage, including the related
        information, are supplied solely for private use and for information
        purposes.
      </p>

      <p>
        <b>
          Use of Trafimage map images and swisstopo and OpenStreetMap map data.
        </b>
      </p>
      <p>
        The provisions of swisstopo and OpenStreetMap and apply to basic
        geographical data (e.g. aerial photographs, relief data, national maps).
        Individual maps and plans, or excerpts thereof, and information may be
        downloaded, saved, printed or copied by other methods for private use
        provided that none of the copyright symbols “© SBB / Geodaten: ©
        OpenStreetMap, © swisstopo“ or other legally protected names are
        removed. All property rights and rights of use respectively shall remain
        with SBB (map images) and swisstopo (basic map data), which can revoke
        their consent at any time. None of the basic geographical data contained
        on the websites may be reproduced (completely or in part), transmitted
        (electronically or by other means), modified, linked or used without the
        prior written consent of SBB and swisstopo.
      </p>

      <p>
        <b>Copyright of swisstopo.</b>
      </p>
      <p>
        The basic geographical data integrated into this Internet platform is
        the property of the Swiss Confederation. The data is copyright protected
        by the Swiss Federal Law on Geoinformation (SR 510.62). Unless otherwise
        indicated, data is provided by the Federal Office of Topography
        (swisstopo) and is subject to the provisions of the Geoinformation
        Ordinance (SR 510.620) and the ordinance of the Federal Department of
        Defence, Civil Protection and Sport on fees and charges of the Federal
        Office of Topography (SR 510.620.2).
      </p>

      <p>
        <b>Liability on the part of swisstopo.</b>
      </p>
      <p>
        If a contractual relationship between swisstopo and the user of a
        service exists, swisstopo shall only be liable for damage it has caused
        by gross negligence or deliberate intent which lies within the scope of
        its responsibility. swisstopo shall not be liable for loss of earnings,
        data loss or other indirect damage due in particular to incorrect
        information or technical malfunctions. swisstopo assumes no
        responsibility for errors that do not lie within its area of
        responsibility.
      </p>

      <p>
        <b>Data belonging to SBB and its partners.</b>
      </p>
      <p>All other data is the property of SBB or its partners.</p>
    </div>
  );
}

export default LegalEN();
