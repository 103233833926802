import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'lodash.flowright';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import IABPPropTypes from '../../model/iabp/prop-types';
import { setActivePlan } from '../../model/iabp/actions';
import { setClickedFeatures, fitExtent } from '../../model/map/actions';
import './OutdoorPlanButton.scss';

const propTypes = {
  // mapStateToProps
  activePlan: IABPPropTypes.plan,
  station: IABPPropTypes.station.isRequired,

  // mapDispatchToProps
  dispatchSetActivePlan: PropTypes.func.isRequired,
  dispatchSetClickedFeatures: PropTypes.func.isRequired,
  dispatchFitExtent: PropTypes.func.isRequired,

  // react-i18next
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  activePlan: null,
};

const BlueSwitch = withStyles({
  root: {
    width: '46px',
    height: '26px',
    padding: '0px',
    borderRadius: '25px',
    border: '1px solid #e5e5e5',
  },
  switchBase: {
    color: '#ffffff',
    top: '-8px',
    left: '-8px',
    '&&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: '#ffffff',
    },
    '&$checked + $track': {
      backgroundColor: '#eb0000',
      opacity: 1,
    },
  },
  thumb: {
    width: '22px',
    height: '22px',
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.07), 0 0 1px 1px rgba(0,0,0,0.11), 0 4px 2px 0 rgba(0,0,0,0.1), 0 4px 9px 2px rgba(0,0,0,0.08)',
  },
  checked: {},
  track: {
    backgroundColor: '#eb0000',
    opacity: 1,
  },
})(Switch);

const OutdoorPlanButton = ({
  dispatchFitExtent,
  dispatchSetActivePlan,
  dispatchSetClickedFeatures,
  activePlan,
  station,
  t,
}) => {
  const { extent } = station;

  const [outdoorPlan] = station.plans.filter(
    (p) => p.plan_type === 'outdoor_web',
  );

  const [indoorPlan] = station.plans.filter(
    (p) => p.plan_type === 'indoor_web',
  );

  const active = !(
    outdoorPlan &&
    activePlan &&
    activePlan.plan_type === outdoorPlan.plan_type
  );

  const toggle = useCallback(
    (evt) => {
      if (evt.type === 'keypress' && evt.which !== 13) {
        return;
      }
      dispatchSetActivePlan(active ? outdoorPlan : indoorPlan, 'Button');
      dispatchFitExtent(extent);
      dispatchSetClickedFeatures([]);
    },
    [
      active,
      dispatchFitExtent,
      dispatchSetActivePlan,
      dispatchSetClickedFeatures,
      extent,
      indoorPlan,
      outdoorPlan,
    ],
  );

  if (!outdoorPlan || !indoorPlan) {
    return null;
  }

  return (
    <>
      <div
        className="tm-outdoor-plan-button"
        role="button"
        onClick={toggle}
        onKeyPress={toggle}
        tabIndex={0}
      >
        <span className={!active ? 'tm-title-active' : null}>
          {t('Aussen')}
          {active && (
            <span className="tm-screen-reader-text">{t('gewählt')}</span>
          )}
        </span>
        <BlueSwitch
          checked={active}
          onChange={toggle}
          disableRipple
          tabIndex={-1}
        />
        <span className={active ? 'tm-title-active' : null}>
          {t('Innen')}
          {active && (
            <span className="tm-screen-reader-text">{t('gewählt')}</span>
          )}
        </span>
      </div>
    </>
  );
};

OutdoorPlanButton.propTypes = propTypes;
OutdoorPlanButton.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  station: state.iabp.station,
});

const mapDispatchToProps = {
  dispatchFitExtent: fitExtent,
  dispatchSetActivePlan: setActivePlan,
  dispatchSetClickedFeatures: setClickedFeatures,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(OutdoorPlanButton);
