import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layer from 'react-spatial/layers/Layer';
import VectorLayer from 'react-spatial/layers/VectorLayer';
import Feature from 'ol/Feature';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ClusterSource from 'ol/source/Cluster';
import { setClickedFeatures, setLayers } from '../../model/map/actions';
import { fetchServiceFeatures, togglePlan } from '../../model/iabp/actions';
import IABPPropTypes from '../../model/iabp/prop-types';
import LayerStyle from './LayerStyle';
import CATEGORIES from '../ServiceCategories';

const propTypes = {
  stationAlias: PropTypes.string.isRequired,

  // mapStateToProps
  activePlan: IABPPropTypes.plan.isRequired,
  clickedFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
  filterCategory: PropTypes.string,
  layers: PropTypes.arrayOf(PropTypes.instanceOf(Layer)).isRequired,
  station: IABPPropTypes.station.isRequired,
  serviceFeatures: PropTypes.arrayOf(PropTypes.instanceOf(Feature)),
  lng: PropTypes.string,

  // mapDispatchToProps
  dispatchFetchServiceFeatures: PropTypes.func.isRequired,
  dispatchSetLayers: PropTypes.func.isRequired,
};

const defaultProps = {
  filterCategory: undefined,
  serviceFeatures: [],
  lng: 'de',
};

class PlansServiceLayerCluster extends PureComponent {
  static filterFeaturesByPlan(features, plan) {
    return features.filter((f) => f.get('plan_layer') === plan.plan_layer);
  }

  constructor(props) {
    super(props);
    const {
      dispatchFetchServiceFeatures,
      dispatchSetLayers,
      layers,
      station,
      stationAlias,
    } = this.props;

    const that = this;
    this.layer = new VectorLayer({
      name: 'Cluster',
      olLayer: new OLVectorLayer({
        zIndex: 1,
        style: (f, r) => this.styleFunction(f, r),
        source: new ClusterSource({
          distance: 35, // Size of circle + 3
          geometryFunction(feature) {
            // Don't cluster features which are not in the current plan
            const { activePlan, filterCategory } = that.props;
            const isValidPlan =
              feature.get('plan_layer') === activePlan.plan_layer;
            const isValidCategory =
              !filterCategory || feature.get('category') === filterCategory;
            if (isValidPlan && isValidCategory) {
              return feature.getGeometry();
            }
            return null;
          },
          source: new VectorSource({
            features: [],
          }),
        }),
      }),
    });

    dispatchSetLayers([...layers, this.layer]);
    dispatchFetchServiceFeatures(stationAlias);

    const { extent } = station;
    this.layerStyle = new LayerStyle({ gridExtent: extent });
  }

  componentDidUpdate(prevProps) {
    const {
      activePlan,
      clickedFeatures,
      dispatchFetchServiceFeatures,
      filterCategory,
      serviceFeatures,
      stationAlias,
    } = this.props;

    if (prevProps.stationAlias !== stationAlias) {
      dispatchFetchServiceFeatures(stationAlias);
    }

    if (filterCategory !== prevProps.filterCategory) {
      this.layer.olLayer.changed();
    }

    if (prevProps.clickedFeatures !== clickedFeatures) {
      const [feat] = clickedFeatures;

      if (feat && feat.get('features')) {
        this.onClusterClick(feat);
        this.layer.olLayer.changed();
      }
    }

    if (
      activePlan &&
      (activePlan !== prevProps.activePlan ||
        serviceFeatures !== prevProps.serviceFeatures)
    ) {
      const planFeatures =
        activePlan.plan_type === 'outdoor_web'
          ? serviceFeatures
          : PlansServiceLayerCluster.filterFeaturesByPlan(
              serviceFeatures,
              activePlan,
            );

      this.layer.olLayer.getSource().clear();
      // We need ot add features on the vector source.
      this.layer.olLayer.getSource().getSource().addFeatures(planFeatures);
    }
  }

  styleFunction(feature, resolution) {
    const { lng } = this.props;
    const cat = CATEGORIES;
    const feats = feature.get('features');
    // Sort features by priorisation of category
    feats.sort((a, b) =>
      cat[a.get('category')].priority > cat[b.get('category')].priority
        ? -1
        : 1,
    );
    return this.layerStyle.serviceStyleFunction(
      feature,
      resolution,
      undefined,
      undefined,
      undefined,
      undefined,
      lng,
    );
  }

  render() {
    return null;
  }
}

PlansServiceLayerCluster.propTypes = propTypes;
PlansServiceLayerCluster.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  activePlan: state.iabp.activePlan,
  clickedFeatures: state.map.clickedFeatures,
  filterCategory: state.iabp.filterCategory,
  layers: state.map.layers,
  station: state.iabp.station,
  serviceFeatures: state.iabp.serviceFeatures,
  lng: state.iabp.lng,
});

const mapDispatchToProps = {
  dispatchFetchServiceFeatures: fetchServiceFeatures,
  dispatchTogglePlan: togglePlan,
  dispatchSetClickedFeatures: setClickedFeatures,
  dispatchSetLayers: setLayers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlansServiceLayerCluster);
