import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Overlay from '@geops/react-ui/components/Overlay';
import Button from '@geops/react-ui/components/Button';
import Feature from 'ol/Feature';
import OLMap from 'ol/Map';

import TrackLink from '../link/TrackLink';
import TransportOverlay from './TransportOverlay';
import ServiceOverlay from './ServiceOverlay';
// eslint-disable-next-line import/no-named-as-default
import PlanAccessContent from './PlanAccessContent';
import RelatedOverlay from './RelatedOverlay';
import EmbeddedOverlay from './EmbeddedOverlay';
import { ReactComponent as LinkArrowSVG } from '../../img/icons/link_arrow.svg';
import { ReactComponent as LeftArrowSVG } from '../../img/icons/arrow_left.svg';

import { setClickedFeatures, setHoverFeatures } from '../../model/map/actions';

import './PlansOverlay.scss';

const propTypes = {
  observe: PropTypes.object,
  map: PropTypes.instanceOf(OLMap).isRequired,
  feature: PropTypes.instanceOf(Feature),
  isEmbedded: PropTypes.bool,
  contentRef: PropTypes.object,
};

const defaultProps = {
  feature: null,
  isEmbedded: false,
  observe: null,
  contentRef: undefined,
};

const PlansOverlay = ({ contentRef, observe, isEmbedded, feature, map }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lng = useSelector((state) => state.iabp.lng);
  const extLinksVisible = useSelector((state) => state.iabp.extLinksVisible);
  const disabled = useSelector((state) => state.iabp.disabled);
  const station = useSelector((state) => state.iabp.station);
  const initialFeatureIdentifier = useSelector(
    (state) => state.iabp.initialFeatureIdentifier,
  );

  const isAccessOverlay = useMemo(() => {
    return feature && feature.get('related');
  }, [feature]);
  const isRelatedOverlay = useMemo(() => {
    return (
      feature &&
      feature.get('related_information') &&
      feature.get('related_information').length
    );
  }, [feature]);
  const isTranspOverlay = useMemo(() => {
    return feature && feature.get('category') === 'public_transport';
  }, [feature]);

  const getOverlayClassName = useCallback(() => {
    if (!feature) {
      return 'tm-overlay-closed';
    }
    if (isAccessOverlay) {
      return 'tm-overlay-access';
    }
    if (isRelatedOverlay) {
      return 'tm-overlay-related';
    }
    if (isTranspOverlay) {
      return 'tm-overlay-transport';
    }
    return '';
  }, [isAccessOverlay, isRelatedOverlay, isTranspOverlay, feature]);

  const defaultHeight = useMemo(() => {
    return isEmbedded ? 220 : 320;
  }, [isEmbedded]);
  const accessOverlayHeight = useMemo(() => {
    return isEmbedded ? 120 : 200;
  }, [isEmbedded]);
  const overlayHeight = useMemo(() => {
    return isAccessOverlay ? accessOverlayHeight : defaultHeight;
  }, [isAccessOverlay, accessOverlayHeight, defaultHeight]);

  const isInitialSelected = useMemo(() => {
    return (
      feature &&
      feature.get('url_identifier') &&
      initialFeatureIdentifier &&
      initialFeatureIdentifier === feature.get('url_identifier')
    );
  }, [feature, initialFeatureIdentifier]);

  let content;

  const onOverlayClose = useCallback(() => {
    dispatch(setClickedFeatures([]));
    dispatch(setHoverFeatures([]));
    map.getTarget().focus();
  }, [map, dispatch]);

  const mobileSize = useMemo(() => {
    return isEmbedded
      ? {
          minimalHeight: 90,
          size: {
            height: 90,
            width: '100%',
          },
        }
      : {
          minimalHeight: overlayHeight,
          defaultSize: {
            height: overlayHeight,
            width: '100%',
          },
          size: isAccessOverlay
            ? {
                height: overlayHeight,
                width: '100%',
              }
            : undefined,
        };
  }, [isEmbedded, overlayHeight, isAccessOverlay]);

  if (feature) {
    if (feature.get('related')) {
      content = (
        <PlanAccessContent
          feature={feature}
          isEmbedded={isEmbedded}
          onClose={onOverlayClose}
        />
      );
    } else if (
      feature.get('category') === 'public_transport' &&
      !disabled.includes('transportOverlay')
    ) {
      content = (
        <TransportOverlay
          feature={feature}
          isEmbedded={isEmbedded}
          onClose={onOverlayClose}
        />
      );
    } else if (
      feature.get('related_information') &&
      feature.get('related_information').length
    ) {
      content = (
        <RelatedOverlay
          feature={feature}
          isEmbedded={isEmbedded}
          onClose={onOverlayClose}
        />
      );
    } else {
      const sbbPath = feature.get('sbb_path');
      let link = null;

      const linkUrl = feature.get('link_url');
      if (station && sbbPath) {
        link = station[`sbb_base_path_${lng}`] + sbbPath;
      } else {
        link = linkUrl;
      }

      // Hide the 'link to offer' on sbb prod site with an url like:
      // https://test-www.sbb.ch/de/bahnhof-ser...haefte/shop-detail.html/geo-migros-e50c
      let isLinkDisplayed = !!link;
      const urlIdentifier = feature.get('url_identifier');
      if (isLinkDisplayed && urlIdentifier) {
        const regex = new RegExp(`www.sbb.ch.*/${urlIdentifier}$`);
        isLinkDisplayed = !regex.test(window.location.href);
      }

      if (isLinkDisplayed) {
        link = (
          <TrackLink
            href={link}
            className="tm-overlay-link-anchor"
            title={t('Link zum Angebot')}
            eventInfo={{ eventName: 'Icon' }}
            category={{
              primaryCategory: t(feature.get('category') || 'transport', {
                lng: 'de',
              }),
              subCategory1: feature.get('type') || '',
              subCategory3: feature.get(`field_name_${lng}`),
            }}
            geoData={{
              coordinates: feature.getGeometry().getCoordinates(),
            }}
          >
            <span>
              {t('Link zum Angebot')}
              &nbsp;
            </span>
            <LinkArrowSVG className="tm-overlay-link-arrow" />
          </TrackLink>
        );
      } else {
        link = null;
      }

      const logo = feature.get('logo_url') ? (
        <div
          className={`tm-overlay-logo${
            !feature.get('logo_url') ? ' tm-hidden' : ''
          }`}
        >
          <img
            src={feature.get('logo_url')}
            alt={`Logo ${feature.get(`field_name_${lng}`)}`}
          />
        </div>
      ) : null;

      if (isEmbedded) {
        content = (
          <EmbeddedOverlay
            onClose={onOverlayClose}
            title={feature.get(`field_name_${lng}`)}
            logo={logo}
            link={
              <div
                className={`tm-overlay-link${
                  !extLinksVisible ? ' tm-hidden' : ''
                }`}
              >
                {link}
              </div>
            }
          />
        );
      } else {
        content = (
          <ServiceOverlay
            logo={logo}
            link={link}
            feature={feature}
            linkUrl={linkUrl}
            extLinksVisible={extLinksVisible}
          />
        );
      }
    }
  }

  // Do not open the identifier feature in the overlay.
  if (disabled.includes('identifierOverlay') && isInitialSelected) {
    return null;
  }

  return !disabled.includes('overlay') ? (
    <Overlay
      observe={observe}
      // Not resizable for embedded.
      isMobileResizable={!isEmbedded}
      thresholdWidthForMobile={isEmbedded ? Infinity : undefined}
      onResizeStop={(evt, handler, div, resized) => {
        // When resizing smaller than the minimum height, it closes the overlay.
        if (
          (resized.height === 0 &&
            [overlayHeight, overlayHeight - 1].includes(div.clientHeight)) ||
          div.clientHeight + resized.height < overlayHeight - 100
        ) {
          onOverlayClose();
        }
      }}
      className={`${getOverlayClassName()}${
        isEmbedded ? ' tm-overlay-embedded' : ''
      }${disabled.includes('footer') ? ' tm-overlay-without-footer' : ''}`}
      mobileSize={
        !feature
          ? {
              minimalHeight: 0,
              size: {
                height: 0,
                width: '100%',
              },
            }
          : mobileSize
      }
    >
      {feature ? (
        <div ref={contentRef} style={{ height: '100%' }}>
          <div className="tm-overlay-close-wrapper">
            <Button className="tm-overlay-close-bt" onClick={onOverlayClose}>
              <LeftArrowSVG focusable={false} />
              {t('Zurück')}
            </Button>
          </div>
          {content}
        </div>
      ) : null}
    </Overlay>
  ) : null;
};

PlansOverlay.propTypes = propTypes;
PlansOverlay.defaultProps = defaultProps;

export default PlansOverlay;
