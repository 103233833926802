import React from 'react';

function ImprintEN() {
  return (
    <div className="tm-imprint">
      <h1>Imprint.</h1>
      <p>
        <b>Overall responsibility for Trafimage.</b>
        <br />
        <br />
        SBB AG
        <br />
        Infrastructure – Installations and Technology – Technology Operation
        Center – Technical Management
        <br />
        Fachbus Trafimage
        <br />
        Poststrasse 6
        <br />
        CH-3072 Ostermundingen
        <br />
        <a href="http://www.trafimage.ch">www.trafimage.ch</a>
        <br />
      </p>
      <p>
        <b>
          Operation, maintenance and further development of the Trafimage
          applications, data integration.
        </b>
        <br />
        <br />
        geOps AG
        <br />
        Solothurnerstrasse 235
        <br />
        CH-4600 Olten
        <br />
        <a href="http://www.geops.ch">www.geops.ch</a>
        <br />
      </p>
      <p>
        <b>Map and station plan production</b>
        <br />
        <br />
        evoq communications AG
        <br />
        Ottikerstrasse 59
        <br />
        CH-8006 Zürich
        <br />
        <a href="http://www.evoq.ch">www.evoq.ch</a>
        <br />
      </p>
    </div>
  );
}

export default ImprintEN();
