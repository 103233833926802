import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@geops/react-ui/components/Button';

import {
  setMenuVisible,
  setStationSwitcherVisible,
} from '../../model/iabp/actions';

const MenuButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const disabled = useSelector((state) => state.iabp.disabled);
  const menuVisible = useSelector((state) => state.iabp.menuVisible);

  if (disabled.includes('menu')) {
    return null;
  }
  return (
    <Button
      onClick={() => {
        const vis = !menuVisible;
        dispatch(setMenuVisible(vis));

        if (vis) {
          dispatch(setStationSwitcherVisible(false));
        }
      }}
      className="tm-button tm-menu-button"
      title={menuVisible ? t('Menü schliessen') : t('Menü öffnen')}
    >
      <div className={`tm-burger${menuVisible ? ' tm-burger-active' : ''}`}>
        <span className="tm-burger-line-first" />
        <span className="tm-burger-line-second" />
        <span className="tm-burger-line-third" />
      </div>
    </Button>
  );
};

export default React.memo(MenuButton);
